import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { DashBoardLayoutIdentity } from './dash-board-layout.identity';

@IdentityTypeNameDecorator('DashBoardItemIdentity')
export class DashBoardItemIdentity extends DashBoardLayoutIdentity {

    // tslint:disable-next-line: variable-name
    private _itemId: number;

    @Expose()
    public get itemId(): number {
        return this.getValue<number>(() => this._itemId, 'itemId');
    }
    public set itemId(value: number) {
        this.setValue<number>(() => { this._itemId = value; }, value, 'itemId');
    }
}

