import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FilledButtonComponent, GenericServiceResponse, NTSTranslatePipe, RoutingService, ToastMessageService } from '@nts/std';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { WidgetComponent } from '../widget-component';
import { WidgetMenuItemShortcutDto } from 'src/app/dash-board/domain-models/dto/widget-menu-item-shortcut.dto';
import { MenuNodeTypes } from 'src/app/dash-board-container/domain-models/enum/menu-node-types';
import { environment } from 'src/environments/environment';
import { RouteAdditionalData } from 'src/app/dash-board-container/domain-models/route-additional-data';
import { Router } from '@angular/router';
import { ClientConfigurationManager, LocalstorageHelper } from '@nts/std/src/lib/utility';
import { LinkOpenTypes } from 'src/app/dash-board-container/domain-models/enum/link-open-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { NgxPopperjsPlacements, NgxPopperjsTriggers, NgxPopperjsModule } from "ngx-popperjs";
import { DashBoardItemViewModel } from 'src/app/dash-board/view-models/dash-board-item.view-model';
import { SvgIconComponent } from '@ngneat/svg-icon'
import { NgClass, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-menu-item-shortcut-widget',
    templateUrl: './menu-item-shortcut-widget.component.html',
    styleUrls: ['./menu-item-shortcut-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        NgClass,
        NgxPopperjsModule,
        FilledButtonComponent,
        NTSTranslatePipe,
        NgIf
    ]
})
export class MenuItemShortcutWidgetComponent extends WidgetComponent {

    title: string;
    subTitle: string;
    backgroundColor: string;
    icon: string;
    label: string;

    widgetIcons = [];
    widgetMenuItemShortcutDto: WidgetMenuItemShortcutDto
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    dashboardItemVm: DashBoardItemViewModel
    // default env class is prod
    public envClass = 'prod';

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService, env: EnvironmentConfiguration, private readonly router: Router) {
        super(toastMessageService, cd);

        this.widgetIcons[MenuNodeTypes.CRUDQ] = "layout-left"
        this.widgetIcons[MenuNodeTypes.Search] = "search"
        this.widgetIcons[MenuNodeTypes.LongOp] = "tools"
        this.widgetIcons[MenuNodeTypes.Activity] = "tools"
        this.widgetIcons[MenuNodeTypes.Link] = "link"
        this.widgetIcons[MenuNodeTypes.InternalLink] = "link"
        this.widgetIcons[MenuNodeTypes.ExternalAppLink] = "app-window"
        this.widgetIcons[MenuNodeTypes.Node] = ""

        ClientConfigurationManager.Current.loaded.pipe(
            untilDestroyed(this),
            filter(loadingComplete => loadingComplete),
            take(1)
        ).subscribe(() => {
            if (
                ClientConfigurationManager.Current.config['DashBoard.MenuObjects.Models.MenuNode_useProductionColor'] === false ||
                ClientConfigurationManager.Current.config['DashBoard.MenuObjects.Models.MenuNode_useProductionColor'] == null) {
                this.envClass = env.envType.substr(0, 3).toLocaleLowerCase();
                this.cd.markForCheck();
            }
        })
    }

    protected override isVisibleRefreshCommand(): Observable<boolean> {
        return of(false);
    }

    override resizeChanged(dashboardItemVm: DashBoardItemViewModel) {
        super.resizeChanged(dashboardItemVm);
        this.dashboardItemVm = dashboardItemVm;
        this.cd.detectChanges();
    }

    protected getLoadDataObservable<WidgetAlertValueDto>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetAlertValueDto>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getMenuItemShortcutData(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetMenuItemShortcutDto): Observable<boolean> {
        this.widgetMenuItemShortcutDto = result;

        this.title = result.shortDescription;
        this.subTitle = result.description;
        this.icon = this.widgetIcons[result.menuNodeType];

        this.cd.detectChanges();

        return of(true);
    }

    async start($event): Promise<boolean> {
        const newTab = $event.ctrlKey;

        let encondedRootModelFullName: string;
        let routeAdditionalData: RouteAdditionalData;
        let encodedAdditionalData: string;
        switch (this.widgetMenuItemShortcutDto.menuNodeType) {
            case MenuNodeTypes.Link:
                return this.openLink(this.widgetMenuItemShortcutDto.args);
            case MenuNodeTypes.InternalLink:
                encondedRootModelFullName = RoutingService.encodeUrl(this.widgetMenuItemShortcutDto.rootModelFullName);
                routeAdditionalData = this.getRouteAdditionalData();
                encodedAdditionalData = RoutingService.encodeObject(routeAdditionalData);
                if (newTab) {
                    window.open('manage/open-internal-link/' + encondedRootModelFullName + '/' + encodedAdditionalData, '_blank');
                    return true;
                }
                else {
                    if (this.widgetMenuItemShortcutDto.shortDescription?.length > 0) {
                        window.document.title = `${this.widgetMenuItemShortcutDto.shortDescription} - ${environment.appTitle}`;
                    }
                    this.router.navigate(['manage/open-internal-link/' + encondedRootModelFullName + '/' + encodedAdditionalData]);
                    return true;
                }
            case MenuNodeTypes.ExternalAppLink:
                const authUrl: string = environment.authenticationAppUrl;
                const accessToken: string = await LocalstorageHelper.getStorageItem("accessToken", undefined, false) as string;
                const refreshToken: string = await LocalstorageHelper.getStorageItem("refreshToken", undefined, false) as string;
                //Link esempio: https://loginservice.ntsinformatica.it/auth/authorize-app?redirect-url=http:%2F%2Flocalhost:59270%2F&client-id=eTQlYQOLLWOXRwaj
                const uri = authUrl + '?access-token=' + accessToken +
                    '&refresh-token=' + refreshToken +
                    '&client-id=' + this.widgetMenuItemShortcutDto.externalAppClientId +
                    '&force-app-auth=true' +
                    '&redirect-url=' + encodeURIComponent(this.widgetMenuItemShortcutDto.args);
                return this.openLink(uri, true);
            default:
                encondedRootModelFullName = RoutingService.encodeUrl(this.widgetMenuItemShortcutDto.rootModelFullName);
                routeAdditionalData = this.getRouteAdditionalData();
                encodedAdditionalData = RoutingService.encodeObject(routeAdditionalData);
                if (newTab) {
                    window.open('manage/' + encondedRootModelFullName + '/' + encodedAdditionalData, '_blank');
                    return true;
                }
                else {
                    if (this.widgetMenuItemShortcutDto.shortDescription?.length > 0) {
                        window.document.title = `${this.widgetMenuItemShortcutDto.shortDescription} - ${environment.appTitle}`;
                    }
                    this.router.navigate(['manage/' + encondedRootModelFullName + '/' + encodedAdditionalData]);
                    return true;
                }
        }

        return false;
    }

    private openLink(url: string, isExternalAppLink = false): boolean {
        switch (this.widgetMenuItemShortcutDto.linkOpenType) {
            case LinkOpenTypes.Inside:
                const routeAdditionalData = this.getRouteAdditionalData();
                const encodedAdditionalData = RoutingService.encodeObject(routeAdditionalData);

                if (isExternalAppLink) {
                    this.router.navigate([`/manage/open-external-app-link/${this.widgetMenuItemShortcutDto.externalAppClientId}/${encodedAdditionalData}`])
                } else if (this.widgetMenuItemShortcutDto.menuNodeType === MenuNodeTypes.Link) {
                    if (this.widgetMenuItemShortcutDto.shortDescription?.length > 0) {
                        window.document.title = `${this.widgetMenuItemShortcutDto.shortDescription} - ${environment.appTitle}`;
                    }
                    this.router.navigate([`/manage/open-link/${encodedAdditionalData}`])
                }
                return true;
            case LinkOpenTypes.NewTab:
                window.open(url, "_blank");
                return true;
            case LinkOpenTypes.Replace:
                window.location.href = url;
                return true;
        }
    }

    protected getRouteAdditionalData(): RouteAdditionalData {

        // preparo l'additionalData
        const routeAdditionalData = new RouteAdditionalData();

        if (this.widgetMenuItemShortcutDto.args?.length > 0) {
            routeAdditionalData.args = this.widgetMenuItemShortcutDto.args;
        }

        if (this.widgetMenuItemShortcutDto.shortDescription?.length > 0) {
            routeAdditionalData.shortDescription = this.widgetMenuItemShortcutDto.shortDescription;
        }

        return routeAdditionalData;
    }
}
