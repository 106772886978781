export enum MenuNodeTypes {
    Node = 0,
    CRUDQ = 1,
    Search = 2,
    LongOp = 3,
    Activity = 4,
    Link = 5,
    ExternalAppLink = 6,
    InternalLink = 7,
}
