import { Expose } from '@nts/std';
import { LinkOpenTypes } from 'src/app/dash-board-container/domain-models/enum/link-open-types';
import { MenuNodeTypes } from 'src/app/dash-board-container/domain-models/enum/menu-node-types';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetMenuItemShortcutDto extends WidgetBaseValueDto {

    @Expose()
    public id: number;

    @Expose()
    public shortDescription: string;

    @Expose()
    public description: string;

    @Expose()
    public isStandard: boolean;

    @Expose()
    public menuNodeType: MenuNodeTypes;

    @Expose()
    public rootModelFullName: string;

    @Expose()
    public args: string;

    @Expose()
    public externalAppClientId: string;

    @Expose()
    public linkOpenType: LinkOpenTypes;

    @Expose()
    public isOffLine;
}
