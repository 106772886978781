import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BaseAggregateComponent, NTSTranslatePipe } from '@nts/std';
import { DashBoardLayoutOrchestratorViewModel } from '../../view-models/dash-board-layout.orchestrator-view-model';
import { DashBoardLayoutViewModel } from '../../view-models/dash-board-layout.view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe, NgIf } from '@angular/common';
import { WidgetContainerComponent } from "../widget-container/widget-container.component";
import { DocumentService, DomRefService, JoyrideBackdropService, JoyrideService, JoyrideStepService } from 'ngx-joyride';

@UntilDestroy()
@Component({
    selector: 'nts-dash-board-layout',
    templateUrl: './dash-board-layout.component.html',
    styleUrls: ['./dash-board-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NTSTranslatePipe,
        WidgetContainerComponent,
        AsyncPipe
    ],
    providers: [
        // JoyrideService,
        // JoyrideStepService,
        // JoyrideBackdropService,
        // DocumentService,
        // DomRefService
    ]
})
export class DashBoardLayoutComponent extends BaseAggregateComponent<DashBoardLayoutViewModel, DashBoardLayoutOrchestratorViewModel> implements OnDestroy {

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnDestroy(): void {
        if (this.orchestratorViewModel?.destroySubscribers$) {
            this.orchestratorViewModel.destroySubscribers$.next();
        }
    }

    override ngOnInit() {
        super.ngOnInit();
        this.orchestratorViewModel.rootViewModelChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.cd.detectChanges();
        });
    }
}
