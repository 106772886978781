import { Expose, Type } from '@nts/std';
import { ResultSetIconItemDto } from './result-set-icon-item-dto';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetIconResultSetValueDto extends WidgetBaseValueDto {

    constructor() {
        super();
        this.items = [];
        this.labels = [];
    }

    @Expose()
    @Type(() => ResultSetIconItemDto)
    public items: ResultSetIconItemDto[];

    @Expose()
    public labels: string[];
}
