import { RootModelTypeNameDecorator, RootModelTypeDecorator, CacheOptionsInterface, MetaDataResponse } from '@nts/std';
import { CrudApiClient } from '@nts/std';
import { MenuNodeIdentity } from '../domain-models/menu-node.identity';
import { MenuNode } from '../domain-models/menu-node';
import { Observable } from 'rxjs';

@RootModelTypeNameDecorator('MenuNode')
@RootModelTypeDecorator(MenuNode)
export class MenuNodeApiClient extends CrudApiClient<MenuNode, MenuNodeIdentity> {

    // Faccio l'override per salvare per 24 ore la getMetaData
    override getMetaDataAsync(
        includeDescriptions = false,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: 60 * 60 * 24,
            force: true,
            enableTimeout: true,
            tenantBarrier: true,
            enterpriseBarrier: true,
            userBarrier: false
        },
        retryRequest = 0
    ): Observable<MetaDataResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.getMetaDataAsync(
            includeDescriptions,
            cacheOptions,
            retryRequest
        );
    }

}
