import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { GenericServiceResponse, ToastMessageService } from '@nts/std';
import { WidgetDonutValueDto } from '../../../domain-models/dto/widget-donut-value-dto';
import { Point, Series, ChartRedrawCallbackFunction } from 'highcharts';
import { Observable, map, of, tap } from 'rxjs';
import { NgIf, formatNumber } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DashBoardItemViewModel } from 'src/app/dash-board/view-models/dash-board-item.view-model';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HC_boost from 'highcharts/modules/boost';
import HC_noDataToDisplay from 'highcharts/modules/no-data-to-display';
import HC_more from 'highcharts/highcharts-more';

HC_boost(Highcharts);
HC_noDataToDisplay(Highcharts);
HC_more(Highcharts);

@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'nts-donut-widget',
    templateUrl: './donut-widget.component.html',
    styleUrls: ['./donut-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HighchartsChartModule,
        NgIf
    ],
    standalone: true
})
// tslint:disable-next-line: max-line-length
export class DonutWidgetComponent extends WidgetFullScreenComponent implements AfterViewInit, OnInit {
    @ViewChild('chartContainer') chartContainer: ElementRef;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options;
    Highcharts: typeof Highcharts = Highcharts;
    forceUpdate: boolean;
    override fontColor = 'black';

    title: string;
    subTitle: string;
    total: number;
    serieName: string;
    showFooterTotal = true;
    titleLabel: any;
    /*
    -- LifeCycle --
    ngOnInit
        -> initChart ( va fatta sempre nella OnInit)!
    logChartInstance -> qui hoi il grafico
    initialize  -> qui posso caricare i dati
    ngAfterViewInit
    */

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    protected getLoadDataObservable<WidgetDonutValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetDonutValue>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getDonutWidgetValue(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetDonutValueDto): Observable<boolean> {
        const _this = this;

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;

        this.serieName = result.serieName;
        const items = result.items;
        const series = [];
        const data = [];
        items.forEach(item => {
            data.push({
                name: item.name,
                y: item.value,
            });
        });
        //sort
        data.sort((item1, item2) => {
            return (item1.y < item2.y ? 1 : -1);
        });

        // una sola serie con i dati
        series.push({
            innerSize: '40%',
            name: this.serieName,
            colorByPoint: true,
            accessibility: {
                point: {
                    valueSuffix: this.serieName
                }
            },
            data
        });

        // aggiorno il grafico
        this.chart.update({
            tooltip: {
                enabled: true,
                outside: true,
                useHTML: true,
                backgroundColor: 'rgba(255,255,255,1)',
                /*style: {
                    padding: 0
                },*/
                formatter: function () {
                    return `${this.point.name}: <b> ${_this.currencyFormat(this.point.y)} ${this.series.name}</b>`;
                }
            },
            legend: {
                labelFormatter: function () {
                    return `${(this as Series).name}: ${_this.currencyFormat((this as Point).y)} ${(this as Point).series.name}`;
                }
            },
            series
        }, true, true);


        // // la prima volta devo effettuar eil timeout per il disegno corretto del grafico dentro il content
        setTimeout(() => {
            this.resizeChanged(null);
        });

        this.cd.detectChanges();

        return of(true);
    }

    ngOnInit() {
        this.initChart();
    }

    calculateTotal(): number {
        return this.chart.series[0]?.data[0]?.total ?? 0;
    }

    currencyFormat(number: number): string {
        return formatNumber(number, 'it', '1.2-2').replace(',00', '');
    }

    initChart() {

        // parametri default
        this.chartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    redraw: this.redrawChart.bind(this) as ChartRedrawCallbackFunction
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                borderWidth: 0,
                useHTML: true,
                itemStyle: {
                    textOverflow: 'ellipsis'
                },
            },
            responsive: {
                rules: [{
                    condition: {
                        minWidth: 0,
                        maxWidth: 400,
                        //callback: this.responsiveChar.bind(this) as ResponsiveCallbackFunction
                    },
                    chartOptions: {
                        // title: {
                        //     text: 'RULE 1 0-400'
                        // },
                        legend: {
                            enabled: false
                        }
                    }
                }, {
                    condition: {
                        minWidth: 401,
                        maxWidth: 500,
                        //callback: this.responsiveChar.bind(this) as ResponsiveCallbackFunction
                    },
                    chartOptions: {
                        // title: {
                        //     text: 'RULE 2 401-500'
                        // },
                        legend: {
                            enabled: true,
                            width: 200,
                            margin: 0,
                            itemStyle: {
                                textOverflow: 'ellipsis',
                                width: 150
                            }
                        }
                    }
                }, {
                    condition: {
                        minWidth: 501,
                        maxWidth: 600,
                        //callback: this.responsiveChar.bind(this) as ResponsiveCallbackFunction
                    },
                    chartOptions: {
                        // title: {
                        //     text: 'RULE 3 501-600'
                        // },
                        legend: {
                            enabled: true,
                            width: 250,
                            margin: 0,
                            itemStyle: {
                                textOverflow: 'ellipsis',
                                width: 200
                            }
                        }
                    }
                }, {
                    condition: {
                        minWidth: 601,
                        maxWidth: 700,
                        //callback: this.responsiveChar.bind(this) as ResponsiveCallbackFunction
                    },
                    chartOptions: {
                        // title: {
                        //     text: 'RULE 4 601-700'
                        // },
                        legend: {
                            enabled: true,
                            width: 350,
                            margin: 0,
                            itemStyle: {
                                textOverflow: 'ellipsis',
                                width: 300
                            }
                        }
                    }
                }, {
                    condition: {
                        minWidth: 701,
                        maxWidth: 800,
                        //callback: this.responsiveChar.bind(this) as ResponsiveCallbackFunction
                    },
                    chartOptions: {
                        // title: {
                        //     text: 'RULE 5 701-800'
                        // },
                        legend: {
                            enabled: true,
                            width: 400,
                            margin: 0,
                            itemStyle: {
                                textOverflow: 'ellipsis',
                                width: 350
                            }
                        }
                    }
                }, {
                    condition: {
                        minWidth: 801,
                        maxWidth: 900,
                        //callback: this.responsiveChar.bind(this) as ResponsiveCallbackFunction
                    },
                    chartOptions: {
                        // title: {
                        //     text: 'RULE 6 801-900'
                        // },
                        legend: {
                            enabled: true,
                            width: 500,
                            margin: 0,
                            itemStyle: {
                                textOverflow: 'ellipsis',
                                width: 450
                            }
                        }
                    }
                }, {
                    condition: {
                        minWidth: 901,
                        //callback: this.responsiveChar.bind(this) as ResponsiveCallbackFunction
                    },
                    chartOptions: {
                        // title: {
                        //     text: '******DEFAULT*******'
                        // },
                        legend: {
                            width: 700,
                            enabled: true,
                            itemStyle: {
                                width: 650,
                                textOverflow: 'ellipsis',
                            },
                        }
                    },
                }]
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            }
        };
    }

    responsiveChar() {
        if (this.chart) {
            //return true;
        }
        /*if (this.chart) {
            const htmlChart = document.querySelector(`#${this.chart.container.id}`);
            const htmlChartLegend = htmlChart.querySelectorAll(".highcharts-legend");
            if (htmlChartLegend && htmlChartLegend.length > 0 && htmlChartLegend[1]) htmlChartLegend[1].style.display = 'none';
        }*/
        //this.chart.legend.options.enabled = false;
        // viene tolta la legenda, devo ridisiegnar eil chart
        //this.resizeChanged();

    }

    redrawChart(): void {
        if (!this.chart) {
            return;
        }

        const charHeight = this.chart.plotHeight;
        const charWidth = this.chart.plotWidth;

        this.total = this.calculateTotal();
        //this.chart.legend.update()

        //this.chart.options.legend.enabled = charWidth > 500;

        //this.showFooterTotal = !this.chart.legend.options.enabled;

        //se non ci sono dati da visualizzare non visualizzo nemmeno il footer
        this.showFooterTotal = this.chart.series.length > 0;

        if (!this.showFooterTotal) {
            this.titleLabel.css({
                display: 'none'
            });
        } else {

            // const font = (charWidth < 340 || charHeight < 300) ? 20 : 30;
            // this.titleLabel.css({
            //     display: 'visible',
            //     fontSize: font + 'px'
            // });

            // const titleHeight = this.titleLabel.getBBox().height;
            // const titleWidth = this.titleLabel.getBBox().width;

            // const titleX = charWidth / 2 - titleWidth / 2 + 10;
            // const titleY = charHeight / 2 + titleHeight / 2;

            // this.titleLabel.attr({
            //     text: this.total + ' ' + this.serieName,
            //     x: titleX,
            //     y: titleY
            // });
        }


        /*
        const titleHeight = this.chart.title.getBBox().height;
        const titleWidth = this.chart.title.getBBox().width;

        const charHeight = this.chart.plotHeight;
        const charWidth = this.chart.plotWidth;

        const charViewHeight =   this.chart.marginTop;
        const charViewWidth = this.chart.getb.nativeElement.offsetHeight;;

        const legendHeight = (this.chart.legend.options.enabled ? this.chart.legend.box.getBBox().height : 0);
        const legendWidth = (this.chart.legend.options.enabled ? this.chart.legend.box.getBBox().width : 0);

        this.chart.title.attr({
            x: (charWidth - legendWidth) / 2 + titleWidth / 2,
            y: (charHeight - legendHeight) / 2 + titleHeight / 2,
        });*/

        // const idTotal = `${this.chart.container.id}_nts-total`;

        // // recupero il riferimetno al grafico
        // const htmlChart = document.querySelector(`#${this.chart.container.id}`);
        // // recupero i riferimenti alla legenda del grafico (possono essere anche piu di 1, quando la responsive la nasconde)
        // const htmlChartLegends = htmlChart.querySelectorAll(`.highcharts-legend`);

        // let htmlLastLegendItem;
        // // quando la respose disabilita la legenda nel DOM ne viene creata una nuova
        // // a questo punto gli elementi li recupero dall'ultima legenda che trovo nel DOM (del grafico corrente)
        // if (htmlChart && htmlChartLegends && htmlChartLegends.length > 0) {
        //     htmlLastLegendItem = htmlChartLegends[htmlChartLegends.length - 1].querySelectorAll('.highcharts-legend-item:last-child');
        // }

        // let htmlTotal = document.getElementById(idTotal);

        // // rimuovo il vecchio totale del grafico per riappenderlo
        // if (htmlTotal) {
        //     htmlTotal.remove();
        // }

        // if (htmlLastLegendItem && htmlLastLegendItem.length > 0) {
        //     // appendo il nuovo totale all'ultimo elemento della legenda
        //     const lastLegendItem = htmlLastLegendItem[htmlLastLegendItem.length - 1];
        //     htmlTotal = document.createElement('div');
        //     htmlTotal.id = idTotal;
        //     htmlTotal.className = 'totalContent';
        //     htmlTotal.innerHTML = `<hr/><span style="float:left"> Totale </span><span style="float:right"> ${this.total} ${this.serieName}</span>`;
        //     lastLegendItem.appendChild(htmlTotal);
        // }

    }


    ngAfterViewInit() {
    }

    logChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;

        // creo la label con il totale
        this.titleLabel = this.chart.renderer.text('', 0, 0)
            .attr({
                zIndex: 5
            })
            .css({
                fontSize: '20px'
            }).add();
    }

    protected override resizeChanged(dashboardItemVm: DashBoardItemViewModel) {
        super.resizeChanged(dashboardItemVm);

        if (this.chart) {
            const height = this.chartContainer.nativeElement.offsetHeight;
            const width = this.chartContainer.nativeElement.offsetWidth;
            this.chart.setSize(width, height, true);
        }

        this.cd.detectChanges();
    }
}

