import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, CoreModel, EnumDecorator, NumberDecorator, StringDecorator, ExternalDecorator, Type } from '@nts/std';
import { DashBoardItemIdentity } from './dash-board-item.identity';
import { Widget } from './widget';


@IdentityTypeDecorator(DashBoardItemIdentity)
@ModelTypeNameDecorator('DashBoardItem')
export class DashBoardItem extends CoreModel<DashBoardItemIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: string;

    @Expose()
    public get code(): string {
        return this.getValueForIdentityValue<string>(() => this._code, 'code');
    }
    public set code(value: string) {
        this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _itemId: number;

    @Expose()
    public get itemId(): number {
        return this.getValueForIdentityValue<number>(() => this._itemId, 'itemId');
    }
    public set itemId(value: number) {
        this.setValueForIdentityValue<number>(() => { this._itemId = value; }, value, 'itemId');
    }

    // #region WidgetRef
    // tslint:disable-next-line: variable-name
    private _widgetCode?: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'DashBoardItem_WidgetCode', maxLength: 50, isRequired: true })
    public get widgetCode(): string {
        return this.getValue<string>(() => this._widgetCode, 'widgetCode');
    }
    public set widgetCode(value: string) {
        this.setValue<string>(() => { this._widgetCode = value; }, value, 'widgetCode');
    }

    // tslint:disable-next-line: variable-name
    private _widgetRef: Widget;

    @ExternalDecorator({ displayNameKey: 'DashBoardItem_WidgetRef', principalPName1: 'widgetCode', dependantPName1: 'code' })
    @Expose()
    @Type(() => Widget)
    public get widgetRef(): Widget {
        return this.getValueForExternal<Widget>(
            () => this._widgetRef,
            'widgetRef',
            Widget
        );
    }
    public set widgetRef(value: Widget) {
        this.setValueForExternal(() => {
            this._widgetRef = value;
        }, value, 'widgetRef');
    }
    // #endregion WidgetRef

    // tslint:disable-next-line: variable-name
    private _x: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_X' })
    public get x(): number {
        return this.getValue<number>(() => this._x, 'x');
    }
    public set x(value: number) {
        this.setValue<number>(() => { this._x = value; }, value, 'x');
    }

    // tslint:disable-next-line: variable-name
    private _y: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_Y' })
    public get y(): number {
        return this.getValue<number>(() => this._y, 'y');
    }
    public set y(value: number) {
        this.setValue<number>(() => { this._y = value; }, value, 'y');
    }

    // tslint:disable-next-line: variable-name
    private _rows: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_Rows' })
    public get rows(): number {
        return this.getValue<number>(() => this._rows, 'rows');
    }
    public set rows(value: number) {
        this.setValue<number>(() => { this._rows = value; }, value, 'rows');
    }

    // tslint:disable-next-line: variable-name
    private _cols: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_Cols' })
    public get cols(): number {
        return this.getValue<number>(() => this._cols, 'cols');
    }
    public set cols(value: number) {
        this.setValue<number>(() => { this._cols = value; }, value, 'cols');
    }

}
