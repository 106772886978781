import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, CoreModel, EnumDecorator, StringDecorator, BoolDecorator, NumberDecorator, InternalDecorator, Type, OCCModel } from '@nts/std';
import { DashBoardLayoutIdentity } from './dash-board-layout.identity';
import { DashBoardItem } from './dash-board-item';
import { DashBoardItemCollection } from './dash-board-item.collection';


@IdentityTypeDecorator(DashBoardLayoutIdentity)
@ModelTypeNameDecorator('DashBoardLayout')
export class DashBoardLayout extends OCCModel<DashBoardLayoutIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'DashBoardLayout_Code', maxLength: 100, isRequired: true })
    public get code(): string {
        return this.getValueForIdentityValue<string>(() => this._code, 'code');
    }
    public set code(value: string) {
        this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _description: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'DashBoardLayout_Description', maxLength: 100, isRequired: true, isMainDescription: true })
    public get description(): string {
        return this.getValue<string>(() => this._description, 'description');
    }
    public set description(value: string) {
        this.setValue<string>(() => { this._description = value; }, value, 'description');
    }

    // tslint:disable-next-line: variable-name
    private _isStandard: boolean;

    @Expose()
    @BoolDecorator({ displayNameKey: 'DashBoardLayout_IsStandard' })
    public get isStandard(): boolean {
        return this.getValue<boolean>(() => this._isStandard, 'isStandard');
    }
    public set isStandard(value: boolean) {
        this.setValue<boolean>(() => { this._isStandard = value; }, value, 'isStandard');
    }

    // tslint:disable-next-line: variable-name
    private _userId: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardLayout_UserId', isRequired: false })
    public get userId(): number {
        return this.getValue<number>(() => this._userId, 'userId');
    }
    public set userId(value: number) {
        this.setValue<number>(() => { this._userId = value; }, value, 'userId');
    }

    // tslint:disable-next-line: variable-name
    private _content: DashBoardItemCollection;

    @Expose()
    @InternalDecorator({ isCollection: true, displayNameKey: 'DashBoardLayout_Content' })
    @Type(() => DashBoardItemCollection)
    public get content(): DashBoardItemCollection {
        return this.getValueForInternalCollection<DashBoardItemCollection, DashBoardItem>(
            (value) => {
                this._content = value;
            },
            this._content,
            'content',
            DashBoardItemCollection
        );
    }

    public set content(value: DashBoardItemCollection) {
        this.setValueForInternalCollection<DashBoardItemCollection, DashBoardItem>(() => {
            this._content = value;
        }, value, 'content');
    }

}

