import { Expose } from '@nts/std';

export class OutputDataOrderDto {

    constructor(
    ) {
    }

    @Expose()
    public position: number;

    @Expose()
    public propertyName: string;

    @Expose()
    public isVisible: boolean;

    @Expose()
    public caption: string;
}
