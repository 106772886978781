import { AggregateMetaData, Expose, Type } from '@nts/std';
import { OutputDataOrderDto } from './output-data-order-dto';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetZoomResultValueDto extends WidgetBaseValueDto {

    constructor() {
        super();
        this.layout = [];
        this.result = [];
    }

    @Expose()
    @Type(() => OutputDataOrderDto)
    public layout: OutputDataOrderDto[];

    @Expose()
    //@Type(() => any)
    public result: any[];

    @Expose()
    @Type(() => AggregateMetaData)
    public metaData: AggregateMetaData;
}
