import { NumericPropertyViewModel, ExternalViewModelTypeDecorator, MasterDetailRootViewModel } from '@nts/std';
import { StringPropertyViewModel } from '@nts/std';
import { DashBoardItemIdentity } from '../domain-models/dash-board-item.identity';
import { DashBoardItem } from '../domain-models/dash-board-item';
import { WidgetExtViewModel } from './widget.ext-view-model';


export class DashBoardItemViewModel extends MasterDetailRootViewModel<DashBoardItem, DashBoardItemIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: StringPropertyViewModel;

    public get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _itemId: NumericPropertyViewModel;

    public get itemId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._itemId = value; }, this._itemId, 'itemId');
    }

    // tslint:disable-next-line: member-ordering
    @ExternalViewModelTypeDecorator(WidgetExtViewModel)
    public widgetRef: WidgetExtViewModel;

    // tslint:disable-next-line: variable-name
    private _rows: NumericPropertyViewModel;

    public get rows(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._rows = value; }, this._rows, 'rows');
    }

    // tslint:disable-next-line: variable-name
    private _cols: NumericPropertyViewModel;

    public get cols(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._cols = value; }, this._cols, 'cols');
    }

    // tslint:disable-next-line: variable-name
    private _x: NumericPropertyViewModel;

    public get x(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._x = value; }, this._x, 'x');
    }

    // tslint:disable-next-line: variable-name
    private _y: NumericPropertyViewModel;

    public get y(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._y = value; }, this._y, 'y');
    }
}
