import { ModelTypeNameDecorator, IdentityTypeDecorator, CoreModel, Expose, NumberDecorator, StringDecorator, BaseIdentity } from '@nts/std';

@IdentityTypeDecorator(MenuNodeAssociationIdentity)
@ModelTypeNameDecorator('MenuNodeAssociation')
export class MenuNodeAssociationIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _parentId: number;

    @Expose()
    public get parentId(): number {
        return this.getValue<number>(() => this._parentId, 'parentId');
    }
    public set parentId(value: number) {
        this.setValue<number>(() => { this._parentId = value; }, value, 'parentId');
    }

    // tslint:disable-next-line: variable-name
    private _childId: number;

    @Expose()
    public get childId(): number {
        return this.getValue<number>(() => this._childId, 'childId');
    }
    public set childId(value: number) {
        this.setValue<number>(() => { this._childId = value; }, value, 'childId');
    }
}
