import { Expose, Type } from '@nts/std';
import { MinimalMenuNodeDto } from './dto/menu-node-dto';

export class RouteAdditionalData {

    @Expose()
    @Type(() => MinimalMenuNodeDto)
    nodePath: MinimalMenuNodeDto[];

    @Expose()
    encodedIdentity?: string;

    // example: test1=prova1&test2=prova2
    @Expose()
    args?: string;

    @Expose()
    shortDescription?: string;
}
