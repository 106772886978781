import { LogLevel } from '@nts/std/src/lib/utility';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';

export const environment: EnvironmentConfiguration = {
  production: true,
  baseAppUrl: window.location.protocol + '//' + (window.location.hostname) + (window.location.port ? `:${window.location.port}` : ''),
  authenticationAppUrl: 'https://ntsloginservice.ntsinformatica.it/auth/login',
  prefixApi: '/api',
  envType: 'DEV',
  appTitle: 'NTS Cloud Tech - DEV',
  appSubTitle: '',
  logLevel: LogLevel.All,
  isEnterpriseBarrierRequired: true,
};
