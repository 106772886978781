import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { GenericServiceResponse, ToastMessageService } from '@nts/std';
import { WidgetBarValue } from '../../../domain-models/dto/widget-bar-value-dto';
import { Observable, of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DashBoardItemViewModel } from 'src/app/dash-board/view-models/dash-board-item.view-model';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HC_boost from 'highcharts/modules/boost';
import HC_noDataToDisplay from 'highcharts/modules/no-data-to-display';
import HC_more from 'highcharts/highcharts-more';
import { NgIf } from '@angular/common';

HC_boost(Highcharts);
HC_noDataToDisplay(Highcharts);
HC_more(Highcharts);

@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'nts-bar-widget',
    templateUrl: './bar-widget.component.html',
    styleUrls: ['./bar-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HighchartsChartModule,
        NgIf
    ]
})

// tslint:disable-next-line: max-line-length
export class BarWidgetComponent extends WidgetFullScreenComponent implements OnInit {
    @ViewChild('chartContainer') chartContainer: ElementRef;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options;
    Highcharts: typeof Highcharts = Highcharts;

    title: string;
    subTitle: string;

    /*
    -- LifeCycle --
    ngOnInit
        -> initChart ( va fatta sempre nella OnInit)!
    logChartInstance -> qui hoi il grafico
    initialize  -> qui posso caricare i dati
    ngAfterViewInit
    */

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    protected getLoadDataObservable<WidgetBarValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetBarValue>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getAlertWidgetValue(widgetDataArg) as any;
    }

    protected initView(result: WidgetBarValue): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;

        const series = [];
        const data = [];
        const categories = [];
        const xLabel = result.xLabel;
        const yLabel = result.yLabel;
        const timeLineItems = result.timeLine;

        timeLineItems.forEach(timeLineItem => {
            categories.push(timeLineItem.name);
            data.push(timeLineItem.value);
        });

        // potrei avere piu serie
        series.push({
            name: '',
            type: 'bar',
            data
        });

        // aggiorno il grafico
        this.chart.update({
            xAxis: {
                title: {
                    text: xLabel
                },
                categories
            },
            yAxis: {
                title: {
                    text: yLabel
                },
            },
            series
        }, true, true, true);

        // la prima volta devo effettuar eil timeout per il disegno corretto del grafico dentro il content
        setTimeout(() => {
            this.resizeChanged(null);
        });

        this.cd.detectChanges();

        return of(true);
    }

    ngOnInit() {
        this.initChart();
    }

    initChart() {
        // parametri default
        this.chartOptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                crosshair: true
            },
            yAxis: {
                min: 0,
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            }
        };
    }

    logChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    protected override resizeChanged(dashboardItemVm: DashBoardItemViewModel) {
        super.resizeChanged(dashboardItemVm);
        if (this.chart) {
            this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, this.chartContainer.nativeElement.offsetHeight, true);
        }
    }
}
