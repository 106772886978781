import { Subject } from 'rxjs';
import { ModalViewModelInterface, ViewModelTypeDecorator, OrchestratorViewModelInterface, UIResultCommandInterface, CommandFactory, MessageResourceManager } from '@nts/std';
import { WidgetExtViewModel } from '../widget.ext-view-model';

@ViewModelTypeDecorator(FullScreenChartModalViewModel)
export class FullScreenChartModalViewModel implements ModalViewModelInterface<any, any> {

    modalTitle = '';
    modalSubtitle = '';
    showFooter = true;

    modalCommands = [];

    cancelAction = new Subject<void>();
    widgetFullScreenComponentFactory: any;
    widgetRef: WidgetExtViewModel;
    orchestratorViewModel: OrchestratorViewModelInterface;
    initialData: any;

    private closeCommand: UIResultCommandInterface<boolean, any>;

    constructor() {
        this.setupCommands();
    }

    async initialize(): Promise<void> {

    }

    //aggiunta pulsante "chiudi" nella modale
    private setupCommands() {
        this.closeCommand = CommandFactory.createResultUICommand(async () => false);
        this.closeCommand.displayName = MessageResourceManager.Current.getMessage("CMD_Close");
        this.closeCommand.closeModal = true;
        this.closeCommand.isCancel = true;
        this.closeCommand.isDefault = true;
        this.modalCommands.push(this.closeCommand);
    }


}
