import { ModalViewModelInterface, ViewModelTypeDecorator, MessageResourceManager, UIResultCommandInterface, CommandFactory, ModalService, ToastMessageService, MessageCodes, CodeValueMessageArg } from '@nts/std';
import { WidgetMenuItemShortcutDto } from 'src/app/dash-board/domain-models/dto/widget-menu-item-shortcut.dto';
import { of, Subject, Subscriber } from "rxjs";

@ViewModelTypeDecorator(PinToDashboardMenuItemShortcutModalViewModel)
export class PinToDashboardMenuItemShortcutModalViewModel implements ModalViewModelInterface<any, any> {

    modalTitle = MessageResourceManager.Current.getMessage('PinToDashboardMenuItemShortcutModal_Title');
    modalSubtitle = '';
    showFooter = true;
    modalCommands = [];
    pinCommand: UIResultCommandInterface<boolean>;

    widgetMenuItemShortcutDto: WidgetMenuItemShortcutDto;

    errorList = [];
    shortDescriptionChange = new Subject<string>();

    constructor() {
        this.setupCommands();
    }

    private setupCommands(): void {
        this.pinCommand = CommandFactory.createResultUICommand(async () => {
            this.pinCommand.closeModal = this.widgetMenuItemShortcutDto.shortDescription != '';
            return true;
        }, () => of(true));
        this.pinCommand.displayName = MessageResourceManager.Current.getMessage('PinToDashboardMenuItemShortcutModal_CMD_Pin');
        this.pinCommand.tooltip = MessageResourceManager.Current.getMessage('PinToDashboardMenuItemShortcutModal_CMD_Pin_Tooltip');
        this.pinCommand.isDefault = true;
        this.modalCommands.push(this.pinCommand);

        this.shortDescriptionChange.subscribe(value => {
            this.errorList = [];
            if (value == '') {
                const args = new Array<CodeValueMessageArg>();
                const arg = new CodeValueMessageArg();
                arg.code = 'NomeCampo';
                arg.value = MessageResourceManager.Current.getMessage('PinToDashboardMenuItemShortcutModal_MenuItem_Description');
                args.push(arg);
                this.errorList.push(MessageResourceManager.Current.getMessageWithArgs(MessageCodes.PropertyRequired, args))
            }
            this.widgetMenuItemShortcutDto.shortDescription = value;
        })
    }
    async initialize(args: any): Promise<void> { }

}

