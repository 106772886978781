import { CollectionViewModel, ItemViewModelTypeDecorator } from '@nts/std';
import { DashBoardLayout } from '../domain-models/dash-board-layout';
import { DashBoardLayoutIdentity } from '../domain-models/dash-board-layout.identity';
import { DashBoardLayoutViewModel } from './dash-board-layout.view-model';

@ItemViewModelTypeDecorator(DashBoardLayoutViewModel)
export class DashBoardLayoutCollectionViewModel extends CollectionViewModel<DashBoardLayoutViewModel, DashBoardLayout, DashBoardLayoutIdentity> {

    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, DashBoardLayoutCollectionViewModel.prototype);
    }
}
