import { WidgetBaseValueDto } from './widget-base-value-dto';
import { Expose } from '@nts/std';
import { Type } from '@nts/std';
import { TimeLineItem } from './widget-time-line-item-dto';

export class WidgetTimeLineValue extends WidgetBaseValueDto {

    constructor() {
        super();
        this.timeLine = [];
    }

    @Expose()
    public xLabel: string;

    @Expose()
    public yLabel: string;


    @Expose()
    @Type(() => TimeLineItem)
    public timeLine: TimeLineItem[];

}



