
import { StdModule, WebApiServiceAgent, OnlineService } from '@nts/std';
import { RouterModule } from '@angular/router';
import { NgModule, Provider } from '@angular/core';
import { MenuNodeAssociationApiClient } from './api-clients/menu-node-association.api-client';
import { MenuNodeApiClient } from './api-clients/menu-node.api-client';
import { CommonModule } from '@angular/common';
import { JoyrideModule } from 'ngx-joyride';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { dashBoardIcons } from '../svg/dash-board';
import { MenuModule } from 'primeng/menu';

@NgModule({
    imports: [
        StdModule,
        CommonModule,
        RouterModule,
        MenuModule,
        JoyrideModule.forRoot(),
    ],
    providers: [
        provideSvgIcons([...dashBoardIcons]),
    ],
})
export class DashBoardContainerModule {

    public static getRootProviders(): Provider[] {
        return [
            {
                provide: MenuNodeAssociationApiClient,
                deps: [WebApiServiceAgent, OnlineService]
            },
            {
                provide: MenuNodeApiClient,
                deps: [WebApiServiceAgent, OnlineService]
            },
        ];
    }
}
