import { ModelTypeNameDecorator, IdentityTypeDecorator, CoreModel, Expose, NumberDecorator, StringDecorator, BaseIdentity, IdentityTypeNameDecorator } from '@nts/std';

@IdentityTypeDecorator(WidgetIdentity)
@IdentityTypeNameDecorator('WidgetIdentity')
@ModelTypeNameDecorator('Widget')
export class WidgetIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _code: string;

    @Expose()
    public get code(): string {
        return this.getValue<string>(() => this._code, 'code');
    }
    public set code(value: string) {
        this.setValue<string>(() => { this._code = value; }, value, 'code');
    }
}
