import { RootModelTypeNameDecorator, RootModelTypeDecorator, GenericServiceResponse, StoreRequest, GetByIdentityRequest, GetByIdentityResponse, AvailableLayoutsInfoDto, BaseLayoutDataDto, LayoutDefinitionIdentity, UserLayoutMetaData, ServiceResponse, GenericServiceRequest, CacheOptionsInterface, MetaDataResponse } from '@nts/std';
import { CrudApiClient } from '@nts/std';
import { DashBoardLayoutIdentity } from '../domain-models/dash-board-layout.identity';
import { DashBoardLayout } from '../domain-models/dash-board-layout';
import { DashBoardLayoutListDto } from '../domain-models/dto/dash-board-layout-list-dto';
import { Observable, of } from 'rxjs';
import { StoreResponse } from '@nts/std';
import { CookiePreferencesDTO } from '../domain-models/dto/cookie-preferences-dto';

@RootModelTypeNameDecorator('DashBoardLayout')
@RootModelTypeDecorator(DashBoardLayout)
export class DashBoardLayoutApiClient extends CrudApiClient<DashBoardLayout, DashBoardLayoutIdentity> {

    // Disabilito la gestione dei layout
    override getAvailableLayoutsAsync(layoutDefinitionIdentity?: LayoutDefinitionIdentity): Observable<GenericServiceResponse<AvailableLayoutsInfoDto>> {
        const response: GenericServiceResponse<AvailableLayoutsInfoDto> = new GenericServiceResponse<AvailableLayoutsInfoDto>(AvailableLayoutsInfoDto);
        response.result = new AvailableLayoutsInfoDto();
        response.result.availableLayouts = [];
        return of(response);
    }

    // Disabilito la gestione dei layout
    override getUserLayoutMetaDataAsync(dto: BaseLayoutDataDto): Observable<GenericServiceResponse<UserLayoutMetaData>> {
        const response: GenericServiceResponse<UserLayoutMetaData> = new GenericServiceResponse<UserLayoutMetaData>(UserLayoutMetaData);
        response.result = new UserLayoutMetaData()
        return of(response);
    }

    getLayouts({
        bypass = false,
        expirationTime = 60 * 60 * 24, // scade dopo 24 ore
        force = true,
        enableTimeout = true,
        tenantBarrier = true,
        enterpriseBarrier = false,
        userBarrier = true
    }: CacheOptionsInterface = { bypass: false }): Observable<GenericServiceResponse<DashBoardLayoutListDto>> {
        const response: GenericServiceResponse<DashBoardLayoutListDto> = new GenericServiceResponse<DashBoardLayoutListDto>(
            DashBoardLayoutListDto);
        return this.getWebApiServiceAgent()
            .invokePostWithOutBodyWithInstance<GenericServiceResponse<DashBoardLayoutListDto>>(
                'GetLayouts',
                response,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                    bypass,
                    expirationTime,
                    force,
                    enableTimeout,
                    tenantBarrier,
                    enterpriseBarrier,
                    userBarrier
                },
            );
    }

    storeLayout(request: StoreRequest<DashBoardLayout>): Observable<StoreResponse<DashBoardLayout, DashBoardLayoutIdentity>> {
        const responseInstance = new StoreResponse<DashBoardLayout, DashBoardLayoutIdentity>(DashBoardLayout);
        return this.agent.invokePostWithResponseWithInstance<
            StoreRequest<DashBoardLayout>, StoreResponse<DashBoardLayout, DashBoardLayoutIdentity>>(
                'StoreLayout', request, responseInstance);
    }

    getLayoutByIdentity(
        request: GetByIdentityRequest<DashBoardLayoutIdentity>): Observable<GetByIdentityResponse<DashBoardLayout, DashBoardLayoutIdentity>> {
        const responseInstance = new GetByIdentityResponse<DashBoardLayout, DashBoardLayoutIdentity>(DashBoardLayout);
        return this.agent.invokePostWithResponseWithInstance<
            GetByIdentityRequest<DashBoardLayoutIdentity>, GetByIdentityResponse<DashBoardLayout, DashBoardLayoutIdentity>>(
                'GetLayoutByIdentity',
                request,
                responseInstance,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                    bypass: false,
                    expirationTime: undefined,
                    force: false,
                    enableTimeout: true,
                    tenantBarrier: true,
                    enterpriseBarrier: false,
                    userBarrier: true
                },
            );
    }

    updateCookiePreferences(updateCookiePreferences: CookiePreferencesDTO): Observable<ServiceResponse> {
        const request = new GenericServiceRequest<CookiePreferencesDTO>();
        request.requestData = updateCookiePreferences;
        return this.agent.invokePostWithResponse<GenericServiceRequest<CookiePreferencesDTO>, ServiceResponse>
            ('UpdateCookiePreferences', request, ServiceResponse);
    }

    // Faccio l'override per salvare per 24 ore la getMetaData
    override getMetaDataAsync(
        includeDescriptions = false,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: 60 * 60 * 24,
            force: true,
            enableTimeout: true,
            tenantBarrier: true,
            enterpriseBarrier: true,
            userBarrier: false
        },
        retryRequest = 0
    ): Observable<MetaDataResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.getMetaDataAsync(
            includeDescriptions,
            cacheOptions,
            retryRequest
        );
    }

}

