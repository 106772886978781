import { WidgetComponentInterface } from '../widget-list/widget-component.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, ViewChild, ViewContainerRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalComponentInterface, ModalService, NTSTranslatePipe, ServiceResponse } from '@nts/std';
import { FullScreenChartModalViewModel } from '../../view-models/modals/full-screen-chart-modal-view-model';
import { WidgetFullScreenComponentInterface } from '../widget-list/widget-full-screen-component.interface';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@UntilDestroy()
@Component({
    selector: 'nts-full-screen-modal-container',
    templateUrl: 'full-screen-modal-container.component.html',
    styleUrls: ['./full-screen-modal-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        SvgIconComponent,
        NTSTranslatePipe
    ]
})
export class FullScreenModalContainerComponent implements ModalComponentInterface {

    @ViewChild('widgetPlaceHolder', { read: ViewContainerRef, static: true })
    widgetPlaceHolder: ViewContainerRef;
    viewModel: FullScreenChartModalViewModel;
    public loading = true;
    public error = false;
    public editing = false;
    public widgetInstance: WidgetFullScreenComponentInterface;
    public errorResponse: ServiceResponse;

    constructor(
        private cd: ChangeDetectorRef,
        private modalService: ModalService
    ) { }

    async initialize(): Promise<void> {
        const widgetComponentFactory = this.viewModel.widgetFullScreenComponentFactory;
        const viewContainerRef = this.widgetPlaceHolder;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(widgetComponentFactory);
        this.widgetInstance = componentRef.instance as WidgetFullScreenComponentInterface;
        this.widgetInstance.vm = this.viewModel.widgetRef;
        this.widgetInstance.orchestratorViewModel = this.viewModel.orchestratorViewModel;

        this.widgetInstance.fullScreenMode = true;

        this.viewModel.widgetRef.loadingErrorChanged.pipe(untilDestroyed(this)).subscribe((response) => {
            this.errorResponse = response.operationSuccedeed ? null : response
            this.error = !response.operationSuccedeed;
            this.cd.detectChanges();
        });
        this.widgetInstance.forceHideLoader.pipe(untilDestroyed(this)).subscribe(() => {
            this.loading = false;
            this.cd.detectChanges();
        });
        this.widgetInstance.forceShowLoader.pipe(untilDestroyed(this)).subscribe(() => {
            this.loading = true;
            this.cd.detectChanges();
        });

        // devo visualizzare i dati senza caricarli
        this.widgetInstance.initialData = this.viewModel.initialData;
        this.widgetInstance.isFullScreenMode = true;
        this.viewModel.modalTitle = this.viewModel.widgetRef.name.value
        await this.widgetInstance.initialize();

        if (this.widgetInstance.modalTitle != null) {
            this.viewModel.modalTitle = this.widgetInstance.modalTitle;
        }

        // resetto initialData visto che le prossime volte che faccio refresh devo aggiornare i dati
        this.widgetInstance.initialData = null;

        this.cd.detectChanges();
    }

    errorDetails() {
        this.modalService.showPopUp(this.errorResponse.informations, this.errorResponse.errors);
    }
}
