import { WidgetEmptyGaugeValue } from './../domain-models/dto/widget-empty-gauge-value-dto';
import { RootModelTypeNameDecorator, RootModelTypeDecorator, GenericServiceResponse, GenericServiceRequest, ResponseWithCacheInfo, CacheOptionsInterface, MetaDataResponse } from '@nts/std';
import { CrudApiClient } from '@nts/std';
import { WidgetIdentity } from '../domain-models/widget.identity';
import { Widget } from '../domain-models/widget';
import { Observable } from 'rxjs';
import { WidgetListDto } from '../domain-models/dto/widget-list-dto';
import { WidgetOnOffValue } from '../domain-models/dto/widget-onoff-value-dto';
import { WidgetNumericValue } from '../domain-models/dto/widget-numeric-value-dto';
import { WidgetGaugeValue } from '../domain-models/dto/widget-gauge-value-dto';
import { WidgetProgramLinkValue } from '../domain-models/dto/widget-programlink-value-dto';
import { WidgetDonutValueDto } from '../domain-models/dto/widget-donut-value-dto';
import { WidgetTimeLineValue } from '../domain-models/dto/widget-time-line-value-dto';
import { WidgetBarValue } from '../domain-models/dto/widget-bar-value-dto';
import { WidgetResultSetValue } from '../domain-models/dto/widget-result-set-value-dto';
import { WidgetDataArg } from '../domain-models/dto/widget-data-arg-dto';
import { WidgetProgramLinkDataArg } from '../domain-models/dto/widget-programlink-data-arg-dto';
import { WidgetSeriesValueDto } from '../domain-models/dto/widget-series-value.dto';
import { WidgetResultSetFilterDataArgDto } from '../domain-models/dto/widget-result-set-filter-data-arg-dto';
import { WidgetIconResultSetValueDto } from '../domain-models/dto/widget-icon-result-set-value-dto';
import { WidgetAlertValueDto } from '../domain-models/dto/widget-alert-value-dto';
import { WidgetZoomResultValueDto } from '../domain-models/dto/widget-zoom-result-value';
import { WidgetZoomResultFilterDataArgDto } from '../domain-models/dto/widget-zoom-result-data-arg-dto';
import { WidgetMenuItemShortcutDto } from '../domain-models/dto/widget-menu-item-shortcut.dto';
import { WidgetIdentityShortcutDto } from '../domain-models/dto/widget-identity-shortcut.dto';

@RootModelTypeNameDecorator('Widget')
@RootModelTypeDecorator(Widget)
export class WidgetApiClient extends CrudApiClient<Widget, WidgetIdentity> {

    public getWidgets(): Observable<GenericServiceResponse<WidgetListDto>> {
        const response: GenericServiceResponse<WidgetListDto> = new GenericServiceResponse(WidgetListDto);
        return this.getWebApiServiceAgent()
            .invokePostWithOutBodyWithInstance<GenericServiceResponse<WidgetListDto>>(
                'GetWidgets', response, false, null, null, null, {
                bypass: false,
                // Scadenza in secondi
                expirationTime: 60 * 60, // scade dopo 1 ora
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            }
            );
    }

    public getAlertWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetAlertValueDto>>> {
        const response: GenericServiceResponse<WidgetAlertValueDto> = new GenericServiceResponse(WidgetAlertValueDto);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetAlertValueDto>>(
                'GetAlertWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getOnOffWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetOnOffValue>>> {
        const response: GenericServiceResponse<WidgetOnOffValue> = new GenericServiceResponse(WidgetOnOffValue);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetOnOffValue>>(
                'GetOnOffWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getNumericWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetNumericValue>>> {
        const response: GenericServiceResponse<WidgetNumericValue> = new GenericServiceResponse(WidgetNumericValue);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetNumericValue>>(
                'GetNumericWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getGaugeWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetGaugeValue>>> {
        const response: GenericServiceResponse<WidgetGaugeValue> = new GenericServiceResponse(WidgetGaugeValue);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetGaugeValue>>(
                'GetGaugeWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getEmptyGaugeWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetEmptyGaugeValue>>> {
        const response: GenericServiceResponse<WidgetEmptyGaugeValue> = new GenericServiceResponse(WidgetEmptyGaugeValue);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetEmptyGaugeValue>>(
                'GetEmptyGaugeWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getProgramLinkWidgetValue(widgetProgramLinkDataArg: WidgetProgramLinkDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetProgramLinkValue>>> {
        const response: GenericServiceResponse<WidgetProgramLinkValue> = new GenericServiceResponse(WidgetProgramLinkValue);
        const request: GenericServiceRequest<WidgetProgramLinkDataArg> = new GenericServiceRequest<WidgetProgramLinkDataArg>();
        request.requestData = widgetProgramLinkDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetProgramLinkDataArg>, GenericServiceResponse<WidgetProgramLinkValue>>(
                'GetProgramLinkWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getDonutWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetDonutValueDto>>> {
        const response: GenericServiceResponse<WidgetDonutValueDto> = new GenericServiceResponse(WidgetDonutValueDto);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetDonutValueDto>>(
                'GetDonutWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getTimeLineWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetTimeLineValue>>> {
        const response: GenericServiceResponse<WidgetTimeLineValue> = new GenericServiceResponse(WidgetTimeLineValue);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetTimeLineValue>>(
                'GetTimeLineWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getDynamicTimeLineWidgetValues(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetSeriesValueDto>>> {
        const response: GenericServiceResponse<WidgetSeriesValueDto> = new GenericServiceResponse(WidgetSeriesValueDto);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetSeriesValueDto>>(
                'GetDynamicTimeLineWidgetValues', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getBarWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetBarValue>>> {
        const response: GenericServiceResponse<WidgetBarValue> = new GenericServiceResponse(WidgetBarValue);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetBarValue>>(
                'GetBarWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getResultSetWidgetValue(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetResultSetValue>>> {
        const response: GenericServiceResponse<WidgetResultSetValue> = new GenericServiceResponse(WidgetResultSetValue);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetResultSetValue>>(
                'GetResultSetWidgetValue', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getWidgetIconResultSet(widgetResultSetFilterDataArgDto: WidgetResultSetFilterDataArgDto, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetIconResultSetValueDto>>> {
        const response: GenericServiceResponse<WidgetIconResultSetValueDto> = new GenericServiceResponse(WidgetIconResultSetValueDto);
        const request: GenericServiceRequest<WidgetResultSetFilterDataArgDto> = new GenericServiceRequest<WidgetResultSetFilterDataArgDto>();
        request.requestData = widgetResultSetFilterDataArgDto;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetResultSetFilterDataArgDto>, GenericServiceResponse<WidgetIconResultSetValueDto>>(
                'GetWidgetIconResultSet', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getZoomResult(widgetZoomResultFilterDataArgDto: WidgetZoomResultFilterDataArgDto, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetZoomResultValueDto>>> {
        const response: GenericServiceResponse<WidgetZoomResultValueDto> = new GenericServiceResponse(WidgetZoomResultValueDto);
        const request: GenericServiceRequest<WidgetZoomResultFilterDataArgDto> = new GenericServiceRequest<WidgetZoomResultFilterDataArgDto>();
        request.requestData = widgetZoomResultFilterDataArgDto;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetZoomResultFilterDataArgDto>, GenericServiceResponse<WidgetZoomResultValueDto>>(
                'GetZoomResult', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getMenuItemShortcutData(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetMenuItemShortcutDto>>> {
        const response: GenericServiceResponse<WidgetMenuItemShortcutDto> = new GenericServiceResponse(WidgetMenuItemShortcutDto);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetMenuItemShortcutDto>>(
                'GetMenuItemShortcutData', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    public getIdentityShortcutData(widgetDataArg: WidgetDataArg, bypass = false): Observable<ResponseWithCacheInfo<GenericServiceResponse<WidgetIdentityShortcutDto>>> {
        const response: GenericServiceResponse<WidgetIdentityShortcutDto> = new GenericServiceResponse(WidgetIdentityShortcutDto);
        const request: GenericServiceRequest<WidgetDataArg> = new GenericServiceRequest<WidgetDataArg>();
        request.requestData = widgetDataArg;
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstanceWithCacheInfo
            <GenericServiceRequest<WidgetDataArg>, GenericServiceResponse<WidgetIdentityShortcutDto>>(
                'GetIdentityShortcutData', request, response, false, null, null, null, {
                bypass,
                // Scadenza in secondi
                expirationTime: 60 * 60 * 24, // scade dopo 24 ore
                force: true,
                userBarrier: true,
                tenantBarrier: true,
                enterpriseBarrier: true,
            });
    }

    // Faccio l'override per salvare per 24 ore la getMetaData
    override getMetaDataAsync(
        includeDescriptions = false,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: 60 * 60 * 24,
            force: true,
            enableTimeout: true,
            tenantBarrier: true,
            enterpriseBarrier: true,
            userBarrier: false
        },
        retryRequest = 0
    ): Observable<MetaDataResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.getMetaDataAsync(
            includeDescriptions,
            cacheOptions,
            retryRequest
        );
    }
}
