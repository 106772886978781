import { MenuNodeTypes } from './enum/menu-node-types';
// tslint:disable-next-line: max-line-length
import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, NumberDecorator, EnumDecorator, CoreModel, BoolDecorator, Type, ExternalDecorator } from '@nts/std';
import { StringDecorator } from '@nts/std';
import { MenuNodeAssociationIdentity } from './menu-node-association.identity';
import { MenuNode } from './menu-node';

@IdentityTypeDecorator(MenuNodeAssociationIdentity)
@ModelTypeNameDecorator('MenuNodeAssociation')
export class MenuNodeAssociation extends CoreModel<MenuNodeAssociationIdentity> {

    // tslint:disable-next-line: variable-name
    private _parentId: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'MenuNodeAssociation_ParentId', isRequired: true })
    public get parentId(): number {
        return this.getValueForIdentityValue<number>(() => this._parentId, 'parentId');
    }
    public set parentId(value: number) {
        this.setValueForIdentityValue<number>(() => { this._parentId = value; }, value, 'parentId');
    }

    // tslint:disable-next-line: variable-name
    private _childId: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'MenuNodeAssociation_ChildId', isRequired: true })
    public get childId(): number {
        return this.getValueForIdentityValue<number>(() => this._childId, 'childId');
    }
    public set childId(value: number) {
        this.setValueForIdentityValue<number>(() => { this._childId = value; }, value, 'childId');
    }

    // tslint:disable-next-line: variable-name
    private _parentMenuNodeRef: MenuNode;

    // tslint:disable-next-line: max-line-length
    @ExternalDecorator({ displayNameKey: 'MenuNodeAssociation_ParentMenuNodeRef', principalPName1: 'parentId', dependantPName1: 'id' /*, isRequired: true */ })
    @Expose()
    @Type(() => MenuNode)
    public get parentMenuNode(): MenuNode {
        return this.getValueForExternal<MenuNode>(
            () => this._parentMenuNodeRef,
            'parentMenuNodeRef',
            MenuNode
        );
    }
    public set parentMenuNode(value: MenuNode) {
        this.setValueForExternal(() => {
            this._parentMenuNodeRef = value;
        }, value, 'parentMenuNodeRef');
    }

    // tslint:disable-next-line: variable-name
    private _childMenuNodeRef: MenuNode;

    // tslint:disable-next-line: max-line-length
    @ExternalDecorator({ displayNameKey: 'MenuNodeAssociation_ChildMenuNodeRef', principalPName1: 'childId', dependantPName1: 'id' /*, isRequired: true */ })
    @Expose()
    @Type(() => MenuNode)
    public get childMenuNodeRef(): MenuNode {
        return this.getValueForExternal<MenuNode>(
            () => this._childMenuNodeRef,
            'childMenuNodeRef',
            MenuNode
        );
    }
    public set childMenuNodeRef(value: MenuNode) {
        this.setValueForExternal(() => {
            this._childMenuNodeRef = value;
        }, value, 'childMenuNodeRef');
    }

    // tslint:disable-next-line: variable-name
    private _position: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'MenuNodeAssociation_Position', isRequired: true })
    public get position(): number {
        return this.getValueForIdentityValue<number>(() => this._position, 'position');
    }
    public set position(value: number) {
        this.setValueForIdentityValue<number>(() => { this._position = value; }, value, 'position');
    }
}
