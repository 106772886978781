<div *ngIf="dashboardItemVm?.rows?.value > 1 ;then full else minimize"></div>

<ng-template #full>
    <div class="reporting-visuals-common-scalar" [ngClass]="envClass">
        <div class="top-bar">
            <h2 class="visual-title text-ellipsis text-center" [popper]="title ?? ''"
                [popperDisabled]="!title || title?.length == 0" [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperApplyClass]="'info'" [popperPlacement]="ngxPopperjsPlacements.TOP"
                [popperPreventOverflow]="false" [popperHideOnScroll]="true" popperAppendTo="body" [popperDelay]="1000">
                {{ title }}</h2>
            <!-- <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3> -->
        </div>
        <div class="bottom-bar">
            <div class="icon-ccontent">
                <svg-icon [fontSize]="50" [key]="icon"></svg-icon>
            </div>
            <div class="text-ccontent">
                <span class="visual-title text-ellipsis text-center">{{label}}</span>
            </div>
            <nts-filled-button [title]="subTitle" [label]="'Widget_MenuItemShortcut_CMD_Start' | NTSTranslate"
                (onClicked)="start($event)" tool>
            </nts-filled-button>
        </div>
    </div>
</ng-template>
<ng-template #minimize>
    <div class="reporting-visuals-common-scalar minimize" [ngClass]="envClass">
        <h2 class=" visual-title text-ellipsis text-center" [popper]="title ?? ''"
            [popperDisabled]="!title || title?.length == 0" [popperTrigger]="ngxPopperjsTriggers.hover"
            [popperApplyClass]="'info'" [popperPlacement]="ngxPopperjsPlacements.TOP" [popperPreventOverflow]="false"
            [popperHideOnScroll]="true" popperAppendTo="body" [popperDelay]="1000">
            {{ title }}</h2>
        <nts-filled-button [title]="subTitle" [icon]="'open-in-window'" (onClicked)="start($event)">
        </nts-filled-button>
    </div>
</ng-template>
