import { CollectionViewModel, ItemViewModelTypeDecorator } from '@nts/std';
import { WidgetIdentity } from '../domain-models/widget.identity';
import { Widget } from '../domain-models/widget';
import { WidgetViewModel } from './widget.view-model';

@ItemViewModelTypeDecorator(WidgetViewModel)
export class WidgetCollectionViewModel extends CollectionViewModel<WidgetViewModel, Widget, WidgetIdentity> {

    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, WidgetCollectionViewModel.prototype);
    }
}
