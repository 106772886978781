<div class="results-grid">
    <div class="top-bar">
        <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode"> {{ title }} </h2>
        <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3>
    </div>
    <ag-grid-angular (rowDoubleClicked)="rowDoubleClicked($event)" [components]=" components" [rowSelection]="'single'"
        (gridReady)="onGridReady($event)" style="width: 100%; height: 100%;" class="ag-theme-nts" [rowHeight]="34"
        [rowData]="rowData" (sortChanged)="onSortChanged($event)" (columnResized)="onColumnResized($event)"
        (columnMoved)="onColumnMoved($event)" [columnDefs]="columnDefs" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
