import { ServiceRequest, Expose } from '@nts/std';

export class GetRootNodesRequest extends ServiceRequest {

    @Expose()
    public take: number;

    @Expose()
    public skip: number;
}
