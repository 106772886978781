import { Injectable } from "@angular/core";
import { MessageResourceManager } from "@nts/std";
import { MessageCodes } from "@nts/std";
import { MessageResult } from "@nts/std";
import { MessageButton } from "@nts/std";
import { ModalService } from "@nts/std";
import { TabSocket, Message, MessageType, LogService } from '@nts/std/src/lib/utility';
import { Subject } from "rxjs";
import { MenuNodeViewModelInterface } from "../dash-board-container/view-models/menu-node-view-model.interface";

@Injectable({
    providedIn: 'root'
})
export class RoutingHelper {

    private uuidSubscriberForCurrentRoutePendingChanges: string = null;
    private currentRouteSocket: TabSocket = null;
    private currentRouteModalService: ModalService = null;
    private currentRouteActionInProgress: Subject<boolean> = null;

    public async hasCurrentRoutePendingChanges(): Promise<boolean> {
        if (this.uuidSubscriberForCurrentRoutePendingChanges?.length > 0 &&
            this.currentRouteSocket) {
            // this.currentRouteActionInProgress.next(true);
            const response = await this.currentRouteSocket.sendWithConfirmAsync<string, boolean>('check-pending-changes', MessageType.AskPendingChanges, 100).catch((err) => {
                console.log(err);
                return new Message(false);
            });
            // this.currentRouteActionInProgress.next(false);
            if (response.data === true) {
                const result = await this.currentRouteModalService.showMessageAsync(
                    MessageResourceManager.Current.getMessage(MessageCodes.Warning),
                    MessageResourceManager.Current.getMessage('Modal_CloseConfirmMessage'),
                    MessageButton.YesNo);
                if (result === MessageResult.No) {
                    // currentRouteSocket.socket.destroy();
                }
                return result === MessageResult.No;
            }
        }
        return false;
    }

    public async registerForCurrentRoutePendingChanges(
        uuid: string,
        socket: TabSocket,
        modalService: ModalService,
        onActionInProgress: Subject<boolean>
    ) {
        this.uuidSubscriberForCurrentRoutePendingChanges = uuid;
        this.currentRouteSocket = socket;
        this.currentRouteModalService = modalService;
        this.currentRouteActionInProgress = onActionInProgress;
    }

    public async unregisterForCurrentRoutePendingChanges(uuid: string) {
        if (this.uuidSubscriberForCurrentRoutePendingChanges?.length > 0 &&
            this.uuidSubscriberForCurrentRoutePendingChanges === uuid) {
            this.uuidSubscriberForCurrentRoutePendingChanges = null;
            if (this.currentRouteSocket) {
                this.currentRouteSocket?.destroy();
            }
            this.currentRouteSocket = null;
            this.currentRouteModalService = null;
            this.currentRouteActionInProgress = null;
        } else {
            if (!this.uuidSubscriberForCurrentRoutePendingChanges) {
                LogService.warn('No current route pending changes registered!')
            } else if (this.uuidSubscriberForCurrentRoutePendingChanges !== uuid) {
                LogService.warn('Uuid not match wih currently registered!')
            }
        }
    }

    menuEventDispatcher = new MenuEventDispatcher();
}

class MenuEventDispatcher {
    onPinMenuItem: Subject<MenuNodeViewModelInterface> = new Subject();
    onStartMenuItem: Subject<MenuNodeViewModelInterface> = new Subject();

}
