import { MasterDetailRootViewModel, StringPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, CollectionViewModelTypeDecorator, VersionPropertyViewModel } from '@nts/std';
import { DashBoardLayoutIdentity } from '../domain-models/dash-board-layout.identity';
import { DashBoardLayout } from '../domain-models/dash-board-layout';
import { DashBoardItemCollectionViewModel } from './dash-board-item.collection-view-model';

export class DashBoardLayoutViewModel extends MasterDetailRootViewModel<DashBoardLayout, DashBoardLayoutIdentity> {

    // tslint:disable-next-line: variable-name
    private _occ: VersionPropertyViewModel;

    public get occ(): VersionPropertyViewModel {
        return this.getVersionPropertyViewModel((value) => { this._occ = value; }, this._occ, 'occ');
    }

    // tslint:disable-next-line: variable-name
    private _code: StringPropertyViewModel;

    public get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _description: StringPropertyViewModel;

    public get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    // tslint:disable-next-line: variable-name
    private _isStandard: BoolPropertyViewModel;

    public get isStandard(): BoolPropertyViewModel {
        return this.getBoolPropertyViewModel((value) => { this._isStandard = value; }, this._isStandard, 'isStandard');
    }

    // tslint:disable-next-line: variable-name
    private _userId: NumericPropertyViewModel;

    public get userId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._userId = value; }, this._userId, 'userId');
    }

    /** DashBoardItemCollectionViewModel */
    @CollectionViewModelTypeDecorator(DashBoardItemCollectionViewModel)
    public content: DashBoardItemCollectionViewModel;

}
