<div class="widget-host dashboard-view" [class.mouse-is-over-widget]="mouseIsOverWidget" (mousedown)="onWidgetClick()"
    [class.dashboard-edit-view]="orchestratorViewModel.editingDashBoardStatusChanged | async"
    (mouseover)="mouseIsOverWidget = true" (mouseout)="mouseIsOverWidget = false">
    <div class="widget-container">
        <!-- Caricamento iniziale -->
        <div class="absolute-fill bolt-spinner flex-column text-center" *ngIf="loading">
            <div class="bolt-spinner-circle medium">

            </div>
        </div>
        <!-- Fine Caricamento iniziale -->
        <div class="visual-message-card-container" *ngIf="widgetInstance && (widgetInstance.hasError$ | async)">
            <div class="visual-message-base">
                <h2 [attr.title]="vm.widgetRef.name.value" class="visual-title text-ellipsis">
                    {{ vm.widgetRef.name.value }}
                </h2>
                <div class="message-body">
                    <div class="message-content" [title]="'Widget_Failed_To_Load'|NTSTranslate">
                        {{'Widget_Failed_To_Load'|NTSTranslate}}</div>
                    <div class="message-icon">
                        <div class="bolt-image-wrapper" style="width: 40px; height: 40px;">
                            <img alt="A doctor with a stethoscope checking on a red disc with a white cross inside it, indicating an error with the widget"
                                class="bolt-image flex-noshrink contain"
                                src="assets/images/nts/widget/widget-error.png">
                        </div>
                    </div>
                    <div class="message-action">
                        <span (click)="errorDetails()"
                            aria-label="Widget failed to load. Click to open a dialog box that has error details."
                            class="link bolt-link" role="button" tabindex="0">{{'More_Details'|NTSTranslate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-container" tabindex="0"
            [hidden]="(widgetInstance && (widgetInstance.hasError$ | async)) || loading">
            <div #widgetPlaceHolder></div>
        </div>
    </div>
    <div class="widget-host-overlay"
        [class.widget-host-edit-overlay]="orchestratorViewModel.editingDashBoardStatusChanged | async"
        *ngIf="widgetInstance">

        <div *ngIf="widgetInstance.existUpdate | async" class="update-dot"></div>

        <div class="widget-host-menu-header inline-flex-row"
            [hidden]="orchestratorViewModel.editingDashBoardStatusChanged | async">
            <ng-template ngFor let-toolbarButton [ngForOf]="widgetInstance.toolbarButtons$ | async">
                <button [hidden]="!(toolbarButton.isVisible$ | async)" [class.mouse-is-over-widget]="mouseIsOverWidget"
                    [class.force-is-visible]="(toolbarButton.iconClass$ | async) === 'refresh-animated'" role="button"
                    tabindex="0" type="button" [disabled]="!(toolbarButton.canExecute$ | async)"
                    (click)="toolbarButton.execute(widgetPlaceHolder)">
                    <svg-icon [key]="toolbarButton.iconClass$ | async"></svg-icon>
                </button>
            </ng-template>
        </div>

        <div class="widget-host-edit-row inline-flex-row"
            *ngIf="orchestratorViewModel.editingDashBoardStatusChanged | async">

            <button #customColorPicker *ngIf="widgetInstance.colorEditing" aria-label="Pick color"
                [(colorPicker)]="colorPicker" (colorPickerChange)="updateColorFromColorPicker($event)"
                (colorPickerClose)="onClosingPicker()" [cpOKButton]="false" [cpCancelButton]="false"
                [cpCancelButtonText]="'Annulla'" [(cpToggle)]="showColorPicker" [cpUseRootViewContainer]="true"
                data-focuszone="" data-is-focusable="true" role="button" tabindex="0" type="button"
                [cpExtraTemplate]="customColorPickerTemplate">
                <svg-icon [key]="'color-picker'"></svg-icon>

                <ng-template #customColorPickerTemplate>
                    <div class="picker-footer">
                        <nts-text-button [title]="'Annulla'" [label]="'Annulla'" (onClicked)="cancelPickerClick()">
                        </nts-text-button>
                        <nts-filled-button [isDisabled]="this.disableConfirm" [type]="3" [title]="'Conferma'"
                            [label]="'Conferma'" (onClicked)="confirmPickerClick()">
                        </nts-filled-button>
                    </div>
                </ng-template>

            </button>

            <button *ngIf="(widgetInstance.canShowClock | async)" (click)="opSources.toggle($event)" aria-label="clock"
                data-is-focusable="true" role="button" tabindex="0" type="button">
                <svg-icon [key]="'configurazioni'"></svg-icon>


                <p-overlayPanel #opSources styleClass="general-container-list-overlay">
                    <div class="timer-container">
                        <nts-base-toggle-text-box class="toggle" #baseToggleTextBox [checkStatus]="timerToggleValue"
                            [labelText]="timerToggleTitle | NTSTranslate" (onChange)="onToggleChange($event)"
                            [tabIndex]="0">
                        </nts-base-toggle-text-box>
                        <nts-label-box [isEnabled]="false" [label]="(timerTitle | NTSTranslate)"></nts-label-box>
                        <nts-base-text-box [isDisabled]="true" [value]="timerValue.toString()"></nts-base-text-box>
                        <p-slider [min]="minTimer" [max]="maxTimer" [disabled]="!timerToggleValue"
                            [(ngModel)]="timerValue" styleClass="w-14rem" />
                    </div>
                    <div class="picker-footer">
                        <nts-text-button [title]="'Annulla'" [label]="'Annulla'" (onClicked)="opSources.hide()">
                        </nts-text-button>
                        <nts-filled-button [isDisabled]="this.disableSettingsConfirm" [type]="3" [title]="'Conferma'"
                            [label]="'Conferma'" (onClicked)="confirmSettingsClick(); opSources.hide()">
                        </nts-filled-button>
                    </div>
                </p-overlayPanel>

            </button>

            <button aria-label="Delete" (click)="removeWidget()" class="delete-widget-button" data-focuszone=""
                data-is-focusable="true" role="button" tabindex="0" type="button">
                <svg-icon [key]="'cancel'"></svg-icon>
            </button>

        </div>
    </div>
</div>
