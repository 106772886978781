import { Expose, Type } from '@nts/std';
import { CellDto } from './cell-dto';

export class ResultSetIconItemDto {

    constructor() {
        this.cellList = [];
    }

    @Expose()
    @Type(() => CellDto)
    public cellList: CellDto[];

    @Expose()
    public rootModelFullName: string;

    @Expose()
    public serializedIdentity: string;
}
