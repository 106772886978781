import { IdentityTypeNameDecorator, Expose } from '@nts/std';
import { MenuNodeIdentity } from './menu-node.identity';

@IdentityTypeNameDecorator('MenuNodeActionIdentity')
export class MenuNodeActionIdentity extends MenuNodeIdentity {

    // tslint:disable-next-line: variable-name
    private _actionId: number;

    @Expose()
    public get actionId(): number {
        return this.getValue<number>(() => this._actionId, 'actionId');
    }
    public set actionId(value: number) {
        this.setValue<number>(() => { this._actionId = value; }, value, 'actionId');
    }
}

