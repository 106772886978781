import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseRoutes, InternalServerErrorComponent, NotFoundComponent } from '@nts/std';
import { environment } from 'src/environments/environment';
import { DashBoardModelContainerComponent } from './dash-board-container/components/dash-board-model-container/dash-board-model-container.component';
import { ExternalAppLinkContainerComponent } from './dash-board-container/components/external-app-link-container/external-app-link-container.component';
import { InternalLinkContainerComponent } from './dash-board-container/components/internal-link-container/internal-link-container.component';
import { LinkContainerComponent } from './dash-board-container/components/link-container/link-container.component';
import { RemoteModelContainerComponent } from './dash-board-container/components/remote-model-container/remote-model-container.component';

const title = environment ? environment.appTitle : 'Application Title';

const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      {
        path: `manage/open-external-app-link/:externalAppClientId/:encondedAdditionalData`,
        data: {
          title,
        },
        component: ExternalAppLinkContainerComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: `manage/open-link/:encondedAdditionalData`,
        data: {
          title,
        },
        component: LinkContainerComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: `manage/open-internal-link/:encondedRootModelFullName/:encondedAdditionalData`,
        data: {
          title,
        },
        component: InternalLinkContainerComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: `manage/:encondedRootModelFullName/:encondedAdditionalData`,
        data: {
          title,
        },
        component: RemoteModelContainerComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: `manage/dashboardlayout`,
        redirectTo: 'dashboard',
      },
      {
        path: ``,
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: `dashboard`,
        data: {
          title,
          domainModelName: 'DashBoardLayout'
        },
        component: DashBoardModelContainerComponent,
        loadChildren: () => import('./dash-board/dash-board.module').then(m => m.DashBoardModule),
      },
      { path: 'not-found', component: NotFoundComponent },
      { path: 'internal-server-error', component: InternalServerErrorComponent },
      { path: '**', component: NotFoundComponent },
    ],
    () => import('./dash-board-container/components/dash-board-container/dash-board-container.component').then(mod => mod.DashBoardContainerComponent)
  ),
  // #endregion rotte con autenticazione
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
