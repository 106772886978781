import { Expose } from '@nts/std';
import { WidgetIdentity } from '../widget.identity';

export class WidgetProgramLinkDataArg {

    @Expose()
    public identity: WidgetIdentity;

    @Expose()
    public fullName: string;
}



