<div class="reporting-visuals-common-scalar" style="display: flex; flex-direction: column;">
    <div class="top-bar">
        <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode"> {{ title }} </h2>
        <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3>
    </div>
    <div class="chartContainer" #chartContainer>
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" (chartInstance)="logChartInstance($event)">
        </highcharts-chart>
    </div>
</div>
