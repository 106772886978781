export enum WidgetTypes {
    OnOff = 1,
    Alert = 2,
    Number = 3,
    ProgramLink = 4,
    Gauge = 5,
    EmptyGauge = 6,
    ResultSet = 7,
    TimeLine = 8,
    Bar = 9,
    Donut = 10,
    DynamicTimeline = 11,
    IconResultSet = 12,
    MenuItemShortcut = 13,
    SearchResults = 14,
    IdentityShortcut = 15
}
