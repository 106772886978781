import { MenuNodeTypes } from './enum/menu-node-types';
// tslint:disable-next-line: max-line-length
import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, NumberDecorator, EnumDecorator, CoreModel, BoolDecorator, OCCModel, InternalDecorator, Type, UIInfo } from '@nts/std';
import { StringDecorator } from '@nts/std';
import { MenuNodeIdentity } from './menu-node.identity';
import { MenuNodeAction } from './menu-node-action';
import { MenuNodeActionCollection } from './menu-node-action.collection';
import { LinkOpenTypes } from './enum/link-open-types';

@IdentityTypeDecorator(MenuNodeIdentity)
@ModelTypeNameDecorator('MenuNode')
export class MenuNode extends OCCModel<MenuNodeIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'MenuNode_Id', isRequired: true })
    public get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    public set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    // tslint:disable-next-line: variable-name
    private _shortDescription: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'MenuNode_ShortDescription', maxLength: 25, isRequired: true, isMainDescription: true })
    public get shortDescription(): string {
        return this.getValue<string>(() => this._shortDescription, 'shortDescription');
    }
    public set shortDescription(value: string) {
        this.setValue<string>(() => { this._shortDescription = value; }, value, 'shortDescription');
    }

    // tslint:disable-next-line: variable-name
    private _description: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'MenuNode_Description', maxLength: 100 })
    public get description(): string {
        return this.getValue<string>(() => this._description, 'shortDescription');
    }
    public set description(value: string) {
        this.setValue<string>(() => { this._description = value; }, value, 'shortDescription');
    }

    // tslint:disable-next-line: variable-name
    private _isStandard: boolean;

    @Expose()
    @BoolDecorator({ displayNameKey: 'MenuNode_IsStandard' })
    public get isStandard(): boolean {
        return this.getValue<boolean>(() => this._isStandard, 'isStandard');
    }
    public set isStandard(value: boolean) {
        this.setValue<boolean>(() => { this._isStandard = value; }, value, 'isStandard');
    }

    // tslint:disable-next-line: variable-name
    protected _menuNodeType;

    @Expose()
    @EnumDecorator({ displayNameKey: 'MenuNode_MenuNodeType' })
    public get menuNodeType(): MenuNodeTypes {
        return this.getValue<MenuNodeTypes>(() => this._menuNodeType, 'menuNodeType');
    }
    public set menuNodeType(value: MenuNodeTypes) {
        this.setValue<MenuNodeTypes>(() => { this._menuNodeType = value; }, value, 'menuNodeType');
    }

    // tslint:disable-next-line: variable-name
    private _rootModelFullName: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'MenuNode_RootModelFullName', maxLength: 500 })
    public get rootModelFullName(): string {
        return this.getValue<string>(() => this._rootModelFullName, 'rootModelFullName');
    }
    public set rootModelFullName(value: string) {
        this.setValue<string>(() => { this._rootModelFullName = value; }, value, 'rootModelFullName');
    }

    // tslint:disable-next-line: variable-name
    private _args: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'MenuNode_Args', maxLength: 500 })
    public get args(): string {
        return this.getValue<string>(() => this._args, 'args');
    }
    public set args(value: string) {
        this.setValue<string>(() => { this._args = value; }, value, 'args');
    }


    private _externalAppClientId: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'MenuNode_ExternalAppClientId', maxLength: 100 })
    public get externalAppClientId(): string {
        return this.getValue<string>(() => this._externalAppClientId, 'externalAppClientId');
    }
    public set externalAppClientId(value: string) {
        this.setValue<string>(() => { this._externalAppClientId = value; }, value, 'externalAppClientId');
    }

    // tslint:disable-next-line: variable-name
    protected _linkOpenType;

    @Expose()
    @EnumDecorator({ displayNameKey: 'MenuNode_LinkOpenType' })
    public get linkOpenType(): LinkOpenTypes {
        return this.getValue<LinkOpenTypes>(() => this._linkOpenType, 'linkOpenType');
    }
    public set linkOpenType(value: LinkOpenTypes) {
        this.setValue<LinkOpenTypes>(() => { this._linkOpenType = value; }, value, 'linkOpenType');
    }

    // tslint:disable-next-line: variable-name
    protected _isOffLine;

    @Expose()
    @BoolDecorator({ displayNameKey: 'MenuNode_WorkingMode' })
    public get isOffLine(): boolean {
        return this.getValue<boolean>(() => this._isOffLine, 'isOffLine');
    }
    public set isOffLine(value: boolean) {
        this.setValue<boolean>(() => { this._isOffLine = value; }, value, 'isOffLine');
    }

    // tslint:disable-next-line: variable-name
    private _actions: MenuNodeActionCollection;

    @Expose()
    @InternalDecorator({ isCollection: true, displayNameKey: 'MenuNode_Actions' })
    @Type(() => MenuNodeActionCollection)
    public get actions(): MenuNodeActionCollection {
        return this.getValueForInternalCollection<MenuNodeActionCollection, MenuNodeAction>(
            (value) => {
                this._actions = value;
            },
            this._actions,
            'actions',
            MenuNodeActionCollection
        );
    }

    public set actions(value: MenuNodeActionCollection) {
        this.setValueForInternalCollection<MenuNodeActionCollection, MenuNodeAction>(() => {
            this._actions = value;
        }, value, 'actions');
    }

    // Property non presente nei meta datai per gestire le icone
    @Expose()
    public iconClass: string;
}
