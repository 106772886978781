<div class="widgetContainer">
    <div class="grid-item react-grid-item react-draggable cssTransforms">
        <div class="widget-host dashboard-view">
            <div class="widget-container">
                <div class="content-container" tabindex="0">
                    <div class="reporting-visuals-common-scalar" [style.background-color]="backgroundColor">
                        <div class="content">
                            <div class="top-bar">
                                <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode">{{title}}
                                </h2>
                                <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3>
                            </div>
                            <div class="bottom-bar">
                                <p class=" count">{{currentValue}}</p>
                            </div>
                        </div>
                        <div class="update-info">
                            {{'LastUpdate'|NTSTranslate}}: {{(lastUpdate * 1000)| date: 'dd-MM-yyyy HH:mm' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
