import { WidgetBaseValueDto } from './widget-base-value-dto';
import { Expose } from '@nts/std';

export class WidgetOnOffValue extends WidgetBaseValueDto {

    @Expose()
    public currentState: boolean;

    @Expose()
    public onLabel: string;

    @Expose()
    public offLabel: string;
}



