import { Expose } from '@nts/std';
import { MenuNodeTypes } from '../enum/menu-node-types';

export class MinimalMenuNodeDto {
    @Expose()
    public id: number;

    @Expose()
    public rootModelFullName: string;

    @Expose()
    public menuNodeType: MenuNodeTypes;

}

export class MenuNodeDto extends MinimalMenuNodeDto {

    @Expose()
    public shortDescription: string;

    @Expose()
    public description: string;
}
