import { Expose, Type } from '@nts/std';
import { WidgetIdentity } from '../widget.identity';

export class WidgetBaseValueDto {

    @Expose()
    public title: string;

    @Expose()
    public subTitle: string;

    @Expose()
    @Type(() => WidgetIdentity)
    public widgetRef: WidgetIdentity;
}
