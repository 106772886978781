import { ItemViewModelTypeDecorator } from '@nts/std';
import { DashBoardItemIdentity } from '../domain-models/dash-board-item.identity';
import { DashBoardItem } from '../domain-models/dash-board-item';
import { DashBoardItemViewModel } from './dash-board-item.view-model';
import { BaseSerialCollectionViewModel } from '@nts/std';

// @ItemViewModelTypeDecorator(AccomodationContactVM)
@ItemViewModelTypeDecorator(DashBoardItemViewModel)
export class DashBoardItemCollectionViewModel extends BaseSerialCollectionViewModel<DashBoardItemViewModel, DashBoardItem, DashBoardItemIdentity> {

    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, DashBoardItemCollectionViewModel.prototype);
    }
}
