import { Expose, UIInfo, Type } from '@nts/std';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetProgramLinkValue extends WidgetBaseValueDto {

    @Expose()
    @Type(() => UIInfo)
    public uiInfo: UIInfo;
}



