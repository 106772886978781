import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { GenericServiceResponse, NTSTranslatePipe, ToastMessageService } from '@nts/std';
import { WidgetNumericValue } from '../../../domain-models/dto/widget-numeric-value-dto';
import { from, map, Observable, of, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DatePipe, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-numeric-widget',
    templateUrl: './numeric-widget.component.html',
    styleUrls: ['./numeric-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NTSTranslatePipe,
        DatePipe
    ]
})

export class NumericWidgetComponent extends WidgetFullScreenComponent {

    // per la card
    backgroundColor: string;
    title: string;
    subTitle: string;
    currentValue: number;

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    // Al momento è necessario inserire in questa funzione il set del colore, in futuro si potrà stabilire background come property di base
    protected override async updateColor() {
        await super.updateColor();
        this.backgroundColor = await this.getCurrentWidgetColor();
    }

    protected getLoadDataObservable<WidgetNumericValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetNumericValue>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getNumericWidgetValue(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected override isVisibleFullscreenOptions(): Observable<boolean> {
        return of(!this.sameWidgetInFullScreen);
    }

    protected initView(result: WidgetNumericValue): Observable<boolean> {
        return from(this.updateColor()).pipe(map(() => {
            this.title = result.title;
            this.modalTitle = result.title;
            this.subTitle = result.subTitle;

            this.currentValue = result.currentValue;

            this.cd.detectChanges();

            return true;
        }));
    }

    onSelect(event) {
    }
}
