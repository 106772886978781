import { DashBoardLayout } from '../dash-board-layout';
import { Expose, Type } from '@nts/std';

export class DashBoardLayoutListDto {

    @Expose()
    @Type(() => DashBoardLayout)
    public layouts: DashBoardLayout[];

    constructor() {
        this.layouts = [];
    }

}
