import { Component, ViewContainerRef } from '@angular/core';
import { AuthService } from '@nts/std';
import { CookieLoader } from './cookie/cookie-loader';
import { DashBoardLayoutApiClient } from './dash-board/api-clients/dash-board-layout.api-client';
import { filter, take } from 'rxjs'
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { OnlineService } from '@nts/std/src/lib/utility';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public vcRef: ViewContainerRef,
    private authService: AuthService,
    private dashBoardLayoutApiClient: DashBoardLayoutApiClient,
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private onlineService: OnlineService
  ) {

    if (this.onlineService.isOnline === false) {
      this.onlineService.isOnline$.pipe(untilDestroyed(this), filter(() => this.onlineService.isOnline), take(1)).subscribe(() => {
        if (this.onlineService.isOnline) {
          this.initCookie();
        }
      })
    } else {
      setTimeout(() => this.initCookie());
    }
  }

  private async initCookie() {
    const success = await this.authService.waitUntilAccessTokenIsValidAndAvailable();
    if (success) {
      const cookieLoader = new CookieLoader(this.dashBoardLayoutApiClient, this.cookieService, this.httpClient, this.onlineService);
      cookieLoader.init();
    } else {
      this.authService.onAccessTokenChanged.pipe(untilDestroyed(this), take(1)).subscribe(async () => {
        const cookieLoader = new CookieLoader(this.dashBoardLayoutApiClient, this.cookieService, this.httpClient, this.onlineService);
        cookieLoader.init();
      })
    }
  }
}
