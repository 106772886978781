import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BaseTextBoxComponent, FilledButtonComponent, NTSTranslatePipe, TextButtonComponent, WingViewComponentInterface } from '@nts/std';
import { WingViewModelInterface } from '@nts/std';
import { DashBoardLayoutOrchestratorViewModel } from '../../view-models/dash-board-layout.orchestrator-view-model';
import { WidgetViewModel } from '../../view-models/widget.view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { JoyrideModule } from 'ngx-joyride';
import { WidgetFilterPipe } from './widget.filter.pipe';

@UntilDestroy()
@Component({
    selector: 'nts-dash-board-wing',
    templateUrl: './dash-board-wing.component.html',
    styleUrls: ['./dash-board-wing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NTSTranslatePipe,
        NgIf,
        TextButtonComponent,
        FilledButtonComponent,
        BaseTextBoxComponent,
        AsyncPipe,
        NgFor,
        JoyrideModule,
        WidgetFilterPipe
    ]
})
export class DashBoardWingComponent implements WingViewComponentInterface, OnInit {

    wingViewModel: WingViewModelInterface;
    orchestratorViewModel: DashBoardLayoutOrchestratorViewModel;
    searchText = '';

    constructor(private cd: ChangeDetectorRef) { }

    async onWingCollapsedChanged(isCollapsed: boolean): Promise<void> {
    }

    ngOnInit(): void {
        this.orchestratorViewModel.loadingWidgetsStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.cd.detectChanges();
        });
    }

    async initialize(): Promise<void> {
        this.orchestratorViewModel = this.wingViewModel.orchestratorViewModel as DashBoardLayoutOrchestratorViewModel;
    }

    selectWidget(widget: WidgetViewModel) {
        this.orchestratorViewModel.widgets.selection = [widget];
    }

    public async addSelectedWidget() {
        await this.orchestratorViewModel.addSelectedWidget();
        await this.orchestratorViewModel.storeDashBoard();
    }

    dragStartHandler(ev) {
        ev.dataTransfer.setData('text/plain', 'Drag Me Button');
        ev.dataTransfer.dropEffect = 'copy';
        this.orchestratorViewModel.selectedWidgetDragStarted.next();
    }

    onNextAddSelectedWidgetTourStep() {
        this.orchestratorViewModel.storeDashBoard();
    }

    onDoneAddSelectedWidgetTourStep() {
        this.orchestratorViewModel.storeDashBoard();
    }

    onDoneSearchWidgetsTourStep() {
        this.orchestratorViewModel.storeDashBoard();
    }

    valueChange($event) {
        this.searchText = $event;
    }

    search($event) {
        this.searchText = this.searchText;
    }
}
