import { IdentityTypeDecorator, ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, StringPropertyViewModel, ExternalViewModelTypeDecorator, ServiceResponse } from '@nts/std';
import { ExternalViewModel } from '@nts/std';
import { Widget } from '../domain-models/widget';
import { WidgetIdentity } from '../domain-models/widget.identity';
import { EnumPropertyViewModel } from '@nts/std';
import { BoolPropertyViewModel } from '@nts/std';
import { NumericPropertyViewModel } from '@nts/std';
import { BehaviorSubject, Subject } from 'rxjs';
import { DashBoardItemViewModel } from './dash-board-item.view-model';

@IdentityTypeDecorator(WidgetIdentity)
@ExternalDomainModelTypeDecorator(Widget)
@ExternalDomainModelTypeNameDecorator('Widget')
export class WidgetExtViewModel extends ExternalViewModel<Widget, WidgetIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: StringPropertyViewModel;

    public get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _rootModelFulName: StringPropertyViewModel;

    public get rootModelFulName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._rootModelFulName = value; }, this._rootModelFulName, 'rootModelFulName');
    }

    // tslint:disable-next-line: variable-name
    private _name: StringPropertyViewModel;

    public get name(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._name = value; }, this._name, 'name');
    }


    // tslint:disable-next-line: variable-name
    private _description: StringPropertyViewModel;

    public get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    // tslint:disable-next-line: variable-name
    private _widgetType: EnumPropertyViewModel;

    public get widgetType(): EnumPropertyViewModel {
        return this.getEnumPropertyViewModel((value) => { this._widgetType = value; }, this._widgetType, 'widgetType');
    }

    // tslint:disable-next-line: variable-name
    @ExternalViewModelTypeDecorator(WidgetExtViewModel)
    public linkedWidgetRef: WidgetExtViewModel;

    // tslint:disable-next-line: variable-name
    private _isStandard: BoolPropertyViewModel;

    public get isStandard(): BoolPropertyViewModel {
        return this.getBoolPropertyViewModel((value) => { this._isStandard = value; }, this._isStandard, 'isStandard');
    }

    // tslint:disable-next-line: variable-name
    private _queryCode: StringPropertyViewModel;

    public get queryCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._queryCode = value; }, this._queryCode, 'queryCode');
    }

    // tslint:disable-next-line: variable-name
    private _minCols: NumericPropertyViewModel;

    public get minCols(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._minCols = value; }, this._minCols, 'minCols');
    }

    // tslint:disable-next-line: variable-name
    private _maxCols: NumericPropertyViewModel;

    public get maxCols(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._maxCols = value; }, this._maxCols, 'maxCols');
    }

    // tslint:disable-next-line: variable-name
    private _minRows: NumericPropertyViewModel;

    public get minRows(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._minRows = value; }, this._minRows, 'minRows');
    }

    // tslint:disable-next-line: variable-name
    private _maxRows: NumericPropertyViewModel;

    public get maxRows(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._maxRows = value; }, this._maxRows, 'maxRows');
    }

    // tslint:disable-next-line: variable-name
    private _defaultCols: NumericPropertyViewModel;

    public get defaultCols(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._defaultCols = value; }, this._defaultCols, 'defaultCols');
    }

    // tslint:disable-next-line: variable-name
    private _defaultRows: NumericPropertyViewModel;

    public get defaultRows(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._defaultRows = value; }, this._defaultRows, 'defaultRows');
    }

    // Custom property
    public loadingErrorChanged = new BehaviorSubject<ServiceResponse>(new ServiceResponse());

    public resizeChanged = new Subject<DashBoardItemViewModel>();
    public dragStarted = new Subject<void>();

}
