import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { GenericServiceResponse, NTSTranslatePipe, ToastMessageService } from '@nts/std';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { Observable, map, of, tap } from 'rxjs';
import { WidgetAlertValueDto } from '../../../domain-models/dto/widget-alert-value-dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DatePipe, NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@UntilDestroy()
@Component({
    selector: 'nts-alert-widget',
    templateUrl: './alert-widget.component.html',
    styleUrls: ['./alert-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        SvgIconComponent,
        DatePipe,
        NTSTranslatePipe
    ]
})
export class AlertWidgetComponent extends WidgetFullScreenComponent {

    title: string;
    subTitle: string;
    backgroundColor: string;
    icon: string;
    label: string;

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    protected getLoadDataObservable<WidgetAlertValueDto>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetAlertValueDto>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getAlertWidgetValue(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected override isVisibleFullscreenOptions(): Observable<boolean> {
        return of(!this.sameWidgetInFullScreen);
    }

    protected initView(result: WidgetAlertValueDto): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;

        if (result.currentState) {
            this.icon = 'cancel-circled';
            this.label = result.alertLabel;
            this.backgroundColor = '#d9534f';
        } else {
            this.icon = 'check-circled';
            this.label = result.regularLabel;
            this.backgroundColor = '#5cb85c';
        }

        this.cd.detectChanges();

        return of(true);
    }
}
