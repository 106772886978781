<div class="reporting-visuals-common-scalar" [style.background-color]="backgroundColor">
    <div class="top-bar">
        <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode"> {{ title }} </h2>
        <h3 class="visual-subtitle text-subtile-ellipsis text-center" *ngIf="subTitle?.length>0"> {{ subTitle }} </h3>
    </div>
    <div class="bottom-bar">
        <div class="icon-ccontent">
            <svg-icon [fontSize]="50" [key]="icon"></svg-icon>
        </div>
        <div class="text-ccontent">
            <span class="visual-title text-ellipsis text-center">{{label}}</span>
        </div>
    </div>
    <div class="update-info">
        {{'LastUpdate'|NTSTranslate}}: {{(lastUpdate * 1000) | date: 'dd-MM-yyyy HH:mm'}}
    </div>
</div>
