import { Expose, Type } from "@nts/std";
import { MenuNodeUiWithChildren } from "../menu-node-ui-with-children";

export class OffLineTreeDataDto {

    constructor() {
        this.tree = [];
    }

    @Expose()
    public version: string;

    @Expose()
    @Type(() => MenuNodeUiWithChildren)
    public tree: MenuNodeUiWithChildren[];
}
