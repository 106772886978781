import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { ToastMessageService, RootViewModelTypeDecorator, ViewModelFactory, IdentityTypeDecorator, MasterViewModel, ModalService, AuthService, OrchestratorViewModel, RootModelTypeInspector, InternalCollectionMetaData, CollectionViewModel, GetByIdentityRequest, CoreOrchestratorViewModel, MessageContainer, ToolBarViewModelInterface, ToolBarViewModel, ViewModelInterface, CommandTypes, MsgClearMode, ViewModelStates, GetByIdentityResponse, ServiceResponse, MessageResourceManager, BaseError, MessageCodes, MetaDataResponse, ZoomAdvancedOptions, DomainModelMetaData, ZoomUIStarterArgs, ExternalMetaData, MetaDataUtils, ZoomStarterMode, ZoomOrchestratorViewModelResolver, ZoomResult } from '@nts/std';
import { DashBoardLayoutIdentity } from '../domain-models/dash-board-layout.identity';
import { DashBoardLayoutViewModel } from './dash-board-layout.view-model';
import { DashBoardLayoutApiClient } from '../api-clients/dash-board-layout.api-client';
import { DashBoardLayout } from '../domain-models/dash-board-layout';
import { WidgetApiClient } from '../api-clients/widget.api-client';
import { DashBoardLayoutCollectionViewModel } from './dash-board-layout.collection-view-model';
import { DomainModelCollection } from '@nts/std';
import { DashBoardToolBarViewModel } from './dash-board-layout.tool-bar-view-model';
import { BehaviorSubject, Subject, Observable, of, firstValueFrom, takeUntil } from 'rxjs';
import { WidgetCollectionViewModel } from './widget.collection-view-model';
import { WidgetIdentity } from '../domain-models/widget.identity';
import { Widget } from '../domain-models/widget';
import { DashBoardItem } from '../domain-models/dash-board-item';
import cloneDeep from 'lodash-es/cloneDeep';
import { StoreRequest } from '@nts/std';
import { LocalstorageHelper, LogService, OnlineService } from '@nts/std/src/lib/utility';
import { RoutingHelper } from 'src/app/helper/routing-helper';

@Injectable()
@RootViewModelTypeDecorator(DashBoardLayoutViewModel)
@IdentityTypeDecorator(DashBoardLayoutIdentity)
export class DashBoardLayoutOrchestratorViewModel extends OrchestratorViewModel<DashBoardLayoutViewModel, DashBoardLayoutApiClient, DashBoardLayout, DashBoardLayoutIdentity> {

  public refreshAllWidgets = new Subject<void>();
  public editingDashBoardStatusChanged = new BehaviorSubject<boolean>(false);
  public selectedWidgetDragStarted = new Subject<void>();
  public loadingWidgetsStatusChanged = new BehaviorSubject<boolean>(false);
  public beginTourRequested = new BehaviorSubject<string[]>([]);

  // tslint:disable-next-line: member-ordering
  public layouts: DashBoardLayoutCollectionViewModel;
  dashboardLayout: DashBoardLayout = null;

  public widgets: WidgetCollectionViewModel;

  public override readonly showCurrentState = false;

  public override getToolBarMenu(): ToolBarViewModelInterface {
    return new DashBoardToolBarViewModel(this);
  }

  public override async buildGridColumns(): Promise<void> {
    // non necessario
  }

  public override canAccessToLayout(): Observable<boolean> {
    return of(false);
  }

  public override canAccessToSecurity(): Observable<boolean> {
    return of(false);
  }

  constructor(
    apiClient: DashBoardLayoutApiClient,
    public widgetApiClient: WidgetApiClient,
    modalService: ModalService,
    env: EnvironmentConfiguration,
    authService: AuthService,
    toastMessageService: ToastMessageService,
    onlineService: OnlineService,
    routingHelper: RoutingHelper
  ) {
    super(apiClient, modalService, env, authService, toastMessageService, onlineService);
    routingHelper.menuEventDispatcher.onPinMenuItem.subscribe(async () => {
      this.pinMenuItem()
    })
  }

  async pinMenuItem() {
    this.eventDispatcher.onActionInProgress.next(true);
    const result = await this.checkAllWidgets();
    if (result) {
      setTimeout(() => {
        this.eventDispatcher.onActionInProgress.next(false);
        const domElement = window.document.getElementById('widgets-list');
        domElement?.scrollTo({
          top: domElement.scrollHeight,
          behavior: 'smooth',
        })
      }, 1000)
    } else {
      this.eventDispatcher.onActionInProgress.next(false);
    }
  }

  public override async create(): Promise<ServiceResponse> {
    // Disable creation
    return new ServiceResponse();
  }

  override get title() {
    return 'Dashboard';
  }

  public override async initialize(): Promise<void> {

    const internalCollectionMetaData = new InternalCollectionMetaData();
    internalCollectionMetaData.dependentMetaData = this.metadata.rootMetaData;
    let layouts: DashBoardLayout[] = [];

    try {
      const layoutResponse = await firstValueFrom(this.apiClient.getLayouts());
      layouts = layoutResponse?.result?.layouts ?? [];
    } catch (err) {
      LogService.warn(err);
    }

    const collection = new DomainModelCollection<DashBoardLayout, DashBoardLayoutIdentity>(
      null, null, layouts, false, DashBoardLayout);

    this.layouts = await ViewModelFactory.createCollectionViewModel<DashBoardLayoutCollectionViewModel>(
      DashBoardLayoutCollectionViewModel, collection, this.metadata, this, internalCollectionMetaData, '', false, DashBoardLayout);

    // TODO simulazione click nel primo layout, successivamente aggiungere componente lista
    if (this.actionInProgress == false) {
      this.eventDispatcher.onActionInProgress.next(true);
    }

    const currentUserId: number = await this.authService.getCurrentUserId();

    if (currentUserId === 0) {
      this.modalService.showExceptionPopUp(new Error('Utente non trovato!'));
    }

    const isOnline = this.onlineService.isOnline;

    this.dashboardLayout = this.layouts.collection.collectionItems.find(i => i.userId === currentUserId) ||
      this.layouts.collection.collectionItems.pop();

    if (this.dashboardLayout) {

      await this.getByIdentity(this.dashboardLayout.currentIdentity);
      const currentRootViewmodel = this.rootViewModel;

      let widgetMetaData = null;
      this.loadingWidgetsStatusChanged.next(true);
      this.widgetApiClient.getMetaDataAsync().pipe(takeUntil(this.destroySubscribers$)).subscribe(async (widgetMetaDataResponse) => {
        if (widgetMetaDataResponse.operationSuccedeed === true) {
          widgetMetaData = widgetMetaDataResponse.result;
        }

        const widgetResponse = await this.widgetApiClient.getWidgets().toPromise();
        if (widgetResponse.operationSuccedeed) {
          const widgetCollection = new DomainModelCollection<Widget, WidgetIdentity>(
            null, null, widgetResponse.result.widgets, false, Widget);

          const widgetCollectionMetaData = new InternalCollectionMetaData();
          widgetCollectionMetaData.dependentMetaData = widgetMetaData.rootMetaData;
          // this.metadata.rootMetaData.internalCollections[0].dependentMetaData.externals[0].dependentAggregateMetaData

          this.widgets = await ViewModelFactory.createCollectionViewModel<WidgetCollectionViewModel>(
            WidgetCollectionViewModel, widgetCollection, widgetMetaData, this, widgetCollectionMetaData, '', false, Widget);

          // Ho widget nella wing?
          if (this.widgets?.length > 0) {

            // Preseleziono il primo widget nella wing in modo da abilitare il pulsante aggiungi
            this.widgets.setSelectionFirstRow();

            // Non ho ancora aggiunto widget nella dashboard
            if ((currentRootViewmodel as DashBoardLayoutViewModel)?.content?.length === 0) {
              const skipTour: boolean = await LocalstorageHelper.getStorageItem('skipTour') === 'skip';

              if (!skipTour) {

                await LocalstorageHelper.setStorageItem('skipTour', 'skip');
                this.beginTourRequested.next([
                  'addFirstWidget',
                  'searchWidgets',
                  'firstWidgetSelected',
                  'addSelectedWidget',
                  'menuToggle'
                ]);
              }
            }

          }
        }
        this.loadingWidgetsStatusChanged.next(false);
      });

    } else if (!isOnline) {
      // Crea un rootViewModel vuoto
      const newEntity = new DashBoardLayout();
      await this.tryRebuildViewModelAsyncWithoutResponse(newEntity, false, true);

    } else {
      this.modalService.showExceptionPopUp(new Error('Dashboard non trovata!'));
    }

    this.eventDispatcher.onActionInProgress.next(false);

    this.eventDispatcher.onWingCollapsed.pipe(takeUntil(this.destroySubscribers$)).subscribe(async (isCollapsed) => {
      if (!isCollapsed) {
        this.editingDashBoardStatusChanged.next(true);
      } else {
        await this.executeStore();
        this.editingDashBoardStatusChanged.next(false);
      }
    })
  }

  public editDashBoard() {
    this.editingDashBoardStatusChanged.next(true);
  }

  public addWidgetDashBoard() {
    this.editingDashBoardStatusChanged.next(true);
    this.eventDispatcher.onWingCollapsed.next(false);
  }

  override canAccessToUserLayout(): Observable<boolean> {
    return of(false)
  }

  private async executeStore(): Promise<boolean> {
    if (this.currentState.value !== ViewModelStates.Unchanged) {
      const success = await this.store();
      if (success) {
        return true;
      }
    }
    return false;
  }

  public async storeDashBoard(): Promise<boolean> {
    await this.executeStore();
    this.editingDashBoardStatusChanged.next(false);
    this.eventDispatcher.onWingCollapsed.next(true);
    return true;
  }

  public override async getByIdentity(identity: DashBoardLayoutIdentity): Promise<ServiceResponse> {
    let response = new ServiceResponse();

    if (await this.currentState.canGetByIdentity().toPromise()) {
      response = await this.getByIdentityImplementationAsync(identity);
    } else {
      const error = new BaseError();
      error.code = 'NotAllowed';
      error.description = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
        MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[CommandTypes.Create]));
      response.errors = [error];
      this.notAllowedAction(CommandTypes.GetByIdentity);
    }
    return response;
  }

  protected override async getByIdentityImplementationAsync(identity: DashBoardLayoutIdentity): Promise<GetByIdentityResponse<DashBoardLayout, DashBoardLayoutIdentity>> {
    return await this.loadByIdentityImplementationAsync(identity);
  }

  public override async loadByIdentityImplementationAsync(identity: DashBoardLayoutIdentity): Promise<GetByIdentityResponse<DashBoardLayout, DashBoardLayoutIdentity>> {

    const request = new GetByIdentityRequest<DashBoardLayoutIdentity>();
    request.identity = identity;
    return await this.apiClient.getLayoutByIdentity(request)
      .toPromise()
      .then(async (response) => {
        if (response.operationSuccedeed && response.result != null) {
          await this.tryRebuildViewModelAsync(response, response.result, false, true);
          this.currentState.getByIdentity();
          this.viewModelValidate(false);
        } else {
          this.refreshMessagesFromResponse(response, MsgClearMode.ClearOnlyTemporaryMessage);
        }
        return response;
      }).catch(err => {
        const response = new GetByIdentityResponse<DashBoardLayout, DashBoardLayoutIdentity>(this.apiClient.rootModelType);
        response.operationSuccedeed = false;
        return response;
      });
  }

  override removeError(item: MessageContainer) {
    throw new Error('Method not implemented.');
  }

  public async addSelectedWidget(x?: number, y?: number) {
    if (this.widgets?.selection?.length > 0) {

      const widgetToAdd = cloneDeep(this.widgets.selection[0].getDomainModel());
      const dashBoardItem = new DashBoardItem();
      dashBoardItem.cols = widgetToAdd.defaultCols;
      dashBoardItem.rows = widgetToAdd.defaultRows;
      dashBoardItem.y = y || 0;
      dashBoardItem.x = x || 0;
      dashBoardItem.widgetRef = widgetToAdd;
      await this.rootViewModel.content.addFromEntity(dashBoardItem);
    }
  }

  public override async store(): Promise<boolean> {
    let storeSuccedeed = false;
    try {
      if (this.actionInProgress == false) {
        this.eventDispatcher.onActionInProgress.next({ inProgress: true, loaderAnimation: true });
      }
      storeSuccedeed = await this.storeImplementation();
    } catch (error) {
      console.error(error);
    } finally {
      this.eventDispatcher.onActionInProgress.next(false);
    }
    return storeSuccedeed;
  }

  protected override async storeImplementation(): Promise<boolean> {
    let operationSuccedeed = false;

    if (await this.currentState.canStore().toPromise()) {

      if (this.viewModelValidate(true)) {
        const storeRequest = new StoreRequest<DashBoardLayout>();
        storeRequest.model = this.domainModel;
        const response = await this.apiClient.storeLayout(storeRequest).toPromise();
        operationSuccedeed = response.operationSuccedeed;
        if (response.operationSuccedeed) {
          if (this.rootViewModel?.code?.value !== response.result.code) {
            // Forzo l'aggiornamento della cache se il codice del layout iniziale è diverso da quello restituito dallo store
            await firstValueFrom(this.apiClient.getLayouts({ bypass: false, expirationTime: 1 }))
          }
        }
        await this.tryRebuildViewModelAsync(response, response.result, false, true);
        if (response.operationSuccedeed) {
          this.currentState.store();
        }
      }
    } else {
      this.notAllowedAction(CommandTypes.Store);
    }
    return operationSuccedeed;
  }

  async refreshWidgets() {
    await this.getByIdentity(this.dashboardLayout.currentIdentity);
    this.refreshAllWidgets.next()
  }

  getCustomEnterpriseDataError(): Promise<BaseError | null> {
    // Disattivo il controllo degli errori sull'enterprise
    return null;
  }

  async checkAllWidgets(): Promise<boolean> {

    // await this.getByIdentity(this.dashboardLayout.currentIdentity);
    // this.refreshAllWidgets.next()

    let result = false;
    const request = new GetByIdentityRequest<DashBoardLayoutIdentity>();
    request.identity = this.dashboardLayout.currentIdentity;
    const response = await this.apiClient.getLayoutByIdentity(request)
      .toPromise()
      .then(async (response) => {
        if (response.operationSuccedeed && response.result != null) {
          // await this.tryRebuildViewModelAsync(response, response.result, false, true);
          // this.currentState.getByIdentity();
          // this.viewModelValidate(false, true);
        } else {
          this.refreshMessagesFromResponse(response, MsgClearMode.ClearOnlyTemporaryMessage);
        }
        return response;
      }).catch(err => {
        const response = new GetByIdentityResponse<DashBoardLayout, DashBoardLayoutIdentity>(this.apiClient.rootModelType);
        response.operationSuccedeed = false;
        return response;
      });
    if (response.operationSuccedeed) {
      //rimuovo i locali che non esisteno piu
      for (let index = this.rootViewModel.content.length - 1; index >= 0; index--) {
        const findItem = response.result.content.collectionItems.find(i => i.itemId == this.rootViewModel.content[index].itemId.value);
        if (!findItem) {
          this.rootViewModel.content.remove(index);
        }
      }

      //aggiorno/inserisco
      for (const item of response.result.content.collectionItems) {
        const findItem = this.rootViewModel.content.find(i => i.itemId.value == item.itemId)
        if (findItem) {
          if (
            findItem.x.value != item.x ||
            findItem.y.value != item.y ||
            findItem.cols.value != item.cols ||
            findItem.rows.value != item.rows
          ) {
            findItem.x.value = item.x;
            findItem.y.value = item.y;
            findItem.cols.value = item.cols;
            findItem.rows.value = item.rows;
          }
        } else {
          await this.rootViewModel.content.addFromEntity(item);
        }
      }
      this.rootViewModel.occ.setValue(response.result.occ);
      this.currentState.getByIdentity();

      result = true;
    }
    return result;
  }


  public override isVisibleToggleRightSideBar(): Observable<boolean> {
    return of(false);
  }

  public override isVisibleCreate(): Observable<boolean> {
    return of(false);
  }

  public override isVisibleFind(): Observable<boolean> {
    return of(false);
  }

  public override isVisibleStore(): Observable<boolean> {
    return of(false);
  }

  public override isVisibleRestore(): Observable<boolean> {
    return of(false);
  }

  public override isVisibleRemove(): Observable<boolean> {
    return of(false);
  }

  public override isVisiblePinIdentityToDashboard(): Observable<boolean> {
    return of(false);
  }
}
