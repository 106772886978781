import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { StdModule, AuthService, WebApiServiceAgent, EndPointResolver, ResponseCacheService, NtsAppModule, ComponentLocator, ModalService, ToastMessageService } from '@nts/std';
import { NgModule } from '@angular/core';
import { DashBoardLayoutComponent } from './components/dash-board-layout/dash-board-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { DashBoardLayoutApiClient } from './api-clients/dash-board-layout.api-client';
import { DashBoardLayoutOrchestratorViewModel } from './view-models/dash-board-layout.orchestrator-view-model';
import { DashBoardLayoutViewModel } from './view-models/dash-board-layout.view-model';
import { ProvidersForApiClient } from '@nts/std';
import { WidgetApiClient } from './api-clients/widget.api-client';
import { DashBoardWingViewModel } from './view-models/wing/dash-board-wing.view-model';
import { FullScreenChartModalViewModel } from './view-models/modals/full-screen-chart-modal-view-model';
import { FullScreenModalContainerComponent } from './components/full-screen-modal-container/full-screen-modal-container.component';
import { OnlineService, UpdateService } from '@nts/std/src/lib/utility';
import { PinToDashboardMenuItemShortcutModalViewModel } from '../dash-board-container/view-models/modals/pin-to-dashboard-menu-item-shortcut-modal-view-model';
import { PinToDashboardMenuItemShortcutModalContainerComponent } from '../dash-board-container/components/product-menu-list/modals/modals/pin-to-dashboard-menu-item-shortcut-modal-container.component';
import { RoutingHelper } from '../helper/routing-helper';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DashBoardWingComponent } from './components/wing/dash-board-wing.component';

const routes: Routes = [
    {
        path: '',
        component: DashBoardLayoutComponent
    }
];

@NgModule({
    imports: [
        StdModule,
        FormsModule,
        // JoyrideModule.forChild(),
        RouterModule.forChild(
            routes
        )
    ],
    providers: [
        UpdateService,
        OnlineService,
        {
            provide: EndPointResolver,
            deps: [EnvironmentConfiguration]
        },
        {
            provide: ResponseCacheService,
            deps: [OnlineService]
        },
        {
            provide: DashBoardLayoutApiClient,
            deps: [WebApiServiceAgent, OnlineService]
        }
    ]
})
export class DashBoardModule extends NtsAppModule {

    constructor(
        componentLocator: ComponentLocator,
        httpClient: HttpClient,
        environmentConfiguration: EnvironmentConfiguration,
        authService: AuthService,
        modalService: ModalService,
        toastMessageService: ToastMessageService,
        private onlineService: OnlineService,
        private responseCacheService: ResponseCacheService,
        private webApiServiceAgent: WebApiServiceAgent,
        routingHelper: RoutingHelper
    ) {


        super(
            componentLocator,
            httpClient,
            environmentConfiguration,
            authService,
            modalService,
            toastMessageService,
            onlineService,
            routingHelper
        );
    }

    registerAll(
        httpClient: HttpClient,
        environmentConfiguration: EnvironmentConfiguration,
        authService: AuthService,
        modalService: ModalService,
        toastMessageService: ToastMessageService,
        onlineService: OnlineService,
        routingHelper: RoutingHelper
    ) {

        const dashBoardLayoutOrchestratorViewModelProviders = [];
        dashBoardLayoutOrchestratorViewModelProviders.push(...[
            ...ProvidersForApiClient.staticProvidersForApiClient(
                DashBoardLayoutApiClient,
                httpClient,
                environmentConfiguration,
                authService,
                this.onlineService,
                this.responseCacheService,
                this.webApiServiceAgent
            ),
            ...ProvidersForApiClient.staticProvidersForApiClient(
                WidgetApiClient,
                httpClient,
                environmentConfiguration,
                authService,
                this.onlineService,
                this.responseCacheService,
                this.webApiServiceAgent
            ),
            {
                provide: ModalService,
                useValue: modalService,
            },
            {
                provide: EnvironmentConfiguration,
                useValue: environmentConfiguration,
            },
            {
                provide: AuthService,
                useValue: authService,
            },
            {
                provide: ToastMessageService,
                useValue: toastMessageService,
            },
            {
                provide: ToastMessageService,
                useValue: toastMessageService,
            },
            {
                provide: RoutingHelper,
                useValue: routingHelper,
            },
            OnlineService,
            ResponseCacheService,
            WebApiServiceAgent
        ]);

        dashBoardLayoutOrchestratorViewModelProviders.push({
            provide: DashBoardLayoutOrchestratorViewModel,
            deps: [
                DashBoardLayoutApiClient,
                WidgetApiClient,
                ModalService,
                EnvironmentConfiguration,
                AuthService,
                ToastMessageService,
                OnlineService,
                RoutingHelper
            ]
        });

        this.registerAllForDomainModelTypeWithProviders(
            'DashBoardLayout',
            DashBoardLayoutOrchestratorViewModel,
            DashBoardLayoutViewModel,
            DashBoardLayoutComponent,
            dashBoardLayoutOrchestratorViewModelProviders,
            DashBoardWingViewModel,
            DashBoardWingComponent
        );

        this.componentLocator.registerViewModelComponentAssociation(FullScreenChartModalViewModel, FullScreenModalContainerComponent);

        this.componentLocator.registerViewModelComponentAssociation(PinToDashboardMenuItemShortcutModalViewModel, PinToDashboardMenuItemShortcutModalContainerComponent);
    }
}
