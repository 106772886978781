// tslint:disable-next-line: max-line-length
import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, NumberDecorator, EnumDecorator, CoreModel, BoolDecorator, OCCModel, InternalDecorator, Type, UIInfo } from '@nts/std';
import { MenuNodeIdentity } from './menu-node.identity';
import { MenuNode } from './menu-node';

@IdentityTypeDecorator(MenuNodeIdentity)
@ModelTypeNameDecorator('MenuNodeUi')
export class MenuNodeUi extends MenuNode {

    // Property non presente nei meta dati per gestire l'uiInfo
    @Expose()
    @Type(() => UIInfo)
    public uiInfo: UIInfo;
}
