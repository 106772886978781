
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import '@angular/common/locales/global/it';
import * as moment_ from 'moment';

import 'vanilla-cookieconsent/src/cookieconsent.js'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StdModule, NtsAppRootModule, CurrentSessionService, WebApiServiceAgent } from '@nts/std';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';
import { DashBoardModule } from './dash-board/dash-board.module';
import * as Highcharts from 'highcharts';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DashBoardContainerModule } from './dash-board-container/dash-board-container.module';
import { environment as devEnvironment } from 'src/environments/environment.dev';
import { UNPROXIED_ENVIRONMENT } from '@nts/std/src/lib/environments';
import { unproxiedEnvironmentFactory } from '@nts/std/src/lib/environments';
import { DashBoardLayoutApiClient } from './dash-board/api-clients/dash-board-layout.api-client';
import { NtsBootService } from '@nts/std';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';

declare const require: any;
const jstz = require('jstimezonedetect');
const moment = moment_;

// TODO separare in due funzioni
export function initializerFactoryFunction(
  dashBoardLayoutApiClient: DashBoardLayoutApiClient
) {
  // Do initing of services that is required before app loads
  // NOTE: this factory needs to return a function (that then returns a promise)
  return async () => {
    // #region Highcharts
    Highcharts.setOptions({
      time: {
        timezone: jstz.determine().name(),
        timezoneOffset: -moment().utcOffset()
      },
      lang: {
        /**
         * (Highcharts, Highstock, Highmaps, Gantt) An array containing the months
         * names in abbreviated form. Corresponds to the `%b` format in
         * `Highcharts.dateFormat()`.
         */
        shortMonths: moment.localeData().monthsShort(),

        /**
         * (Highcharts, Highstock, Highmaps, Gantt) Short week days, starting
         * Sunday. If not specified, Highcharts uses the first three letters of the
         * `lang.weekdays` option.
         */
        shortWeekdays: moment.localeData().weekdaysShort(),

        /**
         * (Highcharts, Highstock, Highmaps, Gantt) An array containing the weekday
         * names.
         */
        weekdays: moment.localeData().weekdays(),

        /**
         * (Highcharts, Highstock, Highmaps, Gantt) An array containing the months
         * names. Corresponds to the `%B` format in `Highcharts.dateFormat()`.
         */
        months: moment.localeData().months()
      },
    });
    // #endregion Highcharts
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    StdModule,
    DashBoardModule,
    DashBoardContainerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: EnvironmentConfiguration,
      useValue: { ...environment }
    },
    ...StdModule.getRootProviders(),
    ...DashBoardContainerModule.getRootProviders(),
    {
      provide: UNPROXIED_ENVIRONMENT,
      useFactory: unproxiedEnvironmentFactory(devEnvironment),
      deps: [EnvironmentConfiguration]
    },
    {
      provide: DashBoardLayoutApiClient,
      deps: [WebApiServiceAgent, CurrentSessionService]
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializerFactoryFunction,
    //   deps: [DashBoardLayoutApiClient],
    //   multi: true
    // },
    { provide: LOCALE_ID, useValue: 'it' },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends NtsAppRootModule {
  constructor(
    bootService: NtsBootService
  ) {
    super(bootService);
  }
}
