import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, CoreModel, StringDecorator, EnumDecorator } from '@nts/std';
import { MenuNodeActionIdentity } from './menu-node-action.identity';
import { Actions } from './enum/actions';


@IdentityTypeDecorator(MenuNodeActionIdentity)
@ModelTypeNameDecorator('MenuNodeAction')
export class MenuNodeAction extends CoreModel<MenuNodeActionIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    public get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    public set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    // tslint:disable-next-line: variable-name
    private _actionId: number;

    @Expose()
    public get actionId(): number {
        return this.getValueForIdentityValue<number>(() => this._actionId, 'actionId');
    }
    public set actionId(value: number) {
        this.setValueForIdentityValue<number>(() => { this._actionId = value; }, value, 'actionId');
    }

    // tslint:disable-next-line: variable-name
    protected _action: Actions;

    @Expose()
    @EnumDecorator({ displayNameKey: 'MenuNodeAction_Action' })
    public get action(): Actions {
        return this.getValue<Actions>(() => this._action, 'action');
    }
    public set action(value: Actions) {
        this.setValue<Actions>(() => { this._action = value; }, value, 'action');
    }

}

