import { Expose, DateTimeOffset, Transform } from '@nts/std';

export class DonutItem {

    @Expose()
    public name: string;

    @Expose()
    public value: number;
}



