import { Expose } from '@nts/std';

export class TimeLineItem {

    @Expose()
    public name: string;

    @Expose()
    public value: number;
}



