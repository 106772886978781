<gridster id="widgets-list" *ngIf="widgetList?.length > 0 || options.draggable.enabled; else noWidgets"
    [options]="options">
    <gridster-item [item]="widget" *ngFor="let widget of widgetList">
        <nts-widget-host [vm]="widget['item']" [orchestratorViewModel]="orchestratorViewModel"></nts-widget-host>
    </gridster-item>
</gridster>
<ng-template #noWidgets>
    <div class="no-widgets-container">
        <div class="no-widgets-content">
            <img class="no-widgets-image" src="assets/images/nts/widget/empty-dashboard.svg" alt="">
            <div class="no-widgets-primary">
                {{'DashboardNoWidgetsTitle' | NTSTranslate}}
            </div>
            <div class="no-widgets-secondary">
                <span>{{'DashboardNoWidgetsDescription' | NTSTranslate}}</span>
            </div>
            <nts-filled-button joyrideStep="addFirstWidget" [type]="3" [title]="'CMD_AddWidget' | NTSTranslate"
                [label]="'CMD_AddWidget' | NTSTranslate" (next)="onNextAddFirstWidgetTourStep()"
                text="{{'CMD_AddWidget_Text' | NTSTranslate}}" (onClicked)="orchestratorViewModel.addWidgetDashBoard()">
            </nts-filled-button>
        </div>
    </div>
</ng-template>
