<div class="main-container" *ngIf="rootViewModel">
    <nts-widget-container *ngIf="orchestratorViewModel.onlineService.isOnline$ | async ; else offline"
        [collectionViewModel]="rootViewModel.content" [orchestratorViewModel]="orchestratorViewModel">
    </nts-widget-container>
</div>

<ng-template #offline>
    <div class="offline-container">
        <div class="offline-content">
            <img class="offline-image" src="assets/images/nts/off-line.png" alt="">
            <div class="offline-primary">
                {{'DashboardOfflineTitle' | NTSTranslate}}
            </div>
            <div class="offline-secondary">
                <span>{{'DashboardOfflineDescription' | NTSTranslate}}</span>
            </div>
        </div>
    </div>
</ng-template>
