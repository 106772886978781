<div class="reporting-visuals-common-scalar">
    <div class="top-bar">
        <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode"> {{ title }} </h2>
        <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3>
    </div>
    <div class="bottom-bar">
        <div class="switch-content">
            <div class="checkbox-switch">
                <input type="checkbox" [checked]="currentState" [disabled]="true" name="status" class="input-checkbox">
                <div class="checkbox-animate">
                    <span class="checkbox-off">{{offLabel}}</span>
                    <span class="checkbox-on">{{onLabel}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
