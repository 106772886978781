import { WidgetFullScreenComponent } from '../widget-list/widget-full-screen-component';
import { Component, Input, OnInit, AfterViewInit, QueryList, ViewChildren, ComponentFactoryResolver, SimpleChanges, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CollectionViewModelInterface, CollectionChangedAction, FilledButtonComponent, NTSTranslatePipe } from '@nts/std';
import { ViewModelInterface } from '@nts/std';
import { GridsterConfig, GridsterItem, GridType, DisplayGrid, GridsterItemComponentInterface } from 'angular-gridster2';
import { DashBoardItemViewModel } from '../../view-models/dash-board-item.view-model';
import { WidgetHostComponent } from '../widget-host/widget-host.component';
import { WidgetComponentInterface } from '../widget-list/widget-component.interface';
import { DashBoardLayoutOrchestratorViewModel } from '../../view-models/dash-board-layout.orchestrator-view-model';
import { Subscription } from 'rxjs';
import { CollectionChangedEventArgs } from '@nts/std';
import { Widget } from '../../domain-models/widget';
import { WidgetFullScreenComponentInterface } from '../widget-list/widget-full-screen-component.interface';
import { JoyrideModule, JoyrideService } from 'ngx-joyride';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GridsterModule } from 'angular-gridster2';
import { NgFor, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-widget-container',
    templateUrl: './widget-container.component.html',
    styleUrls: ['./widget-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        GridsterModule,
        FilledButtonComponent,
        WidgetHostComponent,
        NTSTranslatePipe,
        NgIf,
        JoyrideModule,
        NgFor
    ]
})
export class WidgetContainerComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChildren(WidgetHostComponent) viewChildren!: QueryList<WidgetHostComponent>;
    @Input() collectionViewModel!: CollectionViewModelInterface<ViewModelInterface>;
    @Input() orchestratorViewModel!: DashBoardLayoutOrchestratorViewModel;

    private collectionViewModelCollectionChangedSubscription: Subscription = null;

    public widgetList: Array<GridsterItem> = [];
    public options: GridsterConfig = {
        gridType: GridType.Fixed,
        fixedColWidth: 100,
        fixedRowHeight: 80,
        displayGrid: DisplayGrid.None,
        pushItems: false,
        enableOccupiedCellDrop: false,
        enableEmptyCellDrop: false,
        defaultItemCols: 1,
        defaultItemRows: 1,
        emptyCellDropCallback: this.emptyCellClick.bind(this),
        draggable: {
            enabled: false,
            ignoreContent: true,
            dragHandleClass: 'dashboard-edit-view',
            ignoreContentClass: 'widget-host-edit-row',
            start: this.itemDragStart.bind(this),
        },
        resizable: {
            enabled: false
        },

        itemChangeCallback: this.updateViewModelAfterItemChange.bind(this),
        itemResizeCallback: this.itemResize.bind(this),
    };

    emptyCellClick(event: MouseEvent, item: GridsterItem): void {
        this.orchestratorViewModel.addSelectedWidget(item.x, item.y);
    }

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
        private readonly joyrideService: JoyrideService
    ) { }


    updateViewModelAfterItemChange(item: GridsterItem): void {
        const vm = item['item'] as DashBoardItemViewModel;
        vm.cols.value = item.cols;
        vm.rows.value = item.rows;
        vm.x.value = item.x;
        vm.y.value = item.y;
    }

    itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
        // itemComponent.gridster.curRowHeight += (item.cols * 100 - item.rows) / 10000;
        /*if (itemComponent.gridster.curRowHeight > 1) {
            this.unitHeight = itemComponent.gridster.curRowHeight;
        }*/
        const vm = item['item'] as DashBoardItemViewModel;
        vm.cols.value = itemComponent.$item.cols;
        vm.rows.value = itemComponent.$item.rows;
        vm.widgetRef.resizeChanged.next(vm);
    }

    itemDragStart(item: GridsterItem, itemComponent: GridsterItemComponentInterface, event: MouseEvent): void {
        // tslint:disable-next-line:no-console
        const vm = item['item'] as DashBoardItemViewModel;
        vm.widgetRef.dragStarted.next();
    }

    unsubscribeCollectionViewModelSubscribtion() {
        if (this.collectionViewModelCollectionChangedSubscription != null) {
            this.collectionViewModelCollectionChangedSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes['collectionViewModel']) {

            this.unsubscribeCollectionViewModelSubscribtion();

            this.widgetList.splice(0, this.widgetList.length);
            this.collectionViewModel.forEach((vm: DashBoardItemViewModel) => {
                this.widgetList.push({
                    cols: vm.cols.value > 0 ? vm.cols.value : 1,
                    rows: vm.rows.value > 0 ? vm.rows.value : 1,
                    y: vm.y.value, x: vm.x.value,
                    item: vm,
                    minItemRows: vm.widgetRef.minRows.value,
                    maxItemRows: vm.widgetRef.maxRows.value,
                    minItemCols: vm.widgetRef.minCols.value,
                    maxItemCols: vm.widgetRef.maxCols.value
                });
            });

            this.collectionViewModelCollectionChangedSubscription = this.collectionViewModel.collectionChanged.pipe(untilDestroyed(this)).subscribe((arg: CollectionChangedEventArgs<DashBoardItemViewModel>) => {
                if (arg.action === CollectionChangedAction.add) {
                    this.widgetList.push({
                        cols: arg.items[0].cols.value,
                        rows: arg.items[0].rows.value,
                        y: arg.items[0].y.value, x: arg.items[0].x.value,
                        item: arg.items[0],
                        minItemRows: arg.items[0].widgetRef.minRows.value,
                        maxItemRows: arg.items[0].widgetRef.maxRows.value,
                        minItemCols: arg.items[0].widgetRef.minCols.value,
                        maxItemCols: arg.items[0].widgetRef.maxCols.value
                    });
                } else if (arg.action === CollectionChangedAction.remove) {
                    this.widgetList.splice(this.widgetList.findIndex((i) => {
                        const item = i['item'] as DashBoardItemViewModel;
                        return item.getDomainModel().currentIdentity === arg.items[0].getDomainModel().currentIdentity;
                    }), 1);
                } else if (arg.action === CollectionChangedAction.edit) {
                    const itemIndex = this.widgetList.findIndex((i) => {
                        const item = i['item'] as DashBoardItemViewModel;
                        return item.getDomainModel().currentIdentity === arg.items[0].getDomainModel().currentIdentity;
                    });
                    const item = this.widgetList[itemIndex];
                    if (
                        item.cols != arg.items[0].cols.value ||
                        item.rows != arg.items[0].rows.value ||
                        item.y != arg.items[0].y.value ||
                        item.x != arg.items[0].x.value
                    ) {
                        this.widgetList.splice(itemIndex, 1);

                        this.widgetList.splice(itemIndex, 0, {
                            cols: arg.items[0].cols.value,
                            rows: arg.items[0].rows.value,
                            y: arg.items[0].y.value, x: arg.items[0].x.value,
                            item: arg.items[0],
                            minItemRows: arg.items[0].widgetRef.minRows.value,
                            maxItemRows: arg.items[0].widgetRef.maxRows.value,
                            minItemCols: arg.items[0].widgetRef.minCols.value,
                            maxItemCols: arg.items[0].widgetRef.maxCols.value
                        });
                    }
                }
                this.cd.detectChanges();
            });
        }
    }


    ngOnInit(): void {
        this.orchestratorViewModel.editingDashBoardStatusChanged.pipe(untilDestroyed(this)).subscribe((newValue: boolean) => {

            if (this.options.draggable.enabled === newValue) {
                return;
            }
            this.options.draggable.enabled = newValue;

            this.options.draggable.enabled = newValue;
            this.options.resizable.enabled = newValue;
            this.options.enableOccupiedCellDrop = newValue;
            this.options.swap = newValue;
            this.options.enableEmptyCellDrop = newValue;
            if (this.options.api && this.options.api.optionsChanged) {
                this.options.api.optionsChanged();
            }
        });

        this.orchestratorViewModel.selectedWidgetDragStarted.pipe(untilDestroyed(this)).subscribe(() => {
            const selectedWidget = this.orchestratorViewModel.widgets.selection[0].getDomainModel() as Widget;
            this.options.defaultItemCols = selectedWidget.defaultCols;
            this.options.defaultItemRows = selectedWidget.defaultRows;
            if (this.options.api && this.options.api.optionsChanged) {
                this.options.api.optionsChanged();
            }
            this.cd.detectChanges();
        });

        this.orchestratorViewModel.beginTourRequested.pipe(untilDestroyed(this)).subscribe((steps: string[]) => {
            this.joyrideService.startTour(
                {
                    waitingTime: 250,
                    steps,
                    showPrevButton: false,
                    customTexts: { prev: 'Indietro', next: 'Avanti', done: 'Fatto' }
                }
            );
        })
    }

    ngAfterViewInit() {

        this.viewChildren.forEach((widgetHostComponent: WidgetHostComponent) => {
            this.loadComponent(widgetHostComponent);
        });

        // gestisce il redraw solo dei componenti aggiunti e non di tutti
        this.viewChildren.changes.pipe(untilDestroyed(this)).subscribe((_) => {
            this.viewChildren.forEach((widgetHostComponent: WidgetHostComponent) => {
                if (!widgetHostComponent.componentLoaded) {
                    this.loadComponent(widgetHostComponent);
                }
            });
        });
    }

    private loadComponent(widgetHostComponent: WidgetHostComponent) {
        widgetHostComponent.componentLoaded = true;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            widgetHostComponent.componentsMapping[widgetHostComponent.vm.widgetRef.widgetType.value]);
        const viewContainerRef = widgetHostComponent.widgetPlaceHolder;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        (componentRef.instance as WidgetComponentInterface).vm = widgetHostComponent.vm.widgetRef;
        (componentRef.instance as WidgetComponentInterface).orchestratorViewModel = this.orchestratorViewModel;
        widgetHostComponent.widgetInstance = (componentRef.instance as WidgetComponentInterface);


        if (widgetHostComponent.widgetInstance instanceof WidgetFullScreenComponent) {
            let widgetFullScreenComponentFactory = componentFactory;
            if (widgetHostComponent.vm.widgetRef?.linkedWidgetRef?.domainModel) {
                widgetFullScreenComponentFactory = this.componentFactoryResolver.resolveComponentFactory(
                    widgetHostComponent.componentsMapping[widgetHostComponent.vm.widgetRef.linkedWidgetRef.widgetType.value]
                );
                widgetHostComponent.widgetInstance.sameWidgetInFullScreen = false;
            }
            (widgetHostComponent.widgetInstance as WidgetFullScreenComponentInterface)
                .widgetFullScreenComponentFactory = widgetFullScreenComponentFactory;
        }

        widgetHostComponent.initialize();

    }

    onNextAddFirstWidgetTourStep() {
        this.orchestratorViewModel.addWidgetDashBoard();
    }
}
