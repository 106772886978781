import { MessageFormatter, PresentationCache } from "@nts/std";
import { LocalstorageHelper, LogService, OnlineService, TIMEOUT_CACHE } from "@nts/std/src/lib/utility";
import { CookieService } from "ngx-cookie-service";
import { catchError, firstValueFrom, identity, of, tap, timeout } from "rxjs";
import { DashBoardLayoutApiClient } from "../dash-board/api-clients/dash-board-layout.api-client";
import { CookiePreferencesDTO } from "../dash-board/domain-models/dto/cookie-preferences-dto";
import { HttpClient } from "@angular/common/http";

export const COOKIE_TRANLATIONS_STORAGE_KEY = 'cookieTranslations'

export class CookieLoader {

    private profileDomainModelFullName = 'LoginService.UserObjects.Models.User';
    private cookiePrivacyPreferences: CookiePreferencesDTO;
    private translations = {};
    // private loadedConfiguration = false;

    constructor(
        private apiClient: DashBoardLayoutApiClient,
        private cookieService: CookieService,
        private http: HttpClient,
        private onlineService: OnlineService
    ) {

    }

    async init() {

        // const updateCookiePreferences = new UpdateCookiePreferences();
        // updateCookiePreferences.essentialCookies = false;
        // await firstValueFrom(this.apiClient.updateCookiePreferences(updateCookiePreferences));

        const isOnline = this.onlineService.isOnline;
        if (!isOnline || navigator?.webdriver) {
            return;
        }

        await this.loadCurrentPreferences();
        await this.checkCurrentStoredCookie();
        const popupParams = await this.getPopupParams();
        await this.loadPopup(popupParams);
    }

    private async loadPopup(popupParams: any) {
        const cookieConsent = (window as any).initCookieConsent();
        cookieConsent.run(popupParams);
        if (this.cookiePrivacyPreferences.essentialCookies == true) {
            cookieConsent.accept('necessary'); // accept all categories
        } else {
            cookieConsent.show();
        }

        // Il tempo necessario per far scattare l'evento onAccept se viene caricato la configurazione con il cookie già impostato
        // quindi è da skippare fino a quando il flag loadedConfiguration è true


        // console.log(cookieConsent.getUserPreferences());
        // setTimeout(() => {
        // cookieConsent.showSettings(2000);
        // }, 10000)
    }

    private async loadCurrentPreferences() {
        let cookiePrivacyPreferences = new CookiePreferencesDTO();
        try {
            const response = await firstValueFrom(this.apiClient.getCookiePreferences());
            if (response.operationSuccedeed) {
                cookiePrivacyPreferences = response.result;
            }
        } catch (err) {
            LogService.warn(err);
        }
        this.cookiePrivacyPreferences = cookiePrivacyPreferences;
    }

    private async checkCurrentStoredCookie() {
        // Se essential cookie non è accettato
        // Devo cancellare il cookie e avviare la configurazione
        if (this.cookiePrivacyPreferences.essentialCookies === false) {
            this.cookieService.delete('cc_cookie', '/', window.location.hostname, window.location.protocol === "https:", 'Lax');
            // document.cookie = `cc_cookie=; Max-Age=-99999999; domain=.${window.location.hostname};`;
        }
    }

    getMessageWithStrings(key: string, ...args: string[]) {
        let message = this.getMessage(key);
        if (args != null) {
            message = MessageFormatter.formatString(message, ...args);
        }
        return message;
    }

    getMessage(key: string): string {

        if (!key) {
            return '';
        }

        let message = null;
        message = this.translations[key];
        if (message == null) {
            return this.errorMessage(key);
        }

        return message;
    }

    private errorMessage(key: string): string {
        return MessageFormatter.formatNumber('Localization code \'{0}\' not found', key);
    }

    private getCookieStorageKey() {
        const browserLanguage = navigator.language.slice(0, 2).toLowerCase();
        const current_lang = (browserLanguage != 'it' && browserLanguage != 'en') ? 'it' : browserLanguage;
        return COOKIE_TRANLATIONS_STORAGE_KEY + '_' + current_lang.toUpperCase();
    }

    private async getPopupParams(): Promise<any> {

        let profileUrl = '';
        let loginBaseUrl = '';

        const currentTimestamp = new Date().getTime().toString();

        try {
            await PresentationCache.addIfNotExist(this.profileDomainModelFullName);

            // recupero l'url del profilo
            profileUrl = PresentationCache.get(this.profileDomainModelFullName)?.replace(new RegExp('user', 'g'), 'profile');

            // recupero il base url della login
            loginBaseUrl = profileUrl?.split('/manage')[0];

        } catch (err) {
            LogService.warn(err);
        }

        const cookiePolicyUrl = loginBaseUrl + '/assets/static/informazioni-sui-cookies.html?ver=' + currentTimestamp;
        const privacyPolicyUrl = loginBaseUrl + '/assets/static/informativa-privacy.html?ver=' + currentTimestamp;

        const browserLanguage = navigator.language.slice(0, 2).toLowerCase();
        const current_lang = (browserLanguage != 'it' && browserLanguage != 'en') ? 'it' : browserLanguage;

        const storedTranslations = await LocalstorageHelper.getStorageItem(this.getCookieStorageKey(), undefined, false, false, false);

        if(this.onlineService.isOnline === false) {
            if (storedTranslations) {
                // Caso offline e cache presente, ritorna subito l'oggetto storedTranslations
                this.translations = storedTranslations;
            } else {
                // Caso offline e cache non presente, ritorna oggetto vuoto
                this.translations = {};
            }
        } else {
            this.translations = await firstValueFrom(
                this.http.get(
                    'assets/i18n/' + current_lang + '/cookie.json?ver=' + currentTimestamp
                ).pipe(
                    catchError(e => {
                        LogService.warn(`missing cookie ${current_lang} translations!`, e);
                        return of({});
                    }),
                    storedTranslations ?
                            // Caso online e cache presente, ritorna l'oggetto storedTranslations solo dopo un timeout di TIMEOUT_CACHE
                            timeout({
                                first: TIMEOUT_CACHE,
                                with: () => {
                                    return of(storedTranslations);
                                }
                            }) :
                            // Caso online e cache non presente, ritorna identity
                            identity,
                    tap((translations) => {
                        LocalstorageHelper.setStorageItem(
                            this.getCookieStorageKey(), translations, undefined, false, false, false
                        );
                    })
                )
            )
        }

        return {

            force_consent: true,
            autorun: false,
            current_lang,
            autoclear_cookies: true,

            onFirstAction: (user_preferences, cookie) => {
                // callback triggered only once
                // console.log('onFirstAction')
            },

            // Attenzione, se presente il cookie ed è stato precedentemente accettato,
            // se si lancia il cookieConsent.run vaerrà immediatamente invocato questo metodo
            // per questo motivo è necessario aspettare con un flag (loadedConfiguration) che il cookieconsent sia stato già caricato
            onAccept: async (cookie) => {

                // se era già stato accettato non fa niente
                if (this.cookiePrivacyPreferences.essentialCookies == true) {
                    return;
                }

                // ... cookieconsent accepted
                // console.log('onAccept', cookie)

                // Per il momento supportiamo solo l'essential cookie
                const updateCookiePreferences = new CookiePreferencesDTO();
                updateCookiePreferences.essentialCookies = true;
                await firstValueFrom(this.apiClient.updateCookiePreferences(updateCookiePreferences))
            },

            onChange: (cookie, changed_preferences) => {
                // ... cookieconsent preferences were changed
            },

            languages: {
                [current_lang]: {
                    consent_modal: {
                        title: this.getMessage('consent_modal.title'),
                        description: this.getMessageWithStrings('consent_modal.description', cookiePolicyUrl),
                        primary_btn: {
                            text: this.getMessage('consent_modal.primary_btn.text'),
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: this.getMessage('consent_modal.secondary_btn.text'),
                            role: 'settings'                // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: this.getMessage('settings_modal.title'),
                        save_settings_btn: this.getMessage('settings_modal.save_settings_btn'),
                        accept_all_btn: this.getMessage('settings_modal.accept_all_btn'),
                        reject_all_btn: null,       // optional, [v.2.5.0 +]
                        cookie_table_headers: [
                            { col1: this.getMessage('settings_modal.cookie_table_headers.col1') },
                            { col2: this.getMessage('settings_modal.cookie_table_headers.col2') },
                            { col3: this.getMessage('settings_modal.cookie_table_headers.col3') },
                            { col4: this.getMessage('settings_modal.cookie_table_headers.col4') },
                            { col5: this.getMessage('settings_modal.cookie_table_headers.col5') }
                        ],
                        blocks: [

                            {
                                title: this.getMessage('settings_modal.blocks[0].title'),
                                description: this.getMessageWithStrings('settings_modal.blocks[0].description'),
                            },

                            {
                                title: this.getMessage('settings_modal.blocks[2].title'),
                                description: this.getMessage('settings_modal.blocks[2].description'),
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true
                                },
                                cookie_table: [
                                    {
                                        col1: this.getMessage('settings_modal.blocks[2].cookie_table[0].col1'),
                                        col2: window.location.hostname,
                                        col3: this.getMessage('settings_modal.blocks[2].cookie_table[0].col3'),
                                        col4: this.getMessage('settings_modal.blocks[2].cookie_table[0].col4'),
                                        col5: this.getMessage('settings_modal.blocks[2].cookie_table[0].col5'),
                                        is_regex: true
                                    }
                                ]
                            },

                            {
                                title: this.getMessage('settings_modal.blocks[3].title'),
                                description: this.getMessage('settings_modal.blocks[3].description'),
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true
                                },
                                cookie_table: [
                                    {
                                        col1: this.getMessage('settings_modal.blocks[3].cookie_table[0].col1'),
                                        col2: window.location.hostname,
                                        col3: this.getMessage('settings_modal.blocks[3].cookie_table[0].col3'),
                                        col4: this.getMessage('settings_modal.blocks[3].cookie_table[0].col4'),
                                        col5: this.getMessage('settings_modal.blocks[3].cookie_table[0].col5'),
                                        is_regex: true
                                    },
                                    {
                                        col1: this.getMessage('settings_modal.blocks[3].cookie_table[1].col1'),
                                        col2: window.location.hostname,
                                        col3: this.getMessage('settings_modal.blocks[3].cookie_table[1].col3'),
                                        col4: this.getMessage('settings_modal.blocks[3].cookie_table[1].col4'),
                                        col5: this.getMessage('settings_modal.blocks[3].cookie_table[1].col5'),
                                        is_regex: true
                                    }
                                ]
                            },

                            {
                                title: this.getMessage('settings_modal.blocks[1].title'),
                                description: this.getMessageWithStrings('settings_modal.blocks[1].description', privacyPolicyUrl),
                            },

                            // {
                            //     title: this.getMessage('settings_modal.blocks[3].title'),
                            //     description: this.getMessageWithStrings('settings_modal.blocks[3].description', profileUrl, privacyPolicyUrl)
                            // }
                        ]
                    }
                }
            }
        };
    }
}
