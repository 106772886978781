import { Widget } from '../widget';
import { Expose, Type } from '@nts/std';

export class WidgetListDto {

    @Expose()
    @Type(() => Widget)
    public widgets: Widget[];

    constructor() {
        this.widgets = [];
    }
}
