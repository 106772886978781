import { UICommandSettingsManager, UICommandInterface, MessageResourceManager } from '@nts/std';

export class DashBoardToolBarCommandNames {
    public static readonly Edit = 'Edit';
    public static readonly Add = 'AddWidget';
    public static readonly Done = 'Done';
    public static readonly Refresh = 'Refresh';
}

export class DashBoardLayoutUiCommandSettingsManager extends UICommandSettingsManager {

    public override setUICommand(en: string, ui: UICommandInterface): UICommandInterface {

        ui.displayName = MessageResourceManager.Current.getMessage('CMD_' + en);
        ui.name = en;
        ui.tooltip = ui.displayName;

        switch (en) {
            case DashBoardToolBarCommandNames.Done:
                ui.iconClass = 'check';
                break;
            case DashBoardToolBarCommandNames.Add:
                ui.iconClass = 'plus';
                break;
            case DashBoardToolBarCommandNames.Edit:
                ui.iconClass = 'edit-pencil';
                break;
            case DashBoardToolBarCommandNames.Refresh:
                ui.iconClass = 'refresh';
                break;
        }

        return ui;
    }
}
