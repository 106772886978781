import { Expose } from '@nts/std';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetResultSetValue extends WidgetBaseValueDto {

    constructor() {
        super();
        this.labels = [];
        this.valueList = [[]];
    }

    @Expose()
    public labels: string[];

    @Expose()
    public valueList: string[][];

}



