// @ts-nocheck
/* eslint-disable */
import { appAnalyticsIcon } from './analytics';
import { appAppWindowIcon } from './app-window';
import { appAssistenzaIcon } from './assistenza';
import { appBankIcon } from './bank';
import { appCloudCheckIcon } from './cloud-check';
import { appCloudPortalIcon } from './cloud-portal';
import { appColorPickerIcon } from './color-picker';
import { appConfigAccessiIcon } from './config-accessi';
import { appConfigurazioniIcon } from './configurazioni';
import { appDashboardIcon } from './dashboard';
import { appElevatorIcon } from './elevator';
import { appLayoutLeftIcon } from './layout-left';
import { appLicenseIcon } from './license';
import { appMoreVertIcon } from './more-vert';
import { appNeighbourhoodIcon } from './neighbourhood';
import { appOpenInWindowIcon } from './open-in-window';
import { appPageEditIcon } from './page-edit';
import { appPageFlipIcon } from './page-flip';
import { appPinIcon } from './pin';
import { appReportisticaIcon } from './reportistica';
import { appSourceIconsNavArrowDownIcon } from './source_icons_nav-arrow-down';
import { appSourceIconsNavArrowRightIcon } from './source_icons_nav-arrow-right';
import { appSourceIconsReportColumnsIcon } from './source_icons_report-columns';
import { appSubstractIcon } from './substract';
import { appToolsIcon } from './tools';
import { appUserBadgeCheckIcon } from './user-badge-check';
import { appUtilityIcon } from './utility';
export const dashBoardIcons = [appAnalyticsIcon, appAppWindowIcon, appAssistenzaIcon, appBankIcon, appCloudCheckIcon, appCloudPortalIcon, appColorPickerIcon, appConfigAccessiIcon, appConfigurazioniIcon, appDashboardIcon, appElevatorIcon, appLayoutLeftIcon, appLicenseIcon, appMoreVertIcon, appNeighbourhoodIcon, appOpenInWindowIcon, appPageEditIcon, appPageFlipIcon, appPinIcon, appReportisticaIcon, appSourceIconsNavArrowDownIcon, appSourceIconsNavArrowRightIcon, appSourceIconsReportColumnsIcon, appSubstractIcon, appToolsIcon, appUserBadgeCheckIcon, appUtilityIcon];
