import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, Scroll } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IframeComponent, LoaderComponent, RouteChangeParams, RoutingService } from '@nts/std';
import { AuthService, PresentationCache } from '@nts/std';
import { LocalstorageHelper, LogService, TabSocket } from '@nts/std/src/lib/utility';
import { ModalService } from '@nts/std';
import { UUIDHelper } from '@nts/std/src/lib/utility';
import { filter, firstValueFrom, map, Subject } from 'rxjs';
import { RouteAdditionalData } from '../../domain-models/route-additional-data';
import { RoutingHelper } from '../../../helper/routing-helper';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { NgIf } from '@angular/common';


@UntilDestroy()
@Component({
    selector: 'nts-remote-model-container',
    templateUrl: './remote-model-container.component.html',
    styleUrls: ['./remote-model-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LoaderComponent,
        IframeComponent,
        NgIf
    ]
})
export class RemoteModelContainerComponent implements OnInit, OnDestroy {

    @ViewChild('iframe', { static: false }) iframe: ElementRef;

    url: string;
    currentEncodedAdditionalData: string = '';
    currentEncondedRootModelFullName: string = '';

    private navigatorSocket: TabSocket;
    private tabMessagesSocket: TabSocket;
    private uuid = UUIDHelper.generateUUID();
    private firstFrameLoaded = false;
    private onActionInProgress = new Subject<boolean>();

    actionInProgress = false;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private modalService: ModalService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private routingService: RoutingService,
        private routingHelper: RoutingHelper,
        private ngZone: NgZone,
        private environmentConfiguration: EnvironmentConfiguration
    ) {


        this.router.events
            .pipe(
                untilDestroyed(this),
                filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
                map((event) => event instanceof Scroll ? event.routerEvent as NavigationEnd : event as NavigationEnd),
            ).subscribe(async () => {
                const oldUrl = this.url;
                this.url = '';
                this.cd.detectChanges();
                this.url = oldUrl;
                this.cd.detectChanges();
                const res = await firstValueFrom(this.route.params);
                this.check(res);
            });

        this.onActionInProgress.pipe(untilDestroyed(this)).subscribe((onActionInProgress) => {
            this.actionInProgress = onActionInProgress;
            this.cd.detectChanges();
        })
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.routingHelper.unregisterForCurrentRoutePendingChanges(this.uuid);
        if (this.navigatorSocket) {
            this.navigatorSocket.destroy();
        }
    }

    iframeLoading(iframeNativeElement: any) {

        const tabMessagesSocket = this.createTabMessageSocket(iframeNativeElement.contentWindow);

        this.routingHelper.registerForCurrentRoutePendingChanges(
            this.uuid,
            tabMessagesSocket,
            this.modalService,
            this.onActionInProgress
        );

        this.navigatorSocketListen(iframeNativeElement, (params: RouteChangeParams) => {
            this.routingService.routeChangeRequested.next(params);
        }, 'navigator');

        LocalstorageHelper.startListener(iframeNativeElement.contentWindow);
    }

    private async check(res) {
        if (this.currentEncodedAdditionalData !== res.encondedAdditionalData || this.currentEncondedRootModelFullName !== res.encondedRootModelFullName) {

            this.currentEncodedAdditionalData = res.encondedAdditionalData;
            this.currentEncondedRootModelFullName = res.encondedRootModelFullName;

            const encondedAdditionalData = res.encondedAdditionalData;
            const rootModelFullName = RoutingService.decodeUrl(res.encondedRootModelFullName);
            const accessToken: string = await this.authService.getAccessToken();
            const refreshToken: string = await this.authService.getRefreshToken();
            const enterpiseData = await this.authService.getEnterpriseData(await this.authService.getTenantId());

            await PresentationCache.addIfNotExist(rootModelFullName);
            // Remove duplicated slash
            const returnedUrl = PresentationCache.get(rootModelFullName)?.replace(/([^:]\/)\/+/g, "$1");
            if (returnedUrl == null || returnedUrl === '') {
                this.router.navigate(['not-found']);
                return;
            }

            // Recupero l'identity dall'additional data

            let additionalData = new RouteAdditionalData();;
            try {
                additionalData = RoutingService.decodeObject<RouteAdditionalData>(encondedAdditionalData, RouteAdditionalData);
            } catch (e) {
                LogService.warn(`Impossibile decodificare ${encondedAdditionalData}`, e)
            }


            const searchParams = new URLSearchParams(additionalData.args);
            if (enterpiseData?.enterpriseId) {
                searchParams.set(AuthService.ENTERPRISE_ID_QUERY_KEY, enterpiseData?.enterpriseId?.toString());
            }
            if (enterpiseData?.companyId) {
                searchParams.set(AuthService.COMPANY_ID_QUERY_KEY, enterpiseData?.companyId?.toString());
            }
            searchParams.set('iframe', 'true');
            searchParams.set(AuthService.REFRESH_TOKEN_QUERY_KEY, refreshToken);
            searchParams.set(AuthService.ACCESS_TOKEN_QUERY_KEY, accessToken);

            if (additionalData.shortDescription?.length > 0) {
                window.document.title = `${additionalData.shortDescription} - ${this.environmentConfiguration.appTitle}`;
            }

            this.url = `${returnedUrl}${additionalData?.encodedIdentity?.length > 0 ? '/' + additionalData?.encodedIdentity : ''}?${searchParams.toString()}`;
            this.cd.detectChanges();
        }
    }

    private navigatorSocketListen(
        openedWindow: Window,
        listenAction: (message: RouteChangeParams) => void,
        socketName: string
    ) {

        if (this.navigatorSocket) {
            this.navigatorSocket.destroy();
        }
        this.navigatorSocket = new TabSocket(this.ngZone, openedWindow, socketName);
        this.navigatorSocket.onClose.pipe(untilDestroyed(this)).subscribe(() => {
            this.navigatorSocket.destroy();
        });
        this.navigatorSocket.listen<RouteChangeParams>((message) => {
            // clearTimeout(timeout);
            listenAction(message.data)
        });
    }

    private createTabMessageSocket(
        openedWindow: Window,
    ): TabSocket {

        if (this.tabMessagesSocket) {
            this.tabMessagesSocket.destroy();
        }

        this.tabMessagesSocket = new TabSocket(this.ngZone, openedWindow);
        // this.tabMessagesSocket.onClose.pipe(untilDestroyed(this)).subscribe(() => {
        //     this.tabMessagesSocket.destroy();
        // });

        return this.tabMessagesSocket
    }
}
