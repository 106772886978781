import { Expose, Type } from '@nts/std';
import { TimeLineParamsDto } from "./time-line-params.dto";
import { WidgetBaseValueDto } from './widget-base-value-dto';
import { TimeLineItem } from "./widget-time-line-item-dto";

export class WidgetSeriesValueDto extends WidgetBaseValueDto {

    constructor() {
        super();
        this.timeLineList = [];
        this.timeLineParamsList = [];
    }

    @Expose()
    @Type(() => TimeLineItem)
    public timeLineList: TimeLineItem[][];

    @Expose()
    public timeLineName: string;

    @Expose()
    public timeLineDescription: string;

    @Expose()
    public graphTypeName: string;

    @Expose()
    public graphTypeDescription: string;

    @Expose()
    @Type(() => TimeLineParamsDto)
    public timeLineParamsList: TimeLineParamsDto[];

}
