import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { GenericServiceResponse, ToastMessageService } from '@nts/std';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { Observable, map, of, tap } from 'rxjs';
import { TimeLineItem } from '../../../domain-models/dto/widget-time-line-item-dto';
import { WidgetTimeLineValue } from '../../../domain-models/dto/widget-time-line-value-dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DashBoardItemViewModel } from 'src/app/dash-board/view-models/dash-board-item.view-model';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HC_boost from 'highcharts/modules/boost';
import HC_noDataToDisplay from 'highcharts/modules/no-data-to-display';
import HC_more from 'highcharts/highcharts-more';
import { NgIf } from '@angular/common';

HC_boost(Highcharts);
HC_noDataToDisplay(Highcharts);
HC_more(Highcharts);

@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'nts-time-line-widget',
    templateUrl: './time-line-widget.component.html',
    styleUrls: ['./time-line-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HighchartsChartModule,
        NgIf
    ]
})

export class TimeLineWidgetComponent extends WidgetFullScreenComponent implements AfterViewInit, OnInit {

    // http://jsfiddle.net/6hCUx/1/ <-- Esempio interessante con pulsanti per cambiare lo zoom
    // https://codepen.io/pen/?&editable=true=https%3A%2F%2Fwww.highcharts.com%2Fsamples%2Fhighcharts%2Fdemo%2Fline-time-series%3Fcodepen

    @ViewChild('chartContainer') chartContainer: ElementRef;

    chart: Highcharts.Chart;
    chartOptions;
    Highcharts: typeof Highcharts = Highcharts;
    title: string;
    subTitle: string;
    /*
    -- LifeCycle --
    ngOnInit
        -> initChart ( va fatta sempre nella OnInit)!
    logChartInstance -> qui hoi il grafico
    initialize  -> qui posso caricare i dati
    ngAfterViewInit
    */

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    protected getLoadDataObservable<WidgetTimeLineValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetTimeLineValue>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getTimeLineWidgetValue(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetTimeLineValue): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;

        const series = [];

        const serieName = '';
        const xLabel = result.xLabel;
        const yLabel = result.yLabel;
        const timeLineItems = result.timeLine;
        const data = timeLineItems.map((timeLineItem: TimeLineItem) => [
            parseInt(timeLineItem.name, 10),
            timeLineItem.value
        ]);

        // potrei avere piu serie
        series.push({
            name: serieName,
            type: 'line',
            data
        });

        // aggiorno il grafico
        this.chart.update({
            xAxis: {
                title: {
                    text: xLabel
                }
            },
            yAxis: {
                title: {
                    text: yLabel
                }
            },
            series
                /*{
                    name: 'serie',
                    type: 'line',
                    data: values
                }/*, {
                    name: 'Manufacturing',
                    type: 'line',
                    data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
                }, {
                    name: 'Sales & Distribution',
                    type: 'line',
                    data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
                }, {
                    name: 'Project Development',
                    type: 'line',
                    data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
                }, {
                    name: 'Other',
                    type: 'line',
                    data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
                }*/,
        }, true, true, true);

        // la prima volta devo effettuar eil timeout per il disegno corretto del grafico dentro il content
        setTimeout(() => {
            this.resizeChanged(null);
        });

        this.cd.detectChanges();

        return of(true);
    }

    ngOnInit() {
        this.initChart();
    }

    initChart() {
        // parametri default
        this.chartOptions = {
            tooltip: {
                // https://devhints.io/datetime (strftime format)
                xDateFormat: '%e %B %Y, %H:%M'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            legend: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                // dateTimeLabelFormats: {
                //     day: '%e di %b'
                // }
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2010
                }
            },
            credits: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                    }
                }]
            }
        };
    }

    ngAfterViewInit() {
    }

    logChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    protected override resizeChanged(dashboardItemVm: DashBoardItemViewModel) {
        super.resizeChanged(dashboardItemVm);
        if (this.chart) {
            this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, this.chartContainer.nativeElement.offsetHeight, true);
        }
    }
}

