<div class="reporting-visuals-common-scalar">
    <div class="top-bar">
        <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode"> {{ title }} </h2>
        <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3>
    </div>
    <div class="bottom-bar">
        <button class="done-editing-button bolt-button enabled primary bolt-focus-treatment" data-focuszone=""
            data-is-focusable="true" role="button" tabindex="0" type="button" (click)="startProgram()">
            <span class="bolt-button-text body-m">{{'Widget_MenuItemShortcut_CMD_Start' | NTSTranslate}}</span>
        </button>
    </div>
</div>
