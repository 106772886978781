<div class="widget-container">
  <div class="absolute-fill bolt-spinner flex-column text-center" *ngIf="loading">
    <div class="bolt-spinner-circle medium">

    </div>
  </div>
  <div class="visual-message-card-container" *ngIf="error && !loading">
    <div class="visual-message-base">
      <div class="message-body">
        <div class="message-content">{{'Widget_Failed_To_Load'|NTSTranslate}}</div>
        <div class="message-icon">
          <div class="bolt-image-wrapper" style="width: 40px; height: 40px;">
            <img
              alt="A doctor with a stethoscope checking on a red disc with a white cross inside it, indicating an error with the widget"
              class="bolt-image flex-noshrink contain" src="assets/images/nts/widget/widget-error.png">
          </div>
        </div>
        <div class="message-action">
          <span (click)="errorDetails()"
            aria-label="Widget failed to load. Click to open a dialog box that has error details."
            class="link bolt-link" role="button" tabindex="0">{{'More_Details'|NTSTranslate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="content-container" tabindex="0" [hidden]="error || loading">
    <div #widgetPlaceHolder></div>
  </div>
</div>
<div class="widget-host-overlay" [class.widget-host-edit-overlay]="editing" *ngIf="widgetInstance">

  <div class="widget-host-menu-header inline-flex-row mouse-is-over-widget">
    <ng-template ngFor let-toolbarButton [ngForOf]="widgetInstance.fullScreenToolbarButtons">
      <button *ngIf="toolbarButton.isVisible$ | async" aria-label="More actions" data-focuszone=""
        data-is-focusable="true" role="button" tabindex="0" type="button"
        [disabled]="!(toolbarButton.canExecute$ | async)" (click)="toolbarButton.execute(widgetPlaceHolder)">
        <svg-icon [key]="toolbarButton.iconClass$ | async"></svg-icon>
      </button>
    </ng-template>
  </div>
</div>
