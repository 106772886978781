import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BaseIdentity, FilledButtonComponent, GenericServiceResponse, MetaDataUtils, NTSTranslatePipe, RouteChangeParams, RoutingService, ToastMessageService } from '@nts/std';
import { filter, map, Observable, of, take, tap } from 'rxjs';
import { WidgetComponent } from '../widget-component';
import { Router } from '@angular/router';
import { ClientConfigurationManager } from '@nts/std/src/lib/utility';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { NgxPopperjsPlacements, NgxPopperjsTriggers, NgxPopperjsModule } from "ngx-popperjs";
import { DashBoardItemViewModel } from 'src/app/dash-board/view-models/dash-board-item.view-model';
import { WidgetIdentityShortcutDto } from 'src/app/dash-board/domain-models/dto/widget-identity-shortcut.dto';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgClass, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-identity-shortcut-widget',
    templateUrl: './identity-shortcut-widget.component.html',
    styleUrls: ['./identity-shortcut-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        NgxPopperjsModule,
        NgClass,
        FilledButtonComponent,
        NTSTranslatePipe,
        NgIf
    ]
})
export class IdentityShortcutWidgetComponent extends WidgetComponent {

    title: string;
    subTitle: string;
    fullName: string;
    identity: BaseIdentity

    backgroundColor: string;
    icon: string;
    label: string;

    widgetIdentityShortcutDto: WidgetIdentityShortcutDto
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    dashboardItemVm: DashBoardItemViewModel
    // default env class is prod
    public envClass = 'prod';

    constructor(
        private cd: ChangeDetectorRef,
        toastMessageService: ToastMessageService,
        env: EnvironmentConfiguration,
        private readonly router: Router,
        private routingService: RoutingService
    ) {
        super(toastMessageService, cd);

        ClientConfigurationManager.Current.loaded.pipe(
            untilDestroyed(this),
            filter(loadingComplete => loadingComplete),
            take(1)
        ).subscribe(() => {
            if (
                ClientConfigurationManager.Current.config['DashBoard.MenuObjects.Models.MenuNode_useProductionColor'] === false ||
                ClientConfigurationManager.Current.config['DashBoard.MenuObjects.Models.MenuNode_useProductionColor'] == null) {
                this.envClass = env.envType.substr(0, 3).toLocaleLowerCase();
                this.cd.markForCheck();
            }
        })
    }

    protected override isVisibleRefreshCommand(): Observable<boolean> {
        return of(false);
    }

    override resizeChanged(dashboardItemVm: DashBoardItemViewModel) {
        super.resizeChanged(dashboardItemVm);
        this.dashboardItemVm = dashboardItemVm;
        this.cd.detectChanges();
    }

    protected getLoadDataObservable<WidgetAlertValueDto>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetAlertValueDto>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getIdentityShortcutData(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetIdentityShortcutDto): Observable<boolean> {
        this.widgetIdentityShortcutDto = result;

        this.title = result.title;

        this.cd.detectChanges();

        return of(true);
    }

    async start($event): Promise<boolean> {
        const newTab = $event.ctrlKey;


        const routeChangeParams = new RouteChangeParams();
        routeChangeParams.rootModelFullName = this.widgetIdentityShortcutDto.fullName;
        routeChangeParams.inBlank = newTab;

        if (this.widgetIdentityShortcutDto?.identity?.length > 1) {
            const serializedIdentity = this.getJsonIdentity(this.widgetIdentityShortcutDto.identity);
            if (serializedIdentity && this.isJsonString(serializedIdentity)) {
                routeChangeParams.routeParam = RoutingService.encodeUrl(serializedIdentity);
            }
        }
        routeChangeParams.queryParams = '?collapse-menu=true';

        this.routingService.routeChangeRequested.next(routeChangeParams)
        return true;
    }

    private getJsonIdentity(indentityString: string) {
        const identityParsed = JSON.parse(indentityString);
        const identity = {};
        for (const propertyName in identityParsed) {
            const propertyNameInCamelCase = MetaDataUtils.toCamelCase(propertyName);
            let val = identityParsed[propertyName];
            identity[propertyNameInCamelCase] = val;
        };
        return JSON.stringify(identity);
    }

    private isJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
