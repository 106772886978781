<div class="dashboards-widget-inline-catalog flex-grow flex-column flex-noshrink scroll-hidden">
    <div
        class="bolt-header-with-commandbar bolt-header flex-row flex-noshrink flex-start bolt-header-no-spacing-defined">
        <div class="bolt-header-title-area flex-column flex-grow scroll-hidden">
            <div class="flex-grow scroll-hidden widget-catalog-header">
                <div class="widget-catalog-header-text fontSizeL">Aggiungi Widget</div>
                <div [title]="'Widget_Ricerca' | NTSTranslate" class="widget-search"
                    *ngIf="!(orchestratorViewModel.loadingWidgetsStatusChanged | async)"
                    (done)="onDoneSearchWidgetsTourStep()" joyrideStep="searchWidgets"
                    text="{{'Widget_Ricerca_Text' | NTSTranslate}}">
                    <nts-text-button [icon]="'search'"></nts-text-button>
                    <nts-base-text-box [placeholder]="'Ricerca widgets'" [value]="searchText"
                        (onValueChange)="valueChange($event)"></nts-base-text-box>
                </div>
            </div>
        </div>
    </div>

    <div class="bolt-panel-content flex-row flex-grow scroll-auto">
        <div *ngIf="orchestratorViewModel.loadingWidgetsStatusChanged | async"
            class="catalog-spinner bolt-spinner flex-column text-center rhythm-vertical-8">
            <div class="bolt-spinner-circle medium"></div>
        </div>
        <div *ngIf="!(orchestratorViewModel.loadingWidgetsStatusChanged | async)">
            <table aria-label="List of available widgets"
                class="widget-catalog-list bolt-list body-m relative scroll-hidden" role="listbox" style="width: 100%;">
                <tbody class="relative">
                    <tr aria-hidden="true" class="bolt-list-row-spacer invisible" role="presentation">
                        <td class="bolt-list-cell-spacer invisible" colspan="1" style="height: 0px;"></td>
                    </tr>
                    <tr aria-hidden="true" class="bolt-list-row-spacer invisible" role="presentation">
                        <td class="bolt-list-cell-spacer invisible" colspan="1" style="height: 0px;"></td>
                    </tr>
                    <ng-template ngFor let-widget [ngForOf]="orchestratorViewModel.widgets | widgetFilter: searchText"
                        let-i="index">

                        <tr joyrideStep="firstWidgetSelected" [title]="'Widget_Seleziona' | NTSTranslate"
                            text="{{'Widget_Seleziona_Text' | NTSTranslate}}" *ngIf="i == 0"
                            (mousedown)="selectWidget(widget)" aria-busy="false" [attr.aria-posinset]="i + 1"
                            [attr.aria-selected]="orchestratorViewModel.widgets.selectedItem == widget"
                            [class.selected]="orchestratorViewModel.widgets.selectedItem == widget" aria-setsize="32"
                            class="widget-row bolt-list-row" data-focuszone="focuszone-105" data-row-index="2"
                            tabindex="-1" role="option">
                            <td class="bolt-list-cell" data-column-index="0">
                                <div class="bolt-list-cell-content flex-row">
                                    <div draggable="true" style="width: 100%;" (dragstart)="dragStartHandler($event)">
                                        <div class="widget-catalog-entry flex flex-noshrink flex-column">
                                            <div class="flex-row">
                                                <div class="widget-catalog-image bolt-image-wrapper"
                                                    style="width: 100px; height: 100px;"><img draggable="false" alt=""
                                                        class="bolt-image flex-noshrink contain"
                                                        src="assets\images\nts\widget\{{widget.widgetType.value}}.png">
                                                </div>
                                                <div class="widget-details">
                                                    <div class="widget-name-container">
                                                        <div class="widget-name fontSizeL text-ellipsis">
                                                            {{ widget?.name?.value }}
                                                        </div>
                                                    </div>
                                                    <div class="widget-description fontSizeM text-ellipsis">
                                                        {{ widget?.description?.value }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div *ngIf="orchestratorViewModel.widgets.selectedItem == widget"
                                                class="widget-more-info flex-row">
                                                <div class="widget-publisher fontSizeM text-ellipsis">By Microsoft</div>
                                                <a class="flagged-widget-gallery-link fontSizeM bolt-link"
                                                    data-focuszone="focuszone-107"
                                                    href="https://go.microsoft.com/fwlink/?LinkID=760415" rel="noopener"
                                                    tabindex="0" target="_blank">Learn More</a>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="i > 0" (mousedown)="selectWidget(widget)" aria-busy="false"
                            [attr.aria-posinset]="i + 1"
                            [attr.aria-selected]="orchestratorViewModel.widgets.selectedItem == widget"
                            [class.selected]="orchestratorViewModel.widgets.selectedItem == widget" aria-setsize="32"
                            class="widget-row bolt-list-row" data-focuszone="focuszone-105" data-row-index="2"
                            tabindex="-1" role="option">
                            <td class="bolt-list-cell" data-column-index="0">
                                <div class="bolt-list-cell-content flex-row">
                                    <div draggable="true" style="width: 100%;" (dragstart)="dragStartHandler($event)">
                                        <div class="widget-catalog-entry flex flex-noshrink flex-column">
                                            <div class="flex-row">
                                                <div class="widget-catalog-image bolt-image-wrapper"
                                                    style="width: 100px; height: 100px;"><img draggable="false" alt=""
                                                        class="bolt-image flex-noshrink contain"
                                                        src="assets\images\nts\widget\{{widget.widgetType.value}}.png">
                                                </div>
                                                <div class="widget-details">
                                                    <div class="widget-name-container">
                                                        <div class="widget-name fontSizeL text-ellipsis">
                                                            {{ widget?.name?.value }}
                                                        </div>
                                                    </div>
                                                    <div class="widget-description fontSizeM text-ellipsis">
                                                        {{ widget?.description?.value }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div *ngIf="orchestratorViewModel.widgets.selectedItem == widget"
                                                class="widget-more-info flex-row">
                                                <div class="widget-publisher fontSizeM text-ellipsis">By Microsoft</div>
                                                <a class="flagged-widget-gallery-link fontSizeM bolt-link"
                                                    data-focuszone="focuszone-107"
                                                    href="https://go.microsoft.com/fwlink/?LinkID=760415" rel="noopener"
                                                    tabindex="0" target="_blank">Learn More</a>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <tr aria-hidden="true" class="bolt-list-row-spacer invisible" role="presentation">
                        <td class="bolt-list-cell-spacer invisible" colspan="1" style="height: 0px;"></td>
                    </tr>
                    <tr aria-hidden="true" class="bolt-list-row-spacer invisible" role="presentation">
                        <td class="bolt-list-cell-spacer invisible" colspan="1" style="height: 0px;"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="bolt-panel-separator flex-noshrink"></div>
    <div class="bolt-panel-footer flex-center">
        <!-- <span>Don't see a widget? Explore the <a class="bolt-link" href="https://marketplace.visualstudio.com/"
                rel="noopener" tabindex="0" target="_blank">Extension Gallery</a></span> -->
        <div class="bolt-panel-footer-buttons flex-grow bolt-button-group flex-row rhythm-horizontal-8">
            <nts-filled-button [type]="3" [title]="'Widget_Aggiungi' | NTSTranslate"
                text="{{'Widget_Aggiungi_Text' | NTSTranslate}}" joyrideStep="addSelectedWidget"
                (done)="onDoneAddSelectedWidgetTourStep()" (next)="onNextAddSelectedWidgetTourStep()"
                [label]="'Aggiungi'" (onClicked)="addSelectedWidget()">
            </nts-filled-button>
        </div>
    </div>
</div>
