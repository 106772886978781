import { Expose } from '@nts/std';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetAlertValueDto extends WidgetBaseValueDto {

    @Expose()
    public currentState: boolean;

    @Expose()
    public alertLabel: string;

    @Expose()
    public regularLabel: string;
}



