import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { BaseNumericPropertyViewModel, GenericServiceResponse, ToastMessageService } from '@nts/std';
import { WidgetGaugeValue } from '../../../domain-models/dto/widget-gauge-value-dto';
import { ColorType } from 'highcharts';
import { Observable, map, of, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DashBoardItemViewModel } from 'src/app/dash-board/view-models/dash-board-item.view-model';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HC_boost from 'highcharts/modules/boost';
import HC_noDataToDisplay from 'highcharts/modules/no-data-to-display';
import HC_more from 'highcharts/highcharts-more';
import HC_solidGauge from 'highcharts/modules/solid-gauge';
import { NgIf } from '@angular/common';

HC_boost(Highcharts);
HC_noDataToDisplay(Highcharts);
HC_more(Highcharts);
HC_solidGauge(Highcharts);

@UntilDestroy()
@Component({
    selector: 'nts-gauge-widget',
    templateUrl: './gauge-widget.component.html',
    styleUrls: ['./gauge-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HighchartsChartModule,
        NgIf
    ]
})

// tslint:disable-next-line: max-line-length
export class GaugeWidgetComponent extends WidgetFullScreenComponent implements AfterViewInit, OnInit {
    @ViewChild('chartContainer') chartContainer: ElementRef;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options;
    Highcharts: typeof Highcharts = Highcharts;

    title: string;
    subTitle: string;
    serieName: string
    /*
    -- LifeCycle --
    ngOnInit
        -> initChart ( va fatta sempre nella OnInit)!
    logChartInstance -> qui hoi il grafico
    initialize  -> qui posso caricare i dati
    ngAfterViewInit
    */

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    protected getLoadDataObservable<WidgetGaugeValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetGaugeValue>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getGaugeWidgetValue(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetGaugeValue): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;
        this.serieName = result.serieName;

        const minValue = result.minValue;
        const maxValue = result.maxValue;
        const value = result.currentValue;
        const firstLimit = (result.firstLimit - minValue) / (maxValue - minValue);
        const secondLimit = (result.secondLimit - minValue) / (maxValue - minValue);

        const statusValue: [number, ColorType][] = [
            [0, '#55BF3B'],          // green
            [firstLimit, '#DDDF0D'], // yellow
            [secondLimit, '#DF5353'] // red
        ];


        // aggiorno il grafico
        this.chart.update({
            yAxis: {
                stops: statusValue,
                min: minValue,
                max: maxValue,
                tickPositioner: function () {
                    return [this.min, this.max];
                },
            },
            series: [{
                name: this.serieName,
                type: 'solidgauge',
                data: [
                    value
                ],
                tooltip: {
                    valueSuffix: this.serieName
                }
            }]
        }, true, true, true);

        this.resizeChanged(null);

        this.cd.detectChanges();

        return of(true);
    }

    ngOnInit() {
        this.initChart();
    }

    initChart() {
        // parametri default
        this.chartOptions = {
            chart: {
                type: 'solidgauge'
            },
            title: '' as any,
            pane: {
                center: ['50%', '85%'],
                size: '120%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                } as any
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            // the value axis
            yAxis: {
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70,
                },
                labels: {
                    y: 16,
                    formatter: function () {
                        return Highcharts.numberFormat(this.value as number, -1, BaseNumericPropertyViewModel.getDecimalSeparator(), BaseNumericPropertyViewModel.getThousandSeparator());
                    }
                },
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            /*responsive: {
                rules: [{
                    condition: {
                        minH: 500
                    },
                    chartOptions: {
                        yAxis: {
                            title: {
                                y: 0,
                                //text: 'RAM'
                            }
                        }
                    }
                }]
            }*/
        };
    }

    ngAfterViewInit() {
    }

    logChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    protected override async resizeChanged(dashboardItemVm: DashBoardItemViewModel) {
        super.resizeChanged(dashboardItemVm);
        if (this.chart) {
            setTimeout(() => {
                // la prima volta devo effettuar eil timeout per il disegno corretto del grafico dentro il content
                this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, this.chartContainer.nativeElement.offsetHeight, true);
                // resize del font in base alla dimensione del widget
                this.updateSerieName(this.chartContainer.nativeElement.offsetWidth, this.chartContainer.nativeElement.offsetHeight);
            });
        }
    }

    updateSerieName(charWidth: number, charHeight: number) {
        const fontSize = (charWidth > 300 && charHeight > 200 ? 25 : 12);
        this.chart.update({
            series: [{
                name: this.serieName,
                type: 'solidgauge',
                dataLabels: {
                    formatter: function () {
                        const value = Highcharts.numberFormat(this.y, -1, BaseNumericPropertyViewModel.getDecimalSeparator(), BaseNumericPropertyViewModel.getThousandSeparator());
                        return '<div style="text-align:center">' +
                            '<span style="font-size:' + fontSize + 'px">' + value + '</span><br/>' +
                            '<span style="font-size:' + (fontSize * 0.80) + 'px;opacity:0.4">' + this.series.name + '</span>' +
                            '</div>'
                    }
                }
            }]
        });
        return;
    }
}

