import { Expose } from '@nts/std';
import { WidgetDataArg } from './widget-data-arg-dto';

export class WidgetResultSetFilterDataArgDto extends WidgetDataArg {

    @Expose()
    public take = 20;

    @Expose()
    public skip: number
}
