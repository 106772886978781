import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { GenericServiceResponse, ToastMessageService } from '@nts/std';
import { WidgetOnOffValue } from '../../../domain-models/dto/widget-onoff-value-dto';
import { Observable, map, of, tap } from 'rxjs';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-onoff-widget',
    templateUrl: './onoff-widget.component.html',
    styleUrls: ['./onoff-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf
    ]

})
export class OnOffWidgetComponent extends WidgetFullScreenComponent {

    title: string;
    subTitle: string;
    currentState: boolean;
    onLabel: string;
    offLabel: string;

    disabled = false;

    constructor(
        private cd: ChangeDetectorRef,
        toastMessageService: ToastMessageService
    ) {
        super(toastMessageService, cd);
    }

    protected override isVisibleFullscreenOptions(): Observable<boolean> {
        return of(!this.sameWidgetInFullScreen);
    }

    protected getLoadDataObservable<WidgetOnOffValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetOnOffValue>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getOnOffWidgetValue(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetOnOffValue): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;
        this.currentState = result.currentState;
        this.onLabel = result.onLabel;
        this.offLabel = result.offLabel;

        this.cd.detectChanges();

        return of(true);
    }
}
