import { Expose } from '@nts/std';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetGaugeValue extends WidgetBaseValueDto {

    @Expose()
    public currentValue: number;

    @Expose()
    public minValue: number;

    @Expose()
    public maxValue: number;

    @Expose()
    public serieName: string;

    @Expose()
    public firstLimit: number;

    @Expose()
    public secondLimit: number;
}



