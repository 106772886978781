import { Pipe, PipeTransform } from '@angular/core';
import { WidgetViewModel } from '../../view-models/widget.view-model';

@Pipe({
    name: 'widgetFilter',
    standalone: true
})
export class WidgetFilterPipe implements PipeTransform {
    transform(widgets: WidgetViewModel[], searchText: string): any[] {
        if (!widgets) {
            return [];
        }
        if (!searchText || searchText.length === 0) {
            return widgets;
        }
        searchText = searchText.toLocaleLowerCase();

        return widgets.filter(w =>
            w.name?.value?.toLocaleLowerCase().includes(searchText) || w.description?.value?.toLocaleLowerCase().includes(searchText)
        );
    }
}
