// tslint:disable-next-line: max-line-length
import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, NumberDecorator, EnumDecorator, CoreModel, BoolDecorator, OCCModel, InternalDecorator, Type, UIInfo } from '@nts/std';
import { MenuNodeIdentity } from './menu-node.identity';
import { MenuNodeUi } from './menu-node-ui';

@IdentityTypeDecorator(MenuNodeIdentity)
@ModelTypeNameDecorator('MenuNodeUiWithChildren')
export class MenuNodeUiWithChildren extends MenuNodeUi {

    @Expose()
    @Type(() => MenuNodeUiWithChildren)
    public children: MenuNodeUiWithChildren[];
}
