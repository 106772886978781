<div class="work-item-results-grid">
  <div class="top-bar">
    <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode"> {{ title }} </h2>
    <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3>
  </div>
  <div class="work-item-type-filters-container flex-noshrink">
    <button class="work-item-type-filter bolt-button enabled subtle bolt-focus-treatment" type="button">
      <div class="work-item-type-filter-icon-container">
        <i class="ms-Icon--Search work-item-type-icon work-item-type-filter-icons normal bowtie-icon lex-noshrink"></i>
      </div>
      <div class="work-item-type-filter-count">{{total}}</div>
      <div class="flex-column flex-baseline flex-self-end">
        <div class="work-item-type-filter-type"><span>Totale</span></div>
      </div>
    </button>
  </div>
  <div id="scroll" class="wit-ui-control work-item-tree-grid">
    <div class="bolt-table-container flex-grow">
      <table class="bolt-table bolt-list body-m relative scroll-hidden">
        <colgroup>
          <col aria-hidden="true" style="width: 8px;">
          <col style="width: 50px;">
          <col style="width: 24.9969%;">
          <col style="width: 74.9906%;">
          <col style="width: 0.0124984%;">
          <col aria-hidden="true" style="width: 8px;">
        </colgroup>
        <thead>
          <tr class="bolt-table-header-row">
            <th class="bolt-table-header-border"></th>
            <th *ngFor="let label of labels" class="work-item-grid-column-header bolt-table-header-cell">
              <div class=" bolt-table-header-cell-content flex-row">
                <div class="bolt-table-header-sizer bolt-sizer bolt-sizer-row flex-row">
                </div>
                <div class="scroll-hidden">
                  <div class="bolt-table-header-cell-text text-ellipsis body-s"><span>{{label}}</span></div>
                </div>
              </div>
            </th>
            <th class="bolt-table-header-cell bolt-table-header-cell-empty col-header-3"></th>
            <th class="bolt-table-header-border"></th>
          </tr>
        </thead>
        <tbody class="relative">
          <tr *ngFor="let values of valueList
            let index = index;
            let isOdd = odd;
            let isEven = even" [class.odd]="isOdd" [class.even]="isEven"
            class="bolt-tree-row bolt-table-row bolt-list-row first-row">
            <td class="bolt-table-cell-compact bolt-table-cell bolt-list-cell"></td>
            <!-- <td class="work-item-grid-column bolt-table-cell bolt-list-cell">
              <div class="bolt-table-cell-content flex-row flex-center">
                <div class="text-ellipsis">{{index}}</div>
              </div>
            </td>-->
            <td *ngFor="let value of values" class="work-item-grid-column bolt-table-cell bolt-list-cell">
              <div class="bolt-table-cell-content flex-row flex-center">
                <div class="wit-ui-control work-item-state">
                  <!-- <div class="work-item-state-circle withText"
                    style="background-color: rgb(0, 122, 204); border-color: rgba(0, 122, 204, 0.3);"></div> -->
                  <div class="work-item-state-name"><span>{{value}}</span></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
