<div class="viewport">
  <div class="title-container">
    <div class="top-bar">
      <h2 class="visual-title text-ellipsis text-center" *ngIf="!isFullScreenMode"> {{ title }} </h2>
      <h3 class="visual-subtitle text-subtile-ellipsis text-center"> {{ subTitle }} </h3>
    </div>
  </div>
  <div class="chart-container" #chartContainer>
    <highcharts-chart [Highcharts]="Highcharts" [(update)]="forceUpdate" [options]="chartOptions"
      (chartInstance)="logChartInstance($event)">
    </highcharts-chart>
  </div>

  <div class="total-container" *ngIf="showFooterTotal">
    <h2 class="visual-total text-center text-ellipsis">
      Totale {{ currencyFormat(total) }} {{ serieName }}
    </h2>
  </div>
</div>


<!--
<div class="reporting-visuals-common-scalar" #chartContainer>
  <div style="display: block;">
    <h2 class="visual-title text-center text-ellipsis">
      {{ title }}
    </h2>
  </div>
  <div class="chartContainer">
    <highcharts-chart [Highcharts]="Highcharts" [(update)]="forceUpdate" [options]="chartOptions"
      (chartInstance)="logChartInstance($event)">
    </highcharts-chart>
  </div>
  <div class="totalContainer" *ngIf="showFooterTotal">
    <h2 class="text-center text-ellipsis">
      Totale {{ total }} {{ serieName }}
    </h2>
  </div>
</div> -->
