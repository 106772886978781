import { WidgetComponent } from './widget-component';
import { Observable, of } from 'rxjs';
import { CommandFactory, UICommandInterface } from '@nts/std';
import { FullScreenChartModalViewModel } from '../../view-models/modals/full-screen-chart-modal-view-model';
import { WidgetFullScreenComponentInterface } from './widget-full-screen-component.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export abstract class WidgetFullScreenComponent extends WidgetComponent implements WidgetFullScreenComponentInterface {
  widgetFullScreenComponentFactory: any;
  sameWidgetInFullScreen = true;
  startMaximized = true;
  maximizable = false;
  fullScreenToolbarButtons: Array<UICommandInterface> = [];
  modalTitle: string;
  isFullScreenMode = false;

  override async initToolbar() {
    await super.initToolbar();

    const refreshCommand = CommandFactory.createUICommand(
      (x) => this.refreshData(),
      () => of(true),
      null,
      () => of(true)
    );
    refreshCommand.iconClass = 'refresh';
    refreshCommand.displayName = '';
    refreshCommand.tooltip = '';

    this.refreshInProgress$.pipe(untilDestroyed(this)).subscribe((refreshInProgress) => {
      refreshCommand.iconClass$.next(refreshInProgress ? 'refresh-animated' : 'refresh')
    })

    this.fullScreenToolbarButtons.push(refreshCommand);

    const fullScreenCommand = CommandFactory.createUICommand(
      (x) => this.fullscreenOptions(x),
      () => this.canFullscreenOptions(),
      null,
      () => this.isVisibleFullscreenOptions()
    );
    fullScreenCommand.iconClass = this.sameWidgetInFullScreen ? 'expand' : 'substract';
    fullScreenCommand.displayName = '';
    fullScreenCommand.tooltip = '';

    this.toolbarButtons$.next([fullScreenCommand, ...this.toolbarButtons$.value]);

  }

  private async fullscreenOptions(x) {

    const fullscreenChartModalViewModel = new FullScreenChartModalViewModel();
    await fullscreenChartModalViewModel.initialize();
    fullscreenChartModalViewModel.widgetFullScreenComponentFactory = this.widgetFullScreenComponentFactory;
    fullscreenChartModalViewModel.widgetRef = this.sameWidgetInFullScreen ? this.vm : this.vm.linkedWidgetRef;
    fullscreenChartModalViewModel.initialData = this.sameWidgetInFullScreen ? this.initialData : null;
    fullscreenChartModalViewModel.orchestratorViewModel = this.orchestratorViewModel;
    await this.orchestratorViewModel.modalService.showCustomModalWithResultAsync(
      fullscreenChartModalViewModel,
      false,
      this.maximizable,
      this.startMaximized,
      {
        disableClose: true,
        panelClass: [
          'fullscreen-modal'
        ]
      }
    );
  }

  protected canFullscreenOptions(): Observable<boolean> {
    return of(true);
  }

  protected isVisibleFullscreenOptions(): Observable<boolean> {
    return of(true);
  }
}
