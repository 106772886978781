import { RootModelTypeNameDecorator, RootModelTypeDecorator, GenericServiceResponse, ServiceRequest, GenericServiceRequest, ServiceResponse } from '@nts/std';
import { CrudApiClient } from '@nts/std';
import { GetRootNodesRequest } from '../requests/get-root-nodes.request';
import { Observable } from 'rxjs';
import { GetRootNodesResponse } from '../responses/get-root-nodes.response';
import { MenuNodeAssociation } from '../domain-models/menu-node-association';
import { MenuNodeAssociationIdentity } from '../domain-models/menu-node-association.identity';
import { GetChildrenRequest } from '../requests/get-children.request';
import { GetChildrenResponse } from '../responses/get-children.response';
import { GetAllNodesResponse } from '../responses/get-all-nodes.response';
import { MenuNodeIdentity } from '../domain-models/menu-node.identity';
import { GetChildrenFromNodeListRequest } from '../requests/get-children-from-node-list.request';
import { GetChildrenFromNodeListResponse } from '../responses/get-children-from-node-list.response';
import { OffLineTreeDataDto } from '../domain-models/dto/off-line-tree-data-dto';
import { WidgetMenuItemShortcutDto } from 'src/app/dash-board/domain-models/dto/widget-menu-item-shortcut.dto';

@RootModelTypeNameDecorator('MenuNodeAssociation')
@RootModelTypeDecorator(MenuNodeAssociation)
export class MenuNodeAssociationApiClient extends CrudApiClient<MenuNodeAssociation, MenuNodeAssociationIdentity> {

    public getRootNodes(): Observable<GetRootNodesResponse> {
        const request = new GetRootNodesRequest();
        request.take = 1000;
        const response: GetRootNodesResponse = new GetRootNodesResponse();
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance<GetRootNodesRequest, GetRootNodesResponse>(
                'GetRootNodes',
                request,
                response,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                    bypass: false,
                    expirationTime: undefined,
                    force: false,
                    enableTimeout: true,
                    tenantBarrier: true,
                    enterpriseBarrier: false,
                    userBarrier: true
                },
            );
    }

    public getAllNodes(): Observable<GetAllNodesResponse> {
        const request = new GetRootNodesRequest();
        request.take = 1000;
        const response: GetAllNodesResponse = new GetAllNodesResponse();
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance<GetRootNodesRequest, GetAllNodesResponse>(
                'GetAllNodes',
                request,
                response,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                    bypass: false,
                    expirationTime: undefined,
                    force: false,
                    enableTimeout: true,
                    tenantBarrier: true,
                    enterpriseBarrier: false,
                    userBarrier: true
                },
            );
    }

    public getOffLineTree(version: string = null): Observable<GenericServiceResponse<OffLineTreeDataDto>> {
        const response: GenericServiceResponse<OffLineTreeDataDto> = new GenericServiceResponse(OffLineTreeDataDto);
        return this.getWebApiServiceAgent()
            .invokeGetWithOutQueryWithInstance<GenericServiceResponse<OffLineTreeDataDto>>(
                version?.length > 0 ? `GetOffLineTree/${version}` : 'GetOffLineTree', response);
    }

    public getChildren(identity: MenuNodeIdentity): Observable<GetChildrenResponse> {
        const request = new GetChildrenRequest();
        request.identity = identity;
        const response: GetChildrenResponse = new GetChildrenResponse();
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance<GetChildrenRequest, GetChildrenResponse>(
                'GetChildren',
                request,
                response,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                    bypass: false,
                    expirationTime: undefined,
                    force: false,
                    enableTimeout: true,
                    tenantBarrier: true,
                    enterpriseBarrier: true,
                    userBarrier: true
                },
            );
    }

    public getChildrenFromNodeList(identityList: MenuNodeIdentity[]): Observable<GetChildrenFromNodeListResponse> {
        const request = new GetChildrenFromNodeListRequest();
        request.requestData = identityList;
        return this.getWebApiServiceAgent()
            .invokePostWithResponse<GetChildrenFromNodeListRequest, GetChildrenFromNodeListResponse>(
                'GetChildrenFromNodeList',
                request,
                GetChildrenFromNodeListResponse,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                    bypass: false,
                    expirationTime: undefined,
                    force: false,
                    enableTimeout: true,
                    tenantBarrier: true,
                    enterpriseBarrier: false,
                    userBarrier: true
                },
            );
    }

    public pinMenuItemToDashBoard(dto: WidgetMenuItemShortcutDto): Observable<ServiceResponse> {
        const request: GenericServiceRequest<WidgetMenuItemShortcutDto> = new GenericServiceRequest<WidgetMenuItemShortcutDto>();
        request.requestData = dto;

        return this.getWebApiServiceAgent().invokePostWithResponse<
            GenericServiceRequest<WidgetMenuItemShortcutDto>, ServiceResponse>(
                'PinMenuItemToDashBoard', request, ServiceResponse);
    }
}
