import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, Scroll } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IframeComponent, LoaderComponent, RoutingService } from '@nts/std';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { filter, firstValueFrom, map, Subject } from 'rxjs';
import { RouteAdditionalData } from '../../domain-models/route-additional-data';
import { NgIf } from '@angular/common';
import { LogService } from '@nts/std/src/lib/utility';


@UntilDestroy()
@Component({
    selector: 'nts-link-container',
    templateUrl: './link-container.component.html',
    styleUrls: ['./link-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LoaderComponent,
        IframeComponent,
        NgIf
    ]
})
export class LinkContainerComponent {

    @ViewChild('iframe', { static: false }) iframe: ElementRef;

    url: string;

    private onActionInProgress = new Subject<boolean>();

    actionInProgress = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef,
        private environmentConfiguration: EnvironmentConfiguration
    ) {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
                map((event) => event instanceof Scroll ? event.routerEvent as NavigationEnd : event as NavigationEnd),
            ).subscribe(async () => {

                const oldUrl = this.url;
                this.url = '';
                this.cd.detectChanges();
                this.url = oldUrl;
                this.cd.detectChanges();
                const routeParams = await firstValueFrom(this.route.params);
                this.check(routeParams);
            });

        this.onActionInProgress.pipe(untilDestroyed(this)).subscribe((onActionInProgress) => {
            this.actionInProgress = onActionInProgress;
            this.cd.detectChanges();
        })
    }

    private async check(routeParams: Params) {
        const encondedAdditionalData = routeParams['encondedAdditionalData'];

        let routeAdditionalData = new RouteAdditionalData();

        try {
            routeAdditionalData = RoutingService.decodeObject<RouteAdditionalData>(encondedAdditionalData, RouteAdditionalData);
        } catch (e) {
            LogService.warn(`Impossibile decodificare ${encondedAdditionalData}`, e)
        }

        if (routeAdditionalData.shortDescription?.length > 0) {
            window.document.title = `${routeAdditionalData.shortDescription} - ${this.environmentConfiguration.appTitle}`;
        }

        if (routeAdditionalData?.args?.length > 0 && routeAdditionalData.nodePath?.length > 0) {
            this.url = `${routeAdditionalData.args}`;
            this.cd.detectChanges();
        } else {
            this.router.navigate(['/not-found']);
        }
    }
}
