import { Component } from '@angular/core';
import { BaseTextBoxComponent, LabelBoxComponent, ModalComponentInterface, NTSTranslatePipe } from '@nts/std';
import { PinToDashboardMenuItemShortcutModalViewModel } from 'src/app/dash-board-container/view-models/modals/pin-to-dashboard-menu-item-shortcut-modal-view-model';

@Component({
    selector: 'nts-pin-to-dashboard-menu-item-shortcut-modal-container',
    templateUrl: 'pin-to-dashboard-menu-item-shortcut-modal-container.component.html',
    styleUrls: ['./pin-to-dashboard-menu-item-shortcut-modal-container.component.scss'],
    standalone: true,
    imports: [
        LabelBoxComponent,
        BaseTextBoxComponent,
        NTSTranslatePipe
    ]
})

export class PinToDashboardMenuItemShortcutModalContainerComponent implements ModalComponentInterface {
    viewModel: PinToDashboardMenuItemShortcutModalViewModel;

    onValueChange($event) {
        this.viewModel.shortDescriptionChange.next($event);
    }
}
