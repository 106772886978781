import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, Scroll } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { AuthService, IframeComponent, LoaderComponent, RoutingService } from '@nts/std';
import { filter, firstValueFrom, map, Subject } from 'rxjs';
import { RouteAdditionalData } from '../../domain-models/route-additional-data';
import { NgIf } from '@angular/common';
import { LogService } from '@nts/std/src/lib/utility';


@UntilDestroy()
@Component({
    selector: 'nts-external-app-link-container',
    templateUrl: './external-app-link-container.component.html',
    styleUrls: ['./external-app-link-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LoaderComponent,
        IframeComponent,
        NgIf
    ]
})
export class ExternalAppLinkContainerComponent {

    @ViewChild('iframe', { static: false }) iframe: ElementRef;

    url: string;

    private onActionInProgress = new Subject<boolean>();

    actionInProgress = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly cd: ChangeDetectorRef,
        private readonly environment: EnvironmentConfiguration,
        private readonly authService: AuthService
    ) {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
                map((event) => event instanceof Scroll ? event.routerEvent as NavigationEnd : event as NavigationEnd),
            ).subscribe(async () => {

                const oldUrl = this.url;
                this.url = '';
                this.cd.detectChanges();
                this.url = oldUrl;
                this.cd.detectChanges();
                const routeParams = await firstValueFrom(this.route.params);
                this.check(routeParams);
            });

        this.onActionInProgress.pipe(untilDestroyed(this)).subscribe((onActionInProgress) => {
            this.actionInProgress = onActionInProgress;
            this.cd.detectChanges();
        })
    }

    private async check(routeParams: Params) {
        const encondedAdditionalData = routeParams['encondedAdditionalData'];
        const externalAppClientId = routeParams['externalAppClientId'];

        let routeAdditionalData = new RouteAdditionalData();
        try {
            routeAdditionalData = RoutingService.decodeObject<RouteAdditionalData>(encondedAdditionalData, RouteAdditionalData);
        } catch (e) {
            LogService.warn(`Impossibile decodificare ${encondedAdditionalData}`, e)
        }
        const authUrl: string = this.environment.authenticationAppUrl;

        const accessToken: string = await this.authService.getAccessToken();
        const refreshToken: string = await this.authService.getRefreshToken();

        if (routeAdditionalData?.args?.length > 0 && routeAdditionalData.nodePath?.length > 0 && externalAppClientId?.length > 0) {

            // Link esempio: https://loginservice.ntsinformatica.it/auth/authorize-app?redirect-url=http:%2F%2Flocalhost:59270%2F&client-id=eTQlYQOLLWOXRwaj

            this.url = authUrl + '?access-token=' + accessToken +
                '&refresh-token=' + refreshToken +
                '&client-id=' + externalAppClientId +
                '&force-app-auth=true' +
                '&iframe=true' +
                '&redirect-url=' + encodeURIComponent(routeAdditionalData.args);

            this.cd.detectChanges();
        } else {
            this.router.navigate(['/not-found']);
        }
    }
}
