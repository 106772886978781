import { ModelTypeNameDecorator, IdentityTypeDecorator, CoreModel, Expose, NumberDecorator, StringDecorator, BaseIdentity, IdentityTypeNameDecorator } from '@nts/std';

@IdentityTypeDecorator(MenuNodeIdentity)
@IdentityTypeNameDecorator('MenuNodeIdentity')
export class MenuNodeIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    public get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    public set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}
