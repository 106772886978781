import { UIInfo, ToastMessageService, GenericServiceResponse, NTSTranslatePipe } from '@nts/std';
import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { WidgetProgramLinkDataArg } from '../../../domain-models/dto/widget-programlink-data-arg-dto';
import { AuthService } from '@nts/std';
import { WidgetProgramLinkValue } from '../../../domain-models/dto/widget-programlink-value-dto';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { Observable, map, of, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-program-link-widget',
    templateUrl: './program-link-widget.component.html',
    styleUrls: ['./program-link-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NTSTranslatePipe,
        NgIf
    ]
})
export class ProgramLinkWidgetComponent extends WidgetFullScreenComponent {

    title: string;
    subTitle: string;
    uiInfo: UIInfo;

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService, private authService: AuthService) {
        super(toastMessageService, cd);
    }

    protected override isVisibleFullscreenOptions(): Observable<boolean> {
        return of(!this.sameWidgetInFullScreen);
    }

    protected getLoadDataObservable<WidgetProgramLinkValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetProgramLinkValue>> {
        const widgetProgramLinkDataArg = new WidgetProgramLinkDataArg();
        widgetProgramLinkDataArg.identity = this.vm.getDomainModel().currentIdentity;
        return this.getApiClient().getProgramLinkWidgetValue(widgetProgramLinkDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetProgramLinkValue): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;
        this.uiInfo = result.uiInfo;

        this.cd.detectChanges();

        return of(true);
    }

    async startProgram() {
        const refreshToken: string = await this.authService.getRefreshToken();
        const accessToken: string = await this.authService.getAccessToken();
        const concatenateOperator = this.uiInfo.fullAddress.split('?').length > 1 ? '&' : '?';
        const newHref = `${this.uiInfo.fullAddress}${concatenateOperator}${AuthService.REFRESH_TOKEN_QUERY_KEY}=${refreshToken != null ? refreshToken : ''}&${AuthService.ACCESS_TOKEN_QUERY_KEY}=${accessToken != null ? accessToken : ''}`;
        window.open(newHref);
    }
}
