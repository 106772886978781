import { Expose } from '@nts/std';
import { DataLevel } from '../enum/data-level';

export class CellDto {

    constructor(
        dataValue?: string,
        dataLevel?: DataLevel
    ) {
        this.dataValue = dataValue;
        this.dataLevel = dataLevel;
    }

    /**
     * se non è valorizzato il valore allora visualizzare solo l'icona
     */
    @Expose()
    public dataValue: string

    @Expose()
    public dataLevel: DataLevel;
}
