import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { GenericServiceResponse, ToastMessageService } from '@nts/std';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { WidgetSeriesValueDto } from '../../../domain-models/dto/widget-series-value.dto';
import cloneDeep from 'lodash-es/cloneDeep';
import { TimeLineItem } from '../../../domain-models/dto/widget-time-line-item-dto';
import { Observable, map, of, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DashBoardItemViewModel } from 'src/app/dash-board/view-models/dash-board-item.view-model';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HC_boost from 'highcharts/modules/boost';
import HC_noDataToDisplay from 'highcharts/modules/no-data-to-display';
import HC_more from 'highcharts/highcharts-more';
import { NgIf } from '@angular/common';

HC_boost(Highcharts);
HC_noDataToDisplay(Highcharts);
HC_more(Highcharts);
@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'nts-dynamic-time-line-widget',
    templateUrl: './dynamic-time-line-widget.component.html',
    styleUrls: ['./dynamic-time-line-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HighchartsChartModule,
        NgIf
    ]
})

export class DynamicTimeLineWidgetComponent extends WidgetFullScreenComponent implements AfterViewInit, OnInit {

    // http://jsfiddle.net/6hCUx/1/ <-- Esempio interessante con pulsanti per cambiare lo zoom
    // https://codepen.io/pen/?&editable=true=https%3A%2F%2Fwww.highcharts.com%2Fsamples%2Fhighcharts%2Fdemo%2Fline-time-series%3Fcodepen

    @ViewChild('chartContainer') chartContainer: ElementRef;

    chart: Highcharts.Chart;
    chartOptions;
    Highcharts: typeof Highcharts = Highcharts;
    title: string;
    subTitle: string;
    updateFlag = false;

    /*
    -- LifeCycle --
    ngOnInit
        -> initChart ( va fatta sempre nella OnInit)!
    logChartInstance -> qui hoi il grafico
    initialize  -> qui posso caricare i dati
    ngAfterViewInit
    */

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    protected getLoadDataObservable<WidgetSeriesValueDto>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetSeriesValueDto>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getDynamicTimeLineWidgetValues(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetSeriesValueDto): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;

        const chartOptions = cloneDeep(this.chartOptions);

        chartOptions.series = [];

        for (var i = 0; i < result.timeLineList?.length; i++) {
            const timeLineItems = result.timeLineList[i];
            if (timeLineItems) {
                const data = timeLineItems.map((timeLineItem: TimeLineItem) => [
                    parseInt(timeLineItem.name, 10),
                    timeLineItem.value
                ]);
                chartOptions.series.push(
                    {
                        type: result.graphTypeName.toLowerCase(),
                        name: result.timeLineParamsList[i].timeLineDescription,
                        data
                    }
                )
            }
        }

        chartOptions.series = chartOptions.series.filter((s) => s != null);

        this.chart.update(chartOptions, true, true);
        this.updateFlag = true;

        setTimeout(() => {
            this.resizeChanged(null);
        });

        this.cd.detectChanges();

        return of(true);
    }

    ngOnInit() {
        this.initChart();
    }

    initChart() {
        // parametri default
        this.chartOptions = {
            tooltip: {
                // https://devhints.io/datetime (strftime format)
                xDateFormat: '%e %B %Y, %H:%M'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                }
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: this.fullScreenMode,
                align: 'left',
                layout: 'horizontal',
                symbolWidth: 0,
                symbolPadding: 0,
                symbolHeight: 0,
                symbolRadius: 0,
                squareSymbol: false,
                useHTML: true,
                labelFormatter: function () {
                    return `
                    <div style="
                        display: flex;
                    ">
                        <div style="
                            background: ${this.color};
                            height: 30px;
                            width: 8px;
                            margin-right: 5px;
                        ">
                        </div>
                        <div>${this.name}</div>
                    </div>
                    `;
                }
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },
            credits: {
                enabled: false
            },
            series: [],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                    }
                }]
            }
        };
    }

    ngAfterViewInit() {
    }

    logChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    protected override resizeChanged(dashboardItemVm: DashBoardItemViewModel) {
        super.resizeChanged(dashboardItemVm)
        if (this.chart) {
            this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, this.chartContainer.nativeElement.offsetHeight, true);
        }
    }
}

