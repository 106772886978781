import { Expose, Type } from '@nts/std';
import { MenuNodeUiWithChildren } from '../menu-node-ui-with-children';
import { MenuNodeIdentity } from '../menu-node.identity';

export class NodeChildrenDto {

    constructor() {
        this.children = [];
    }

    @Expose()
    @Type(() => MenuNodeIdentity)
    public parent: MenuNodeIdentity;

    @Expose()
    @Type(() => MenuNodeUiWithChildren)
    public children: MenuNodeUiWithChildren[];
}
