import { Expose } from '@nts/std';

export class TimeLineParamsDto {

    @Expose()
    public subjectName: string;

    @Expose()
    public subjectDescription: string;

    @Expose()
    public aggregationName: string;

    @Expose()
    public aggregationDescription: string;

    @Expose()
    public timeLineDescription: string;
}
