import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { GenericServiceResponse, ToastMessageService } from '@nts/std';
import { Observable, map, of, tap } from 'rxjs';
import { WidgetResultSetValue } from '../../../domain-models/dto/widget-result-set-value-dto';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgFor, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-result-set-widget',
    templateUrl: './result-set-widget.component.html',
    styleUrls: ['./result-set-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgFor,
        NgIf
    ],
    standalone: true,
})
export class ResultSetWidgetComponent extends WidgetFullScreenComponent {

    title: string;
    subTitle: string;
    labels: string[] = [];
    valueList: string[][] = [[]];
    total: number;

    constructor(private cd: ChangeDetectorRef, toastMessageService: ToastMessageService) {
        super(toastMessageService, cd);
    }

    protected getLoadDataObservable<WidgetResultSetValue>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetResultSetValue>> {
        const widgetDataArg = this.getWidgetDataArg();
        return this.getApiClient().getResultSetWidgetValue(widgetDataArg, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    protected initView(result: WidgetResultSetValue): Observable<boolean> {

        this.title = result.title;
        this.modalTitle = result.title;
        this.subTitle = result.subTitle;
        this.labels = result.labels;
        this.valueList = result.valueList;
        this.total = this.valueList.length;
        this.cd.detectChanges();

        return of(true);
    }
}
