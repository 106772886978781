import { Expose, Type } from "@nts/std";
import { MenuNodeUi } from "../menu-node-ui";

export class MenuNodeDataDto {

    @Expose()
    @Type(() => MenuNodeUi)
    public nodeUI: MenuNodeUi;

    @Expose()
    @Type(() => MenuNodeDataDto)
    public children: MenuNodeDataDto[];
}
