import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LoaderComponent, ModelContainerComponent, NavigationPanelComponent, NotificationComponent, PageHeaderComponent, RouteChangeParams, TextButtonComponent, ToolBarComponent, ValidationComponent, WingContainerComponent } from '@nts/std';
import { TabSocket } from '@nts/std/src/lib/utility';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, skip } from 'rxjs';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon'

@UntilDestroy()
@Component({
    selector: 'nts-dash-board-model-layout',
    templateUrl: './dash-board-model-container.component.html',
    styleUrls: ['./dash-board-model-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('sideBarLeftOpenClose', [
            state('open', style({
                opacity: 1,
                display: 'flex',
                flexBasis: '280px',
            })),
            state('fullScreen', style({
                opacity: 1,
                display: 'flex',
                flexBasis: '100%',
            })),
            state('closed', style({
                opacity: 0,
                display: 'none',
                flexBasis: '0px',
            })),
            transition('open => closed', [
                sequence([
                    animate('0.2s', style({
                        opacity: '0',
                        flexBasis: '280px'
                    })),
                    style({ display: 'none' }),
                    animate('0.2s ease', style({
                        flexBasis: '0'
                    })),
                ])
            ]),
            transition('closed => open', [
                style({ display: 'flex' }),
                animate('0.2s 0.2s ease', style({
                    flexBasis: '280px',
                    opacity: '0',
                })),
                animate('0.2s', style({
                    opacity: '1'
                })),
            ]),
        ]),
        trigger('sideBarLeftToggleVisibility', [
            state('visible', style({
                left: '9px',
                opacity: '1'
            })),
            state('hidden', style({
                left: '-40px',
                opacity: '0'
            })),
            transition('hidden => visible', [
                sequence([
                    animate('0.2s 0.4s ease')
                ])
            ]),
            transition('visible => hidden', [
                sequence([
                    animate('0.2s ease')
                ])
            ]),
        ])
    ],
    standalone: true,
    imports: [
        LoaderComponent,
        NgIf,
        NgClass,
        NavigationPanelComponent,
        PageHeaderComponent,
        TextButtonComponent,
        ToolBarComponent,
        ValidationComponent,
        NotificationComponent,
        WingContainerComponent,
        AsyncPipe,
        SvgIconComponent
    ]
})
export class DashBoardModelContainerComponent extends ModelContainerComponent {

    // non è necessario gestire la navigazione visto che è già gestita da dashboard container
    protected override async handleNavigationOutsideIframe(routeChangeParams: RouteChangeParams) {

    }

    // non è necessario gestire la navigazione visto che è già gestita da dashboard container
    protected override async handleNavigationInsideIframe(routeChangeParams: RouteChangeParams) {

    }

    // non è necessario gestire la navigazione visto che è già gestita da dashboard container
    protected override handleTabMessageCommunication(socket: TabSocket) {

    }

    // non è necessario gestire le notifiche aggiornamenti visto che è già gestita da dashboard container
    protected override async handleUpdateNotification(evt) {

    }

    override async ngAfterContentInit() {
        super.ngAfterContentInit();
        this.onlineService.isOnline$.pipe(
            untilDestroyed(this),
            skip(1),
            filter(() => this.onlineService.isOnline)
        ).subscribe(() => {
            this.reloadCurrentRoute();
        })
    }

    reloadCurrentRoute() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('not-found', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl);
        });
    }
}
