import { Expose } from '@nts/std';
import { DashBoardItemIdentity } from '../dash-board-item.identity';
import { WidgetIdentity } from '../widget.identity';

export class WidgetDataArg {

    @Expose()
    public identity: WidgetIdentity;

    @Expose()
    public jsonAPIArg: string;

    @Expose()
    public dashBoardWidgetIdentity: DashBoardItemIdentity;
}



