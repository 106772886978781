import { Expose } from '@nts/std';
import { Type } from '@nts/std';
import { DonutItem } from './widget-donut-item-dto';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetDonutValueDto extends WidgetBaseValueDto {

    constructor() {
        super();
        this.items = [];
    }

    @Expose()
    public serieName: string;

    @Expose()
    @Type(() => DonutItem)
    public items: DonutItem[];

}



