import { OCCModel, ExternalDecorator, Type } from '@nts/std';
// tslint:disable-next-line: max-line-length
import { IdentityTypeDecorator, ModelTypeNameDecorator, Expose, NumberDecorator, EnumDecorator, CoreModel, BoolDecorator } from '@nts/std';
import { StringDecorator } from '@nts/std';
import { WidgetIdentity } from './widget.identity';
import { WidgetTypes } from './enum/widget-types';

@IdentityTypeDecorator(WidgetIdentity)
@ModelTypeNameDecorator('Widget')
export class Widget extends OCCModel<WidgetIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_Code', maxLength: 50, isRequired: true })
    public get code(): string {
        return this.getValue<string>(() => this._code, 'code');
    }
    public set code(value: string) {
        this.setValue<string>(() => { this._code = value; }, value, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _rootModelFulName: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_RootModelFulName', maxLength: 255, isRequired: true })
    public get rootModelFulName(): string {
        return this.getValue<string>(() => this._rootModelFulName, 'rootModelFulName');
    }
    public set rootModelFulName(value: string) {
        this.setValue<string>(() => { this._rootModelFulName = value; }, value, 'rootModelFulName');
    }

    // tslint:disable-next-line: variable-name
    private _apiName: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_ApiName', maxLength: 255, isRequired: true })
    public get apiName(): string {
        return this.getValue<string>(() => this._apiName, 'apiName');
    }
    public set apiName(value: string) {
        this.setValue<string>(() => { this._apiName = value; }, value, 'apiName');
    }

    // tslint:disable-next-line: variable-name
    protected _widgetType;

    @Expose()
    @EnumDecorator({ displayNameKey: 'Widget_WidgetTypes' })
    public get widgetType(): WidgetTypes {
        return this.getValue<WidgetTypes>(() => this._widgetType, 'widgetType');
    }
    public set widgetType(value: WidgetTypes) {
        this.setValue<WidgetTypes>(() => { this._widgetType = value; }, value, 'widgetType');
    }

    // tslint:disable-next-line: variable-name
    private _isStandard: boolean;

    @Expose()
    @BoolDecorator({ displayNameKey: 'Widget_IsStandard' })
    public get isStandard(): boolean {
        return this.getValue<boolean>(() => this._isStandard, 'isStandard');
    }
    public set isStandard(value: boolean) {
        this.setValue<boolean>(() => { this._isStandard = value; }, value, 'isStandard');
    }

    // tslint:disable-next-line: variable-name
    private _name: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_Name', maxLength: 30, isRequired: true, isMainDescription: true })
    public get name(): string {
        return this.getValue<string>(() => this._name, 'name');
    }
    public set name(value: string) {
        this.setValue<string>(() => { this._name = value; }, value, 'name');
    }

    // tslint:disable-next-line: variable-name
    private _description: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_Description', maxLength: 255, isRequired: true })
    public get description(): string {
        return this.getValue<string>(() => this._description, 'description');
    }
    public set description(value: string) {
        this.setValue<string>(() => { this._description = value; }, value, 'description');
    }

    // tslint:disable-next-line: variable-name
    private _linkedWidgetCode: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_LinkedWidgetCode', maxLength: 100 })
    public get linkedWidgetCode(): string {
        return this.getValue<string>(() => this._linkedWidgetCode, 'linkedWidgetCode');
    }
    public set linkedWidgetCode(value: string) {
        this.setValue<string>(() => { this._linkedWidgetCode = value; }, value, 'linkedWidgetCode');
    }

    // tslint:disable-next-line: variable-name
    private _linkedWidgetRef: Widget;

    @ExternalDecorator({ displayNameKey: 'Widget_LinkedWidgetRef', principalPName1: 'linkedWidgetCode', dependantPName1: 'code', isRemote: false, isRequired: false })
    @Expose()
    @Type(() => Widget)
    public get linkedWidgetRef(): Widget {
        return this.getValueForExternal<Widget>(
            () => this._linkedWidgetRef,
            'linkedWidgetRef',
            Widget
        );
    }
    public set linkedWidgetRef(value: Widget) {
        this.setValueForExternal(() => {
            this._linkedWidgetRef = value;
        }, value, 'linkedWidgetRef');
    }

    // tslint:disable-next-line: variable-name
    private _minCols: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_MinCols' })
    public get minCols(): number {
        return this.getValue<number>(() => this._minCols, 'minCols');
    }
    public set minCols(value: number) {
        this.setValue<number>(() => { this._minCols = value; }, value, 'minCols');
    }

    // tslint:disable-next-line: variable-name
    private _maxCols: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_MaxRows' })
    public get maxCols(): number {
        return this.getValue<number>(() => this._maxCols, 'maxCols');
    }
    public set maxCols(value: number) {
        this.setValue<number>(() => { this._maxCols = value; }, value, 'maxCols');
    }

    // tslint:disable-next-line: variable-name
    private _minRows: number;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_MinRows', maxLength: 100 })
    public get minRows(): number {
        return this.getValue<number>(() => this._minRows, 'minRows');
    }
    public set minRows(value: number) {
        this.setValue<number>(() => { this._minRows = value; }, value, 'minRows');
    }

    // tslint:disable-next-line: variable-name
    private _maxRows: number;

    @Expose()
    @StringDecorator({ displayNameKey: 'Widget_MaxRows', maxLength: 100 })
    public get maxRows(): number {
        return this.getValue<number>(() => this._maxRows, 'maxRows');
    }
    public set maxRows(value: number) {
        this.setValue<number>(() => { this._maxRows = value; }, value, 'maxRows');
    }

    // tslint:disable-next-line: variable-name
    private _defaultRows: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_DefaultRows' })
    public get defaultRows(): number {
        return this.getValue<number>(() => this._defaultRows, 'defaultRows');
    }
    public set defaultRows(value: number) {
        this.setValue<number>(() => { this._defaultRows = value; }, value, 'defaultRows');
    }

    // tslint:disable-next-line: variable-name
    private _defaultCols: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'DashBoardItem_DefaultCols' })
    public get defaultCols(): number {
        return this.getValue<number>(() => this._defaultCols, 'defaultCols');
    }
    public set defaultCols(value: number) {
        this.setValue<number>(() => { this._defaultCols = value; }, value, 'defaultCols');
    }
}
