import { Expose } from '@nts/std';

export class CookiePreferencesDTO {

    constructor() {
        this.essentialCookies = false;
        this.analyticsCookies = false;
    }

    @Expose()
    public essentialCookies: boolean;

    @Expose()
    public analyticsCookies: boolean;

}
