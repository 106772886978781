import { CommandFactory, ToolBarViewModel, UICommandInterface } from '@nts/std';
import { DashBoardLayoutUiCommandSettingsManager, DashBoardToolBarCommandNames } from './dash-board-layout.ui-command-settings-manager';
import { DashBoardLayoutOrchestratorViewModel } from './dash-board-layout.orchestrator-view-model';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Observable, merge } from 'rxjs';

export class DashBoardToolBarViewModel extends ToolBarViewModel {

    protected override addHomeCommands(): Array<UICommandInterface> {
        const commands = super.addHomeCommands();

        const commandManager = new DashBoardLayoutUiCommandSettingsManager();

        commands.push(
            commandManager.setUICommand(
                DashBoardToolBarCommandNames.Refresh, CommandFactory.createUICommand(
                    async (x) => (this.orchestrator as DashBoardLayoutOrchestratorViewModel).refreshWidgets(),
                    () => (this.orchestrator as DashBoardLayoutOrchestratorViewModel)
                        .editingDashBoardStatusChanged.pipe(map(editing => !editing)),
                    null,
                    () => merge(
                        this.orchestrator.onlineService.isOnline$,
                        this.orchestrator.rootViewModelChanged
                    ).pipe(
                        filter(() => this.orchestrator.rootViewModel != null),
                        switchMap(
                            () => (this.orchestrator as DashBoardLayoutOrchestratorViewModel).editingDashBoardStatusChanged.pipe(map((editing) => {
                                const ovm = (this.orchestrator as DashBoardLayoutOrchestratorViewModel);
                                const noWidgets = ovm.typedRootViewModel.content.length == 0;
                                return !editing && !noWidgets && this.orchestrator.onlineService.isOnline$.value;
                            }))
                        )
                    )
                )
            )
        );

        commands.push(
            commandManager.setUICommand(
                DashBoardToolBarCommandNames.Edit, CommandFactory.createUICommand(
                    async (x) => (this.orchestrator as DashBoardLayoutOrchestratorViewModel).editDashBoard(),
                    () => of(true),
                    null,
                    () => merge(
                        this.orchestrator.onlineService.isOnline$,
                        this.orchestrator.rootViewModelChanged
                    ).pipe(
                        filter(() => this.orchestrator.rootViewModel != null),
                        switchMap(
                            () => (this.orchestrator as DashBoardLayoutOrchestratorViewModel).editingDashBoardStatusChanged.pipe(map((editing) => {
                                const ovm = (this.orchestrator as DashBoardLayoutOrchestratorViewModel);
                                const noWidgets = ovm.typedRootViewModel.content.length == 0;
                                return !editing && !noWidgets && this.orchestrator.onlineService.isOnline$.value;
                            }))
                        )
                    )
                )
            )
        );

        commands.push(
            commandManager.setUICommand(
                DashBoardToolBarCommandNames.Add, CommandFactory.createUICommand(
                    async (x) => (this.orchestrator as DashBoardLayoutOrchestratorViewModel).addWidgetDashBoard(),
                    () => of(true),
                    null,
                    () => merge(
                        this.orchestrator.onlineService.isOnline$,
                        this.orchestrator.rootViewModelChanged
                    ).pipe(
                        filter(() => this.orchestrator.rootViewModel != null),
                        switchMap(
                            () => (this.orchestrator as DashBoardLayoutOrchestratorViewModel).editingDashBoardStatusChanged.pipe(map((editing) => {
                                const ovm = (this.orchestrator as DashBoardLayoutOrchestratorViewModel);
                                const noWidgets = ovm.typedRootViewModel.content.length == 0;
                                return !editing && !noWidgets && this.orchestrator.onlineService.isOnline$.value;
                            }))
                        )
                    )
                )
            )
        );

        commands.push(
            commandManager.setUICommand(
                DashBoardToolBarCommandNames.Done, CommandFactory.createUICommand(
                    async (x) => { await (this.orchestrator as DashBoardLayoutOrchestratorViewModel).storeDashBoard(); },
                    () => of(true),
                    (x) => of(true),
                    () => this.orchestrator.onlineService.isOnline$.pipe(
                        switchMap(
                            (isOnline: boolean) => (this.orchestrator as DashBoardLayoutOrchestratorViewModel).editingDashBoardStatusChanged.pipe(map((editing) => {
                                return editing && isOnline;
                            }))
                        )
                    )
                )
            )
        );

        return commands;
    }

    // #4091 nascondo il menù mobile
    override isVisibleMobileMenuCommand(): Observable<boolean> {
        return of(false);
    }

    // #4091 nascondo il menù altro
    override isVisibleMoreOptionsCommand(): Observable<boolean> {
        return of(false);
    }
}
