import { BaseIdentity, Expose } from '@nts/std';
import { WidgetBaseValueDto } from './widget-base-value-dto';

export class WidgetIdentityShortcutDto extends WidgetBaseValueDto {

    @Expose()
    public identity: string;

    @Expose()
    public fullName: string;

}
